import { Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApisService } from './apis.service';
import { metafields} from './titleMetaTags'
import { Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetafieldsService {
metafields:any=metafields.all;
  constructor(
    private meta: Meta,
    private titleSer: Title,
    private router:Router,
    public api:ApisService,
    @Inject(DOCUMENT) private dom:any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }
  setMetaFields(type:any){
  // console.log(this.metafields[type],this.metafields[type].meta.findIndex((x:any)=>x.name=='description'))
    this.titleSer.setTitle(this.metafields[type].title)
    this.meta.updateTag({ name: 'name', content:this.metafields[type].title});
    this.meta.updateTag({ name: 'description',content:this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.name=='description')].content});
    this.meta.updateTag({ name: 'keywords', content:  this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.name=='keyword')].content})

    this.meta.updateTag({ property: "og:url", content: "https://yuginfo.in/" +this.router.url })
    this.meta.updateTag({ property: "og:title", content: this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.property=='og:title')].content  })
    this.meta.updateTag({ property: "og:site_name", content: this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.property=='og:site_name')].content })
    this.meta.updateTag({ property: "og:description", content:  this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.property=='og:description')].content})
    this.meta.updateTag({ property: 'og:type', content:"WebDes" })
    this.meta.updateTag({ property: "og:image", content: this.metafields[type].meta[this.metafields[type].meta.findIndex((x:any)=>x.property=='og:image')].content})









  }
  setDirectMetaFields(metafields:any){
    // console.log(this.metafields[type],this.metafields[type].meta.findIndex((x:any)=>x.name=='description'))
    if(metafields.title){
      this.titleSer.setTitle(metafields.title)
      this.meta.updateTag({ name: 'name', content:metafields.title});
      this.meta.updateTag({ property: "og:title", content: metafields.title  })


    }
    if(metafields.description){
      this.meta.updateTag({ name: 'description',content:metafields.description });
      this.meta.updateTag({ property: "og:description", content:  metafields.description })


    }
    if(metafields.keywords){
      this.meta.updateTag({ name: 'keywords', content: metafields.keywords })
    }
    if(metafields.image){
      this.meta.updateTag({ property: "og:image", content: metafields.image})

    }


      this.meta.updateTag({ property: "og:url", content: "https://yuginfo.in/" +this.router.url })

      this.meta.updateTag({ property: 'og:type', content:"WebDes" })
      this.meta.updateTag({ property: "og:site_name", content: "YugInfo" })

    }


    setCanonicalURL(url?: string) {
      const canURL = url == undefined ? this.dom.URL : url;
      const link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild("https://yuginfo.in/" +this.router.url);
      link.setAttribute('href', canURL);
    }


    addLink(element:any,elementType:string){
      const link: HTMLLinkElement = this.dom.createElement(elementType);
      Object.keys(element).map((key)=>{
        if(key !== "elementType"){
          link.setAttribute(key, element[key]);
        }
      })
      this.dom.head.appendChild(link);
    }

    removeLink(id:string){
      let query:any = document.querySelector(id);
      document.querySelector(id)?.parentNode?.removeChild(query);
    }
}
