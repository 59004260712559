import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { ApisService } from './services/apis.service';
import { MetafieldsService } from './services/metafields.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
loadingFlag : boolean = false;
anim : any;
progressRef: NgProgressRef | any;
loader:boolean = false;
positionLoading:any = "left";
  isPopState: boolean=false;
  constructor(
    public api:ApisService,
    private progress: NgProgress,
    private router: Router,@Inject(PLATFORM_ID) private platformId: any,
    private meta:MetafieldsService){
    this.router.events.subscribe(event => {
      // Scroll to top if accessing a page, not via browser history stack
      if (event instanceof NavigationEnd && !this.isPopState) {
        if(isPlatformBrowser(this.platformId)){
          if(document.getElementsByTagName('body')[0].clientWidth > 991){
            this.positionLoading = "center";
          }else{
            this.positionLoading = "center";
          }
          window.scrollTo(0, 0);
        }
        this.isPopState = false;
      }

      // Ensures that isPopState is reset
      if (event instanceof NavigationEnd) {
        this.isPopState = false;
      }
    });
    
  }

  ngOnInit(){
    console.log("cooooooooooooooooooooooooool");
    AOS.init();
    this.progressRef = this.progress.ref('myProgress');
    this.meta.setCanonicalURL();
    
    console.log("cooooooooooooooooooooooooool");
  }
  ngAfterViewInit() {
    this.api.loading.subscribe((value)=>{
      if(value == true){
        this.progressRef.start();
      }else{
        this.progressRef.complete();
      }
    })
}
  animationCreated(anim: any) {
    // console.log("created >>>>>>>>>.",anim)
    this.anim = anim;
}

}
