<section id="tinyslider">
  <div class="dark" style="background-image: url(https://info.yuginfo.in/BlogImagesyug/202163125732-702bg-01.jpg);background-position: center top;background-size: cover;">
    <div class="container clearfix">

            <div class="slider-caption slider-caption-center">

                <h1 >Be Part of Something Great</h1>
                <p  >Love your work. Join your dream team.</p>
                    
                
            </div>

        </div>
  </div>
  

</section>
<section id="hropenjobs" style="    padding-top: 60px;">
      <div class="container">
        <div class="rowhrpagepol" style="">
            <div style="" class=" img divboxtechlang content1" [ngClass]="car.title" *ngFor="let car of career">
                  <div class="products titlenamescc" [ngStyle] = "{'background-color' : car.color ? car.color : '#3f27cf'}">
                  <p class="txt">{{car.title}}</p>
                  </div>
                  
                  <div class="content" *ngFor="let job of car.jobs">
                    <div class = "divClass" [ngStyle] = "{'background-color' : car.color ? car.color : '#3f27cf'}"></div>
                    <a target="_blank" class="description">{{job.title}}</a>
                    <a (click)="move(job.title)" class="link" [ngStyle] = "{'background-color' : car.color ? car.color : '#3f27cf'}">Apply now</a>

                  </div>
                
            </div>
       
        </div>
      </div>
    <div class="section section-v2 section__overflow-inherit section_apply-teamup">
      <div class="wrapper section__wrapper">
        <div>
          <svg fill="none" height="144" viewBox="0 0 91 144" width="91" xmlns="http://www.w3.org/2000/svg">
            <path d="m55.7361 104.596v-9.5913c0-11.2196 12.6673-19.1819 22.6202-28.4109 6.6955-6.5146 12.3053-16.2865 12.3053-27.3252 0-11.2196-3.9811-20.6296-12.1244-28.049-8.1432-7.41941-18.82-11.2196-32.0301-11.2196-18.6391 0-34.2017 7.78135-46.5071 23.344l17.5533 19.5438c9.229-10.6767 18.277-16.1055 26.7822-16.1055 9.2291 0 15.9246 5.7907 15.9246 14.2959 0 10.4958-11.4005 18.0962-20.8105 26.6014-6.3337 5.7907-11.2196 14.8388-11.2196 25.5155v11.4009zm-2.3525 34.925c6.3336-6.333 6.1527-16.648.181-22.62-5.9718-5.972-16.2866-5.972-22.6202 0-6.3337 5.972-6.3337 16.287 0 22.62 6.5146 5.972 16.4675 5.972 22.4392 0z" fill="#FD7171"></path></svg>
        </div>
        <div class="word">
          <h2 style="margin-top: -39px;margin-bottom: 5px;"
          class="section-v2__header section-v2__header_main">Didn't find the perfect role for you?</h2>
          <p class="section-v2__text section-v2__text_v2 section-v2__text_main">We may be able to find the role that
            matches your skill set perfectly. Tell us about you, and we’ll be in touch!</p>
          <a (click)="move('common')" class="btn btn_v2 btn_v2-red">Apply Here</a>
        </div>
      </div>
    </div>
  
    <!-- <div id="lifeatyuginfo" class="container-fluid">
      <div class="container-fluid">
        <h5 class="headingsser"><span >Life At Yug Enterprises</span></h5>
        <div class="masonry">
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/202132295434-3011.jpg" />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322133133-3212-%282%29.jpg" style="" />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322133212-9203.jpg           " />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322223032-7264%20%282%29.jpeg" />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322132750-1775.JPEG" />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322133016-8496.jpg" />
            
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322132834-2397.JPEG" />
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/20213221034-8809.JPEG" />
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322132936-7299.JPEG" />
          </div>
          <div class="item">
            <img src="https://info.yuginfo.in/BlogImagesyug/2021322132955-36910.jpg" />
          </div>
        </div>

      </div>
    </div> -->
    <div class="section section-v2 section_process">
      <div class="wrapper section__wrapper">
        <div class="section-v2__subheader section-v2__subheader_v2 section-v2__subheader_main">process</div>
        <h2 class="section-v2__header section-v2__header_main">Our six step process.</h2>
      </div>
      <div class="container Yug-Enterprises-process">
        <div class="Yug-enterprises-process__wrapper">
          <div class="Yug-enterprises-process__row Yug-enterprises-process__slider" style="">
            
            
            
            
            
            
            
          <div class="Yug-enterprises-process__item Yug-enterprises-process__item_pink" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide20">
              <div class="Yug-enterprises-process__num">01.</div>
              <div class="Yug-enterprises-process__header">🔍 Review</div>
              <div class="Yug-enterprises-process__text">Applicants are reviewed to ensure a good fit.</div>
            </div><div class="Yug-enterprises-process__item Yug-enterprises-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide21">
              <div class="Yug-enterprises-process__num">02.</div>
              <div class="Yug-enterprises-process__header">👋 Initial Interview</div>
              <div class="Yug-enterprises-process__text">We want to get to know you better!</div>
            </div><div class="Yug-enterprises-process__item Yug-enterprises-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide22">
              <div class="Yug-enterprises-process__num">03.</div>
              <div class="Yug-enterprises-process__header">📥 Mini Project</div>
              <div class="Yug-enterprises-process__text">A project for the team you’re applying for is distributed.</div>
            </div><div class="Yug-enterprises-process__item Yug-enterprises-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide23">
              <div class="Yug-enterprises-process__num">04.</div>
              <div class="Yug-enterprises-process__header">🤓 Final Interview</div>
              <div class="Yug-enterprises-process__text">This interview is conducted with team leads.</div>
            </div><div class="Yug-enterprises-process__item Yug-enterprises-process__item_yellow" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide24">
              <div class="Yug-enterprises-process__num">05.</div>
              <div class="Yug-enterprises-process__header">💰 Offer</div>
              <div class="Yug-enterprises-process__text">A carefully tailored offer is sent to the candidate.</div>
            </div><div class="Yug-enterprises-process__item Yug-enterprises-process__item_green" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide25">
              <div class="Yug-enterprises-process__num">06.</div>
              <div class="Yug-enterprises-process__header">🚀 Career Launch</div>
              <div class="Yug-enterprises-process__text">Blast off into the future of productivity and unlimited possibility!
              </div>
            </div></div>
        </div>
  
      </div>
    </div>
</section>
<section id="locations" class="testimonalSec selected">
  <div class="container">
    <div class="headingSec text-center ourlocationsheading">

      <h4 style="">OUR LOCATIONS</h4>
      
    </div>
    <div class="row">
      <div class="col-sm-4 bdrAfter">
        <div class="text-center modelsStep">
          <div class="backgroundcolorlightshadow singaporeloc"></div>
          <div class="divcontentmodels">
            <h5>SINGAPORE</h5>
          </div>
        </div>
      </div> 
      <div class="col-sm-4 bdrAfter">
        <div class="text-center modelsStep">
          <div class="backgroundcolorlightshadow jaipurloc"></div>
          <div class="divcontentmodels">
            <h5>INDIA - JAIPUR</h5>
          </div>
        </div>
      </div>  
      <div class="col-sm-4 bdrAfter">
        <div class="text-center modelsStep">
          <div class="backgroundcolorlightshadow hyderabadloc"></div>
          <div class="divcontentmodels">
            <h5>INDIA - HYDERABAD</h5>
          </div>
        </div>
      </div>   
    </div>
  </div>
</section>