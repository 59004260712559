import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect-with-us',
  templateUrl: './connect-with-us.component.html',
  styleUrls: ['./connect-with-us.component.scss']
})
export class ConnectWithUsComponent implements OnInit {
objForm:any;
constructor(private builder: FormBuilder ,private apiservice:ApisService,private router:Router ) { 
  this.objForm = this.builder.group({
    full_name: ['', Validators.required],
    phone_num: ['', Validators.required],
    email_add:['', [Validators.required, Validators.email]],
    message: ['', Validators.required],
    country_code:['',Validators.required],
    budget:['',Validators.required],
    phone_num2:['',Validators.required],
    category:['',Validators.required],
  });
}
saveUser(){
  if (this.objForm.dirty && this.objForm.valid) {
    let obj = {
      'full_name':this.objForm.value.full_name,
      'email_add':this.objForm.value.email_add,
      'country_code':this.objForm.value.country_code,
      'phone_num':this.objForm.value.phone_num,
      'budget':this.objForm.value.budget,
      'phone_num2':this.objForm.value.phone_num2,
      'message':this.objForm.value.message,
      'category':this.objForm.value.category,
      'pathname':this.router.url,
      'form_type':'submitFormContactForm'
      }
   this.apiservice.add(obj , environment.apis.users).then((res)=>{
   })

 }
}
  ngOnInit(): void {
  }

}
