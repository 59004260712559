import { Injectable } from '@angular/core';
import { FileUpload } from './file-upload';

declare var AWS:any; 

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  loadingFlag:boolean = false;
  uploadPercentage:any = '';
  constructor() { }

  private basePath:string = '/';

  pushUpload(upload:any, subfolderPath : string ) {
    console.log(upload,subfolderPath)
    let date = new Date();
    let name = ''; 
    name = name + date.getFullYear()+(date.getMonth() + 1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+'-'+date.getMilliseconds()+ upload.file.name;
    let bucket = new AWS.S3(
      {
        accessKeyId: 'AKIAV4A5WTG5DEP4Y7SS',
        secretAccessKey: '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',
        region: 'us-east-1'
      }
    );

    const params = {
      Bucket: 'vidhema-website',
      Key: 'Vidhema'+subfolderPath+name,
      Body: upload.file,
      ContentType: upload.file.mimetype,
      ACL:"public-read-write"
    };

    return new Promise((resolve, reject) => {
      this.loadingFlag = true;
      let self = this;
      bucket.upload(params).on('httpUploadProgress', function (evt:any) {
        self.uploadPercentage = ((evt.loaded/evt.total)*100).toFixed(0);; 
        console.log(self.uploadPercentage)
        upload.progress = ((evt.loaded/evt.total)*100).toFixed(0);
      }).send(function (err:any, data:any) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              self.loadingFlag = false;
              reject(err);
          }
          if(data){
            console.log('Successfully uploaded file.', data);
            resolve({url:data.Location});
            self.loadingFlag = false;
          }
      });
  });
  }


  deleteUpload(upload: FileUpload, subfolderPath : string) {
      return this.deleteFileStorage(upload.name,subfolderPath);
  }



  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name:string, subfolderPath : string) {
    let folderPath = this.basePath + subfolderPath;

    return new Promise((resolve, reject) => {
        resolve(true);
    });
  }

}
