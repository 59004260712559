import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(private meta:MetafieldsService ) {
    this.meta.api.addAndRemoveClass('aboutus mainaboutus','portfolio profilepagesection blog careers contactus services technology getaquote HomeMain blogcontentbody');
  }

  ngOnInit(): void {
    this.meta.setMetaFields('AboutUs');
  }

}
