export class metafields{
  public static all={
    "AboutUs": {
      "title": "About Us - Welcome to Yug Enterprises",
      "titleTemplate": "%s",
      "meta": [
        {
      "name": "description",
      "content": "Yug Enterprises offers world-class web development solutions that are powerful and innovative. As a top web development company, we provide our services worldwide."
        },
        {
          "name": "keyword",
          "content": "About-us"
        },
        {
          "property": "og:type",
          "content": "blog"
        },
        {
          "property": "og:title",
          "content": "About Us - Welcome to Yug Enterprises"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises offers world-class web development solutions that are powerful and innovative. As a top web development company, we provide our services worldwide."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/about-us.html"
        },
        {
          "property": "og:site_name",
          "content": "Yug Enterprises Pte. Ltd."
        },
        {
          "property": "og:image",
          "content": "https://yuginfo.in/assets/images/yug-logo-dark.png"
        },
        
  
        
        
        
        
        
    ]
      
    },
    "Blog": {
      "title": "Yug Enterprises Blog | Information and Recent Technology Updates",
      "titleTemplate": "%s ",
      "meta": [
      {
    "name": "description",
    "content": "Yug Enterprises Blog - Get the latest technology blog posts, recent updates of web and mobile application development."
      },
      {
        "name": "keyword",
        "content": "blog, new post, new blog,blog, technology blog posts, technology blog, the blog, information blog, blog posts"
      },
      {
        "property": "og:type",
        "content": "blog"
      },
      {
        "property": "og:title",
        "content": "Yug Enterprises Blog | Information and Recent Technology Updates"
      },
      {
        "property": "og:description",
        "content": "Yug Enterprises Blog - Get the latest technology blog posts, recent updates of web and mobile application development."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/blog/"
      },
      {
        "property": "og:site_name",
        "content": "Yug Enterprises Pte. Ltd."
      },
      {
        "property": "og:image",
        "content": "https://yuginfo.in/assets/images/yug-logo-dark.png"
      },
      

      
      
      
      
      
  ]
    },
    "ContactUs": {
      "title": "Contact Yug Enterprises for VueJS Development or Web & Mobile App",
      "titleTemplate": "%s - Yug Enterprises",
      "meta": [
        {
      "name": "description",
      "content": "Yug Enterprises is a reputed web development company. Connect with us and get answers to all your problems related to mobile app development or custom software solutions."
        },
        {
          "name": "keyword",
          "content": "Contact-us"
        },
        {
          "property": "og:type",
          "content": "blog"
        },
        {
          "property": "og:title",
          "content": "Contact Yug Enterprises for VueJS Development or Web & Mobile App"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises offers world-class web development solutions that are powerful and innovative. As a top web development company, we provide our services worldwide."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/about-us.html"
        },
        {
          "property": "og:site_name",
          "content": "Yug Enterprises Pte. Ltd."
        },
        {
          "property": "og:image",
          "content": "https://yuginfo.in/assets/images/yug-logo-dark.png"
        },
        
  
        
        
        
        
        
    ]
      
    },
    "Home": {
      "title": "Yug Enterprises: Mobile App Development Company India, Singapore",
      "titleTemplate": "%s | yuginfo",
      
      "meta": [
        {
      "name": "description",
      "content": "Yug Enterprises is a prominent top web and mobile app development company in India & Singapore, providing custom website development services across the world. Outsource web application development & expand your business globally."
      },
      {
          "name": "keyword",
          "content": "web design company, web development agency, ecommerce development services, outsource web application development, custom website development services, custom web development company, web application framework, best website design, Top mobile app development company"
        },
        {
          "property": "og:type",
          "content": "https://yuginfo.in/"
        },
        {
          "property": "og:title",
          "content": "Yug Enterprises: Mobile App Development Company India, Singapore"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises is a prominent top web and mobile app development company in India & Singapore, providing custom website development services across the world. Outsource web application development & expand your business globally."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/"
        },
        {
          "property": "og:site_name",
          "content": "Yug Enterprises Pte. Ltd."
        },
        {
          "property": "og:image",
          "content": "https://yuginfo.in/assets/images/yug-logo-dark.png"
        },
        
  
        
        
        
        
        
    ]
    },
    "WebJava": {
      "title": "Java web development India | Java web development - Videma Technologies",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "We are here to offer Java development services that are high performing, rich, and reliable. As the leading java development company, we provide java full stack development and work rigorously to fulfill our clients' needs."
      },
      {
          "name": "keyword",
      "content": "java web development, java development services, java development company, java web development, java backend developer, java front end developer, java full stack development"
        },
        {
          "property": "og:type",
          "content": "WebJava"
        },
        {
          "property": "og:title",
          "content": "Java web development India | Java web development - Videma Technologies"
        },
        {
          "property": "og:description",
          "content": "We are here to offer Java development services that are high performing, rich, and reliable. As the leading java development company, we provide java full stack development and work rigorously to fulfill our clients' needs."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/technologies-web-java.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "Portfolio": {
      "title": "Portfolio - Welcome to Yug Enterprises",
      "titleTemplate": "%s",
      "meta": [
        {
      "name": "description",
      "content": "Drive traffic to your website with our full suite of web development services. We offer custom software development solutions. Visit us to see our work portfolio."
        },
        {
          "name": "keyword",
          "content": "Contact-us"
        },
        {
          "property": "og:type",
          "content": "blog"
        },
        {
          "property": "og:title",
          "content":" Portfolio - Welcome to Yug Enterprises"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises offers world-class web development solutions that are powerful and innovative. As a top web development company, we provide our services worldwide."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/about-us.html"
        },
        {
          "property": "og:site_name",
          "content": "Yug Enterprises Pte. Ltd."
        },
        {
          "property": "og:image",
          "content": "https://yuginfo.in/assets/images/yug-logo-dark.png"
        },
        
  
        
        
        
        
        
    ]
      
    },
    "CmsDev": {
      "title": "CMS Website Development Company | CMS Development Company",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "We are the top CMS Development Agency providing Custom CMS Development Services at an affordable price. Hire CMS Web Developers by connecting with our team."
      },
      {
          "name": "keyword",
      "content": "cms web developer, cms development agency, custom cms development services, custom cms development, cms website development company, cms development services"
        },
        {
          "property": "og:type",
          "content": "CmsDev"
        },
        {
          "property": "og:title",
          "content": "CMS Website Development Company | CMS Development Company"
        },
        {
          "property": "og:description",
          "content": "We are the top CMS Development Agency providing Custom CMS Development Services at an affordable price. Hire CMS Web Developers by connecting with our team."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/cms-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "WebDes": {
      "title": "Custom Website Design Services | Custom Web Design",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Yug Enterprises is one of the best web design company with over 2+ years of experience. Our professional & affordable web design services help customer to establish their online presence."
      },
      {
          "name": "keyword",
      "content": "custom website, custom design, custom web design, custom website design, custom designed, custom cms, web page design"
        },
        {
          "property": "og:type",
          "content": "WebDes"
        },
        {
          "property": "og:title",
          "content": "Custom Website Design Services Company"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises is one of the best web design company with over 2+ years of experience. Our professional & affordable web design services help customer to establish their online presence."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/web-design.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "DedDev": {
      "title": "Top Custom Website Design Development Company Singapore & India.",
      "titleTemplate": "%s - Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Custom Website Design Development teams based on ready-to-use IT infrastructure to handle end-to-end delivery of your IT project."
      },
      {
          "name": "keyword",
      "content": "best team work, top team work, best, team, work, team work, software development teams, top, team, work, best work"
        },
        {
          "property": "og:type",
          "content": "WebDes"
        },
        {
          "property": "og:title",
          "content": "Top Custom Website Design Development Company Singapore & India."
        },
        {
          "property": "og:description",
          "content": "Custom Website Design Services Company in Singapore & India. Yug Enterprises is a professional Web design company. That provides affordable Custom Web Design Services."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/dedicated-developer.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "EcommDev": {
      "title": "Ecommerce Website Development Company | Ecommerce Developers",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Best ecommerce development company offering the finest ecommerce solutions for various industries. Our ecommerce developers offer you the top custom ecommerce solutions."
      },
      {
          "name": "keyword",
      "content": "ecommerce development company, ecommerce solutions, best ecommerce development company, ecommerce developers, custom ecommerce solutions, ecommerce development agency, ecommerce development services, ecommerce web development services"
        },
        {
          "property": "og:type",
          "content": "EcommDev"
        },
        {
          "property": "og:title",
          "content": "Ecommerce Development Company | Ecommerce Developers - Yug Enterprises "
        },
        {
          "property": "og:description",
          "content": "Best ecommerce development company offering the finest ecommerce solutions for various industries. Our ecommerce developers offer you the top custom ecommerce solutions."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/ecommerce-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "FrontDev": {
      "title": "Frontend App Development Services | Frontend Development Company",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Onboard certified front-end developers for customized front-end web development services with advanced UI design from the leading front-end development company."
      },
      {
          "name": "keyword",
      "content": "front-end development, front-end development services, front-end development company, front-end developers, front-end web development services"
        },
        {
          "property": "og:type",
          "content": "FrontDev"
        },
        {
          "property": "og:title",
          "content": "Front-End Development Services - Yug Enterprises"
        },
        {
          "property": "og:description",
          "content": "Onboard certified front-end developers for customized front-end web development services with advanced UI design from the leading front-end development company. "
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/front-end-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "FullStackDev": {
      "title": "Expert Javascript Developer | Javascript Development Services",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "We are a top JavaScript Development Company offering end-to-end JS Development Services. Hire our expert JavaScript Developers for custom JS Development Services."
      },
      {
          "name": "keyword",
      "content": "javascript development company, js development services, javascript developers, custom js development services, hire javascript developers, javascript"
        },
        {
          "property": "og:type",
          "content": "FullStackDev"
        },
        {
          "property": "og:title",
          "content": "Hire JavaScript Developer | JavaScript Development Services"
        },
        {
          "property": "og:description",
          "content": "We are a top JavaScript Development Company offering end-to-end JS Development Services. Hire our expert JavaScript Developers for custom JS Development Services. "
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/js-based-full-stack-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "MagentoApp": {
      "title": "Magento Mobile App Builder | Android & iOS App",
      "titleTemplate": "%s | yuginfo",
      "meta": [
        {
      "name": "description",
      "content": "Our Android & iOS mobile apps are fully compatible with your Magento web store. Boost the sales and growth of your business with our productive and engaging app design services."
      },
      {
          "name": "keyword",
          "content": "magento development, magento ecommerce, ecommerce website, ecommerce platforms, best ecommerce platform, ecommerce website builder "
        },
        {
          "property": "og:type",
          "content": "website"
        },
        {
          "property": "og:title",
          "content": "Magento Mobile App Builder | Android & iOS App"
        },
        {
          "property": "og:description",
          "content": "Our Android & iOS mobile apps are fully compatible with your Magento web store. Boost the sales and growth of your business with our productive and engaging app design services."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/magento-mobile-app-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "MobileApps": {
      "title": "Mobile App Development Services | Android & iOS",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "We provide high-end mobile app development services. Develop your own app and establish your business in the online marketplace with the guidance of our team of professional developers."
      },
      {
          "name": "keyword",
      "content": "mobile app development, mobile app, android app development, ios app development, app development company"
        },
        {
          "property": "og:type",
          "content": "website"
        },
        {
          "property": "og:title",
          "content": "Mobile App Development Services | Android & iOS"
        },
        {
          "property": "og:description",
          "content": "We provide high-end mobile app development services. Develop your own app and establish your business in the online marketplace with the guidance of our team of professional developers."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/mobile-apps-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "WebApp": {
      "title": " Web Application Development Company | Custom Website Apps",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
      "content": "We are a leading website design and development company helping our clients with professional custom web design services. Hire our web developers for the best offerings."
      },
      {
          "name": "keyword",
          "content": "web application development, web application development company, website design and development company, custom web design services, web developers, website development services"
        },
        {
          "property": "og:type",
          "content": "website"
        },
        {
          "property": "og:title",
          "content": "Web Application Development Company | Website Development Services"
        },
        {
          "property": "og:description",
          "content": "We are a leading website design and development company helping our clients with professional custom web design services. Hire our web developers for the best offerings."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/web-application-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "ProgressWebApp": {
      "title": "Progressive Web App Development Company | PWA Development Services",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
      "content": "Yug Enterprises offers seamless progressive web app development services to create a secure & high-performance PWA. Call us to hire PWA web app developers."
      },
      {
          "name": "keyword",
          "content": "progressive web development, progressive web app developers, progressive web app development, progressive web app development company, progressive web app development services, pwa developers, pwa development services"
        },
        {
          "property": "og:type",
          "content": "website"
        },
        {
          "property": "og:title",
          "content": "Progressive Web App Development Company | PWA Development Services"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises offers seamless progressive web app development services to create a secure & high-performance PWA. Call us to hire PWA web app developers."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/progressive-web-app-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },

    "HtmlDev": {
      "title": "PSD to HTML Web Development Services | HTML Development ",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Build completing, elegant, responsive, & fully functional designs. With our HTML web development services get the latest PSD to HTML conversion service that are SEO-friendly, browser compatible, and 100% hand-coded. Get connected now!!"
      },
      {
          "name": "keyword",
      "content": "html development, html development services,html web development,html website development, theme development"
        },
        {
          "property": "og:type",
          "content": "HtmlDev"
        },
        {
          "property": "og:title",
          "content": "PSD to HTML Web Development Services | yuginfo"
        },
        {
          "property": "og:description",
          "content": "Build completing, elegant, responsive, & fully functional designs. With our HTML web development services get the latest PSD to HTML conversion service that are SEO-friendly, browser compatible, and 100% hand-coded. Get connected now!!"
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/psd-to-html-development.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "MobAndroid": {
      "title": "Top android app development company offering services with interactive features - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Yug Enterprises offering the great android app development services. We are the top android app development company. Connect with us & we will develop an engaging application for your business."
          },
          {
              "name": "keyword",
              "content": "android development services, android app development, android app development company, android app development services, android application development company, android development company, android mobile app development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Top android app development company offering services with interactive features - Yug Enterprises",
            },
            {
              "property": "og:description",
              "content": "Yug Enterprises offering the great android app development services. We are the top android app development company. Connect with us & we will develop an engaging application for your business."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/technologies-mobile-android.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "JSAngular": {
      "title": "Get the best Angular JS Mobile App Development Services from prolific experts - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "Unlock the power with the top-rated angularjs development company & simplify the applications and websites with our leading Angular JS Development services. We will built an amazing website for you and help you expand your business."
    },
    {
        "name": "keyword",
        "content": "angular js development services, angularjs development company, angular web development, angularjs mobile app development, angular js company"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Get the best Angular JS Mobile App Development Services from prolific experts - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content": "Unlock the power with the top-rated angularjs development company & simplify the applications and websites with our leading Angular JS Development services. We will built an amazing website for you and help you expand your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-javascript-angular-js.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    
    },
    "CMSDrupal": {
      "title": "Top Drupal Development Agency | Drupal Developer - Yug Enterprises",
      "titleTemplate": " | Yug Enterprises %s",
      "meta": [
        {
      "name": "description",
          "content": "Yug Enterprises is a pioneer in offering Drupal Development Services. We offer seamless and responsive Drupal Module Development of websites & apps. Hire Drupal developers & they will built an interactive site for your business."
      },
      {
          "name": "keyword",
      "content": "drupal development services, drupal development, drupal development company, drupal development agency, hire drupal developers, drupal developer, drupal module development"
        },
        {
          "property": "og:type",
          "content": "CMSDrupal"
        },
        {
          "property": "og:title",
          "content": "Top Drupal Development Agency | Drupal Developer - Yug Enterprises"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises is a pioneer in offering Drupal Development Services. We offer seamless and responsive Drupal Module Development of websites & apps. Hire Drupal developers & they will built an interactive site for your business."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/technologies-cms-drupal.html"
        },
        {
          "property": "og:site_name",
          "content": "YugInfo"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "WebDotnet": {
      "title": "Renowned asp.net development company | asp net development company",
      "titleTemplate": "%s - Yug Enterprises",
      "meta": [
        {
      "name": "description",
          "content": "Yug Enterprises is one of the prominent .net development companies in Singapore & India. Our asp.net developers create reliable & high-productive web applications that are user-centric. We focus on delivering end-to-end projects to our customers within a given time period."
      },
      {
          "name": "keyword",
      "content": "asp net development company, dot net development company, dot net companies, asp net, asp dot net"
        },
        {
          "property": "og:type",
          "content": "WebDotnet"
        },
        {
          "property": "og:title",
          "content": "Renowned asp.net development company | asp net development company"
        },
        {
          "property": "og:description",
          "content": "Yug Enterprises is one of the prominent .net development companies in Singapore & India. Our asp.net developers create reliable & high-productive web applications that are user-centric. We focus on delivering end-to-end projects to our customers within a given time period."
        },
        {
          "property": "og:url",
          "content": "https://yuginfo.in/technologies-web-dotnet.html"
        },
        {
          "property": "og:site_name",
          "content": "yuginfo.in"
        },
        {
          "property": "og:image",
          "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
        },
        
  
        
        
        
        
        
    ]
    },
    "MobIOS": {
      "title": "Acclaimed ios app development company offering ios development services",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":  "Having years of experience in iOS development, we are a highly renowned ios app development company. Get the best ios app development services from our expert ios app development team. We are committed on delivering end-to-end projects to our customers within a given time."
    },
    {
        "name": "keyword",
        "content": "ios app development, ios development, ios development services, iphone app development, ios app development services, ios app development company, ios mobile app development, top ios app development company"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Acclaimed ios app development company offering ios development services",
      },
      {
        "property": "og:description",
        "content":"Having years of experience in iOS development, we are a highly renowned ios app development company. Get the best ios app development services from our expert ios app development team. We are committed on delivering end-to-end projects to our customers within a given time."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    
    },
    "CMSKentico": {
      "title": "Hire Kentico Web Development Services Agency - Yug Enterprises",
      "titleTemplate": " | Yug Enterprises %s",
      "meta": [
      {
    "name": "description",
    "content": "We help small businesses, startups, & enterprises to build an engaging Kentio CMS website that works well for all the online platforms. Drive traffic & convert them into effective leads with our Kentico Development Services."
    },
    {
        "name": "keyword",
        "content": "kentico cms, kentico development services, kentico web development services, Kentico, kentico web development"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Hire Kentico Web Development Services Agency - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"We help small businesses, startups, & enterprises to build an engaging Kentio CMS website that works well for all the online platforms. Drive traffic & convert them into effective leads with our Kentico Development Services."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-cms-kentico.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "EcommMagento": {
      "title": "The leading Magento Development Agency | Certified Magento Developer - Yug Enterprises",
      "titleTemplate": "- Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "As the finest Magento ecommerce development company, we offer top-class Magento development services to help you build robust and scalable websites and applications for your organization."
    },
    {
        "name": "keyword",
        "content":  "magento development company, magento development services, magento developer, magento development agency, magento ecommerce development company, certified magento developer"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"The leading Magento Development Agency | Certified Magento Developer - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"As the finest Magento ecommerce development company, we offer top-class Magento development services to help you build robust and scalable websites and applications for your organization."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    
    },
    "JSNode": {
      "title": "Get in touch with theleading Node JS Development Company - Yug Enterprises",
      "titleTemplate": "%s - Yug Enterprises",
     
    "meta": [
      {
    "name": "description",
    "content":"We offer cutting edge Node JS Development Services that enables the real-time growth of your business. Hire Node JS Developer from Yug Enterprises & get seamless websites & applications for your business."
    },
    {
        "name": "keyword",
        "content": "node js development services, nodejs development company, nodejs, node js developer"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Get in touch with theleading Node JS Development Company - Yug Enterprises"
      },

      {
        "property": "og:description",
        "content":"We offer cutting edge Node JS Development Services that enables the real-time growth of your business. Hire Node JS Developer from Yug Enterprises & get seamless websites & applications for your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-javascript-node-js.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "EcommNopcommerce": {
      "title": "Professional NopCommerce Development Company - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":"Hire NopCommerce Developer from us and get the latest NopCommerce Development Services. Our NopCommerce Plugin Development will help you grow your business in an online marketplace. Connect Now!"
    },
    {
        "name": "keyword",
        "content": "nopcommerce, nopcommerce development services, nopcommerce plugin development, nopcommerce development company, hire nopcommerce developer, nopcommerce services, nopcommerce github"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Professional NopCommerce Development Company - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Hire NopCommerce Developer from us and get the latest NopCommerce Development Services. Our NopCommerce Plugin Development will help you grow your business in an online marketplace. Connect Now!"
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "EcommOpencart": {
      "title": "Experience eminent OpenCart Services at Yug Enterprises",
      "titleTemplate": "%s | yuginfo",
    "meta": [
      {
    "name": "description",
    "content": "G & scalable OpenCart Development Services. OpenCart is a powerful open-source online shopping platform that enables the user to create a highly engaging OpenCart website."
    },
    {
        "name": "keyword",
        "content": "opencart, opencart services, opencart development services, opencart website"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Experience eminent OpenCart Services at Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Get high-qupenCart Development Services. OpenCart is a powerful open-source online shopping platform that enables the user to create a highly engaging OpenCart website."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "MobPhonegap": {
      "title": "The best Adobe PhoneGap Development Services - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "We offer PhoneGap Application Development Services that are cost-effective, efficient, & easy to use. We develop a fast and user-friendly PhoneGap Hybrid App for your business."
    },
    {
        "name": "keyword",
        "content": "phonegap, adobe phonegap, phonegap build, phonegap hybrid app"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"The best Adobe PhoneGap Development Services - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"We offer PhoneGap Application Development Services that are cost-effective, efficient, & easy to use. We develop a fast and user-friendly PhoneGap Hybrid App for your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-mobile-phonegap.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "WebPHP": {
      "title": "Get the leading php web design services from the best php programmer - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
      
    "meta": [
      {
    "name": "description",
    "content":  "As a leading php web development company, we aim to implement a secure and powerful server-side for all kinds of web applications. We render php development solutions to meet the need of the enterprises."
    },
    {
        "name": "keyword",
        "content": "php development services, php web development, php development, php programmer, php laravel developer, php web development company, php web design"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Get the leading php web design services from the best php programmer - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"As a leading php web development company, we aim to implement a secure and powerful server-side for all kinds of web applications. We render php development solutions to meet the need of the enterprises."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "EcommPrestashop": {
      "title": "Leading prestashop development company offering Prestashop services",
      "titleTemplate": " | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":  "Prestashop is an excellent professional platform for developers & enterprises. We provide adaptable Prestashop development services to create an efficient website at affordable costs."
    },
    {
        "name": "keyword",
        "content": "prestashop development company, prestashop development services, prestashop, prestashop services"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Leading prestashop development company offering Prestashop services",
      },
      {
        "property": "og:description",
        "content":"Prestashop is an excellent professional platform for developers & enterprises. We provide adaptable Prestashop development services to create an efficient website at affordable costs."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-ecommerce-prestashop.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "WebPython": {
      "title": "Experience the best Full-Stack Python Development from our experts - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":"Yug Enterprises has been offering the best Python Development Services to help you expand your business. As the growing Python Development Company, we do engaging Python Mobile App Development for your business."
    },
    {
        "name": "keyword",
        "content":  "python development services, python web development, python development company, python mobile app development, python development, full stack python"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Experience the best Full-Stack Python Development from our experts - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Yug Enterprises has been offering the best Python Development Services to help you expand your business. As the growing Python Development Company, we do engaging Python Mobile App Development for your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "JSReact": {
      "title": "Leading React Native App Development Company | React JS Mobile Apps - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "We are offering top react native app development services in Singapore & India, you can resource our expert developers to experience the best services from the leading React native development company."
    },
    {
        "name": "keyword",
        "content":  "react native app development, react native development services, react native app development company, react native development company, react native app development services, react js mobile apps"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Leading React Native App Development Company | React JS Mobile Apps - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"We are one of the top React JS development company in Singapore & India and you can hire React JS developers to get the best offshore React JS development services."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "WebROR": {
      "title": "High-rated ruby on rails development services | ruby on rails framework",
      "titleTemplate": "%s | Yug Enterprises",
 "meta": [
      {
    "name": "description",
    "content": "We are well-known ruby on rails development company, providing the best ruby development services. Our ROR Developers will build reliable web apps."
    },
    {
        "name": "keyword",
        "content": "ruby on rails development company, ruby on rails development services, ruby on rails, ruby on rails developer, ror developer, ruby on rails framework, ruby development services"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content": "High-rated ruby on rails development services | ruby on rails framework",
      },
      {
        "property": "og:description",
        "content": "We are well-known ruby on rails development company, providing the best ruby development services. Our ROR Developers will build reliable web apps."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },

      
      
      
      
      
  ]
    },
    "EcommShopify": {
      "title": "Shopify App Development Company | Shopify Web Designer  - Yug Enterprises",
      "titleTemplate": "%s | yuginfo",
      "meta": [
      {
    "name": "description",
    "content": "Are you looking to hire an adroit Shopify expert? We are a leading Shopify website development company offering its services around the globe."
    },
    {
        "name": "keyword",
        "content": "shopify developer, shopify development company, shopify app development, shopify web designer, shopify development agency, shopify website developer, shopify app development company, shopify expert"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content": "Shopify App Development Company | Shopify Web Designer  - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Are you looking to hire an adroit Shopify expert? We are a leading Shopify website development company offering its services around the globe."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "CMSSitefinity": {
      "title": "Hire Sitefinity Developer from Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "We promise you to deliver the Custom Sitefinity Development Services to burgeon your business among the competitors as the leading Sitefinity CMS Development Company in town."
    },
    {
        "name": "keyword",
        "content":  "sitefinity development services, sitefinity development, sitefinity developer, sitefinity cms development, custom sitefinity development"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Hire Sitefinity Developer from Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"We promise you to deliver the Custom Sitefinity Development Services to burgeon your business among the competitors as the leading Sitefinity CMS Development Company in town."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-cms-sitefinity.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "CMSUmbraco": {
      "title": "Leading Umbraco CMS Development Company with expert developers - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "Browse our Umbraco Web Development Company to witness the professional & highly skilled services from our Umbraco Developer team. We leverage our expertise in building, customizing, & migrating sites for your business."
    },
    {
        "name": "keyword",
        "content": "umbraco developer, umbraco web development, umbraco development, umbraco agency, umbraco, umbraco cms"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Leading Umbraco CMS Development Company with expert developers - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Browse our Umbraco Web Development Company to witness the professional & highly skilled services from our Umbraco Developer team. We leverage our expertise in building, customizing, & migrating sites for your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "JSVue": {
      "title": "The best VUE JS Development Company - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
      "meta": [
      {
    "name": "description",
    "content": "We offer professional Vue JS development services that are highly scalable & robust. As the leading VUE JS Development Company we build flexible & lightweight VUE JS Framework that will enhance the visibility of your business."
    },
    {
        "name": "keyword",
        "content":  "vuejs development company, vuejs development services, vuejs, vuejs bootstrap, vue js framework"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"The best VUE JS Development Company - Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"We offer professional Vue JS development services that are highly scalable & robust. As the leading VUE JS Development Company we build flexible & lightweight VUE JS Framework that will enhance the visibility of your business."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "EcommWoocommerce": {
      "title": "Top-rated WooCommerce Development Agency | WooCommerce Development Company Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "We offer the best-in-class WooCommerce Development Services to develop a unique, engaging, and high-performing eCommerce website. Hire WooCommerce Developer & get a site for your online store."
    },
     {
      "name": "keyword",
      "content": "woocommerce development services, woocommerce development company, woocommerce development agency, woocommerce developer"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Top-rated WooCommerce Development Agency | WooCommerce Development Company Yug Enterprises",
      },
      {
        "property": "og:description",
        "content":"Re-imagine your site to stunning solutions that drive high performance and fill the communication gap between you and your targeted customers. As a responsible Sitefinity development company, we enable the opportunity to connect a large audience."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/technologies-ecommerce-woocommerce.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "CMSWordpress": {
      "title": "Top wordpress development company | Wordpress web designer",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":  "We promise to deliver high-quality WordPress website development services as the leading WordPress web development company. Our expert developers consist of skillful knowledge of WordPress, try our services now."
    },
    {
        "name": "keyword",
        "content":  "wordpress web development, wordpress development agency, wordpress web designer, wordpress web development company, wordpress website designer, wordpress web developer, wordpress website development services"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Top wordpress development company | Wordpress web designer",
      },
      {
        "property": "og:description",
        "content":"We promise to deliver high-quality WordPress website development services as the leading WordPress web development company. Our expert developers consist of skillful knowledge of WordPress, try our services now."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "MobXamarin": {
      "title": "Experience an effective Xamarin Development | Xamarin Android | Xamarin iOS",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content": "One of the top Xamarin App Development Company in Singapore & India. We offer to build high-end, native & cross-platform Xamarin Apps to fulfill your business needs."
    },
    {
        "name": "keyword",
        "content":  "xamarin app development, xamarin development, xamarin apps, xamarin, xamarin forms, xamarin android, xamarin ios"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Experience an effective Xamarin Development | Xamarin Android | Xamarin iOS",
      },
      {
        "property": "og:description",
        "content":"One of the top Xamarin App Development Company in Singapore & India. We offer to build high-end, native & cross-platform Xamarin Apps to fulfill your business needs."
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/web-application-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "NFTdevelopmentSerives": {
      "title": "Best NFT Development services | NFT Game Development Company",
      "titleTemplate": "%s | Yug Enterprises",
    "meta": [
      {
    "name": "description",
    "content":  "Experience the best NFT Development Services from the finest NFT Gaming Platform Development Company & get amazing NFT Marketplace Development Services in town. "
    },
    {
        "name": "keyword",
        "content":  "nft development company, nft marketplace development company, nft development services, nft game development company, nft marketplace development services, nft gaming platform development company"
      },
      {
        "property": "og:type",
        "content": "website"
      },
      {
        "property": "og:title",
        "content":"Best NFT Development services | NFT Game Development Company",
      },
      {
        "property": "og:description",
        "content":"Experience the best NFT Development Services from the finest NFT Gaming Platform Development Company & get amazing NFT Marketplace Development Services in town. "
      },
      {
        "property": "og:url",
        "content": "https://yuginfo.in/nft-development.html"
      },
      {
        "property": "og:site_name",
        "content": "YugInfo"
      },
      {
        "property": "og:image",
        "content": "https://info.yuginfo.in/BlogImagesyug/202132212722-899bgheadernew.jpg"
      },
      

      
      
      
      
      
  ]
    },
    "HireAndroid": {
      "title": "Hire Android App Developers | Android App Development Company India",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Our team of Android App Developers offers world-class Android App Development Services. You can hire dedicated Android Programmers on an hourly, weekly, or monthly basis."
          },
          {
              "name": "keyword",
              "content": "hire android developers, hire android app developer, hire dedicated android developer, android developers, hire dedicated android app developer, android app development company, android app development services"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Dedicated Android App Developers | Android App Development Company India",
            },
            {
              "property": "og:description",
              "content": "Our team of Android App Developers offers world-class Android App Development Services. You can hire dedicated Android Programmers on an hourly, weekly, or monthly basis."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-android-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireJava": {
      "title": "Hire Java Developer India | Hire Java Programmer Online",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire professional Java developers from Yug Enterprises.   Achieve your business goals with our professional java programmers for a good price in India."
          },
          {
              "name": "keyword",
              "content": "hire java developer, hire java programmers, hire dedicated java developers, java developers for hire, java development services, Java developers"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Java Developer India | Hire Java Programmer Online",
            },
            {
              "property": "og:description",
              "content": "Hire professional Java developers from Yug Enterprises.   Achieve your business goals with our professional java programmers for a good price in India."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-java-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireDotnet": {
      "title": "Hire ASP.Net Developers | Hire dot Net Developer",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire dedicated ASP.Net Developers from the leading dot Net development company to grow your business with no overhead costs & get reliable web apps that are user-centric."
          },
          {
              "name": "keyword",
              "content": "hire dot net developer, hire dedicated dot net developers, hire asp dot net developer, hire dot net programmers, asp net development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire ASP.Net Developers | Hire dot Net Developer",
            },
            {
              "property": "og:description",
              "content": "Hire dedicated .Net Developers from the leading ASP.Net development company to grow your business with no overhead costs & get reliable web apps that are user-centric."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-dotnet-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HirePHP": {
      "title": "Hire Dedicated PHP Developers | PHP Web Development Company in India",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Looking to hire PHP Developers? Yug Enterprises has a team of the best PHP Programmers who are experienced, dynamic, & trustworthy. Hire Dedicated & Expert PHP Developers & Programmers."
          },
          {
              "name": "keyword",
              "content": "hire php developers, hire dedicated php developer, hire php programmer, dedicated php developer, best php developers, php developers, php development services, php web development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Dedicated PHP Developers | PHP Web Development Company in India",
            },
            {
              "property": "og:description",
              "content": "Looking to hire PHP Developers? Yug Enterprises has a team of the best PHP Programmers who are experienced, dynamic, & trustworthy. Hire Dedicated & Expert PHP Developers & Programmers."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-php-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HirePython": {
      "title": "Hire Python Developers | Hire Python Developers from India",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire Top Python Developers from India with Yug Enterprises. Leverage the expertise of Python programmers by hiring our team of professionals. Post your request now!"
          },
          {
              "name": "keyword",
              "content": "hire python developers, python developers, python software developer, hire dedicated python developers, python development services, python web development, python development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Python Developers | Hire Python Developers from India",
            },
            {
              "property": "og:description",
              "content": "Hire Top Python Developers from India with Yug Enterprises. Leverage the expertise of Python programmers by hiring our team of professionals. Post your request now!"
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-python-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireIOS": {
      "title": "Hire iOS App Developer in India | Hire iOS Developer",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire dedicated iOS Developers in India who can boost your business growth with iOS app development services. Hire an iOS programmer in easy steps by connecting with us now!"
          },
          {
              "name": "keyword",
              "content": "hire ios developers, hire iphone developer, hire dedicated ios developers, hire iphone app developer, ios programmer, iphone app development,ios app development services, ios app development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire iOS App Developer in India | Hire iOS Developer",
            },
            {
              "property": "og:description",
              "content": "Hire dedicated iOS Developers in India who can boost your business growth with iOS app development services. Hire an iOS programmer in easy steps by connecting with us now! "
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-ios-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireFlutter": {
      "title": "Hire Flutter App Developers India | Flutter App Development Company",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Yug Enterprises is a renowned Flutter App Development Company. You can hire Flutter App Developers India from us to accelerate the revenue of your business"
          },
          {
              "name": "keyword",
              "content": "hire flutter developers, hire flutter app developers, hire dedicated flutter developer, hire a flutter developer, flutter app development company, flutter app development services, hire flutter developers India"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Flutter App Developers India | Flutter App Development Company",
            },
            {
              "property": "og:description",
              "content": "Yug Enterprises is a renowned Flutter App Development Company. You can hire Flutter App Developers India from us to accelerate the revenue of your business"
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-flutter-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireReactNative": {
      "title": "Hire React Native Developers | Hire React Programmer",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire React Native Developers from Yug Enterprises & get top-notch React Native App Development Services. We offer a team of the best-hired React programmers."
          },
          {
              "name": "keyword",
              "content": "React Native App Experts for Hire, hire dedicated react native developers, hire react native developers, hire react native app developer, react native developers, react native app development services, react native app development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire React Native Developers | Hire React Programmer",
            },
            {
              "property": "og:description",
              "content": "Hire React Native Developers from Yug Enterprises & get top-notch React Native App Development Services. We offer a team of the best-hired React programmers."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-react-native-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireIonic": {
      "title": "Hire Ionic Developer | Ionic App Development",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": " Hire Ionic Developer from Yug Enterprises. Our team of Ionic App Development has expertise in developing effective cross-platform Ionic mobile apps."
          },
          {
              "name": "keyword",
              "content": "hire ionic developers, Hire dedicated ionic developers, hire ionic framework developer, ionic developers, ionic app development, ionic mobile app development, ionic mobile development, ionic framework, ionic"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Ionic Developer | Ionic App Development",
            },
            {
              "property": "og:description",
              "content": " Hire Ionic Developer from Yug Enterprises. Our team of Ionic App Development has expertise in developing effective cross-platform Ionic mobile apps."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-ionic-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireShopify": {
      "title": "Hire Shopify Developers | Shopify Development Agency",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire Certified Shopify Developers from us. Our Shopify Experts are trusted & build scalable, robust, & user-friendly Shopify apps & websites for your online store."
          },
          {
              "name": "keyword",
              "content": "hire shopify developers, hire shopify expert, shopify developers, shopify web designer, shopify website designer, shopify development agency"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Shopify Developers | Shopify Development Agency",
            },
            {
              "property": "og:description",
              "content": "Hire Certified Shopify Developers from us. Our Shopify Experts are trusted & build scalable, robust, & user-friendly Shopify apps & websites for your online store."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-shopify-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireMagento": {
      "title": "Hire Magento Developers India | Hire Dedicated Magento Developers ",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Are you looking for a Magento Developer? Hire Certified Magento Developers from Yuginfo, a renowned Magento Development Company India. Contact us to hire Magento Experts."
          },
          {
              "name": "keyword",
              "content": "hire magento developers, magento experts, hire dedicated magento developers, looking for magento developer, magento development company, magento development services, magento development agency, magento ecommerce development company, certified magento developer"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Magento Developers India | Hire Dedicated Magento Developers ",
            },
            {
              "property": "og:description",
              "content": "Are you looking for a Magento Developer? Hire Certified Magento Developers from Yuginfo, a renowned Magento Development Company India. Contact us to hire Magento Experts."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-magento-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireShopware": {
      "title": "Hire Shopware Developer | Hire Shopify Experts",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire Shopware Developers from Yug Enterprises for your next project. We have a team of experienced shopify experts at affordable rates."
          },
          {
              "name": "keyword",
              "content": "hire shopware developers, shopware development, shopware developers, shopware, shopware designer, shopware theme development"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Shopware Developer | Hire Shopify Experts",
            },
            {
              "property": "og:description",
              "content": "Hire Shopware Developers from Yug Enterprises for your next project. We have a team of experienced shopify experts at affordable rates."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-shopware-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireWoocommerce": {
      "title": "Hire WooCommerce Developers | WooCommerce Programmers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire WooCommerce Developers, Engineers, & Programmers on an hourly, weekly, or monthly basis. Get the best Custom WooCommerce Development in a cost-effective way."
          },
          {
              "name": "keyword",
              "content": "hire woocommerce developers, woocommerce developers, woocommerce development, woocommerce theme development, woocommerce development agency, custom woocommerce development, woocommerce plugin developers"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire WooCommerce Developers | WooCommerce Programmers",
            },
            {
              "property": "og:description",
              "content": "Hire WooCommerce Developers, Engineers, & Programmers on an hourly, weekly, or monthly basis. Get the best Custom WooCommerce Development in a cost-effective way."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-woocommerce-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireWordpress": {
      "title": "Hire WordPress Developers | Hire Wordpress Programmers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Looking to hire WordPress Developers in India? Our team of dedicated WordPress programmers & experts have adroit skills in developing WordPress website, Themes & Plugins."
          },
          {
              "name": "keyword",
              "content": "hire wordpress developers, hire dedicated wordpress developer, hire wordpress expert, wordpress programmers, indian wordpress developers, wordpress development agency, wordpress web designer, wordpress web development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire WordPress Developers | Hire Wordpress Programmers",
            },
            {
              "property": "og:description",
              "content": "Looking to hire WordPress Developers in India? Our team of dedicated WordPress programmers & experts have adroit skills in developing WordPress website, Themes & Plugins."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-wordpress-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireDrupal": {
      "title": "Hire a Drupal Developer | Drupal Developers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Looking to hire a Drupal developer to develop a website from scratch? We have a team of 25+ drupal developers to build next-gen websites for your business."
          },
          {
              "name": "keyword",
              "content": "hire drupal developers, hire dedicated drupal developers, drupal developers, drupal agency, drupal development services, drupal web development, drupal development agency, drupal web development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire a Drupal Developer | Drupal Developers",
            },
            {
              "property": "og:description",
              "content": "Looking to hire a Drupal developer to develop a website from scratch? We have a team of 25+ drupal developers to build next-gen websites for your business."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-drupal-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireWix": {
      "title": "Hire Wix Developers | Hire Wix Designers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "We offer flexible ways to hire Wix developers and designers with expertise in crafting high-performance apps & websites offering customized Wix solutions under your budget."
          },
          {
              "name": "keyword",
              "content": "hire wix developers, wix website development, wix developers, wix web developer, wix web development, wix android app development, wix app development"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Wix Developers | Hire Wix Designers",
            },
            {
              "property": "og:description",
              "content": "We offer flexible ways to hire Wix developers and designers with expertise in crafting high-performance apps & websites offering customized Wix solutions under your budget."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-wix-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireVue": {
      "title": "Hire Vue JS Developers | Vue JS Development Company",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire Vue JS Developers to experience real-time web apps. Get quick access to our experienced Vue JS Development Services to amplify the growth of your business."
          },
          {
              "name": "keyword",
              "content": "hire vue js developers, vue js developers, vue js, hire vue developers, vue js development company, vue js development services"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Vue JS Developers | Vue JS Development Company",
            },
            {
              "property": "og:description",
              "content": "Hire Vue JS Developers to experience real-time web apps. Get quick access to our experienced Vue JS Development Services to amplify the growth of your business."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-vue-js-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireAngular": {
      "title": "Hire AngularJS Developer | 50+ Senior Angular Developers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire Angular JS Developers from us to build performant & robust web apps. We offer affordable Angular Developers that help you kick-start your business."
          },
          {
              "name": "keyword",
              "content": "hire angular js developers, hire dedicated angular js developers, angular js developers, angular js development company, angular js web development company, angular js web application development, hire dedicated angular js developers"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire AngularJS Developer | 50+ Senior Angular Developers",
            },
            {
              "property": "og:description",
              "content": "Hire Angular JS Developers from us to build performant & robust web apps. We offer affordable Angular Developers that help you kick-start your business."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-angular-js-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireNode": {
      "title": "Hire Node JS Developers | Node JS Development Services",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "We are the top Node JS Development Company that offers you to hire Node Developers for your upcoming projects. Connect with us now for further queries!"
          },
          {
              "name": "keyword",
              "content": "hire node js developers, hire nodejs developer, hire node developers, node js developers, node js development company, node js development services"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire Node JS Developers | Node JS Development Services",
            },
            {
              "property": "og:description",
              "content": "We are the top Node JS Development Company that offers you to hire Node Developers for your upcoming projects. Connect with us now for further queries!"
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-node-js-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireReact": {
      "title": "Hire React JS Developer | React Programmer",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Hire React JS Developer from Yug Enterprises who are highly experienced & certified in their work. You can hire a React programmer team to grow your business in a competitive market. "
          },
          {
              "name": "keyword",
              "content": "hire react js developers, hire react developer, react js developers for hire, hire dedicated react js developers, hire react js development team"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Hire React JS Developer | React Programmer",
            },
            {
              "property": "og:description",
              "content": "Hire React JS Developer from Yug Enterprises who are highly experienced & certified in their work. You can hire a React programmer team to grow your business in a competitive market. "
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-react-js-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireNFTEcommerce": {
      "title": "NFT Ecommerce Platform: Experience the best - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Yug Enterprises is offering tremendous services of NFT Market, NFT art, etc. Connect with us & get a demo of our Non-Fungible Token offerings. Click to learn more!"
          },
          {
              "name": "keyword",
              "content": "non fungible tokens, nft market, nft trading, nft ecommerce platform, nft art, nft market, Nftmarketplace"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"NFT Ecommerce Platform: Experience the best - Yug Enterprises",
            },
            {
              "property": "og:description",
              "content": "Yug Enterprises is offering tremendous services of NFT Market, NFT art, etc. Connect with us & get a demo of our Non-Fungible Token offerings. Click to learn more!"
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-nft-ecommerce-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "HireNFTMarketplace": {
      "title": "Professional NFT Marketplace Website Development | NFT Marketplace Development Company",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Our NFT Marketplace Development Services offers a promising source of revenue. Let’s work together & build a stellar NFT Marketplace Crypto world & come across the leading NFT Platforms."
          },
          {
              "name": "keyword",
              "content": "nft marketplace, nft art marketplace, best nft marketplace, nft marketplace website, marketplace nft, best nft platform, nft marketplace development, nfts marketplace, nft marketplace crypto"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Professional NFT Marketplace Website Development | NFT Marketplace Development Company",
            },
            {
              "property": "og:description",
              "content": "Our NFT Marketplace Development Services offers a promising source of revenue. Let’s work together & build a stellar NFT Marketplace Crypto world & come across the leading NFT Platforms."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hire-nft-marketplace-developers.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "IOSAppDev": {
      "title": "iOS App Development Company India | iOS App Development Services",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Get the best iOS & iPhone application development services from the finest iOS App Development Company in India & Singapore. Enjoy the best iOS apps according to your business needs. "
          },
          {
              "name": "keyword",
              "content": "ios app development, ios development, ios development services, iphone app development, ios app development services, ios app development company, ios mobile app development, top ios app development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"iOS App Development Company India | iOS App Development Services",
            },
            {
              "property": "og:description",
              "content": "Get the best iOS & iPhone application development services from the finest iOS App Development Company in India & Singapore. Enjoy the best iOS apps according to your business needs. "
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/ios-app-development.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "AndroidAppDev": {
      "title": "Android App Development Company in India | Hire Android Developers",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "We offer custom Android App Development Services provided by our expert team of Android App Developers. Connect with us to get prolific Android development services for your business. "
          },
          {
              "name": "keyword",
              "content": "android development services, android app development, android app development company, android app development services, android application development company, android development company, android app developers, android mobile app development company"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Android App Development Company in India | Hire Android Developers",
            },
            {
              "property": "og:description",
              "content": "We offer custom Android App Development Services provided by our expert team of Android App Developers. Connect with us to get prolific Android development services for your business."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/android-app-development.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "FlutterAppDev": {
      "title": "Top Flutter App Development Company | Flutter app Development Services",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "As a leading Flutter App Development Company, we offer Flutter App Developers to help businesses grow by developing budget-friendly native apps for operating systems."
          },
          {
              "name": "keyword",
              "content": "flutter development company, flutter app development company, flutter development services, flutter app development services, flutter development, flutter mobile app development, flutter for web development"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Top Flutter App Development Company | Flutter app Development Services",
            },
            {
              "property": "og:description",
              "content": "As a leading Flutter App Development Company, we offer Flutter App Developers to help businesses grow by developing budget-friendly native apps for operating systems."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hybrid-flutter-development.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "IonicAppDev": {
      "title": "Ionic App Development Company in India |  Ionic App Developers in Singapore",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "We are a top-notch Ionic Mobile App Development Company in India. Our team of expert Ionic Developers builds Ionic Framework & offers cost-effective Ionic App Development solutions."
          },
          {
              "name": "keyword",
              "content": "ionic app development company, ionic mobile app development company, ionic app developer, ionic framework, ionic developer, ionic mobile app development"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Ionic App Development Company in India |  Ionic App Developers in Singapore",
            },
            {
              "property": "og:description",
              "content": "We are a top-notch Ionic Mobile App Development Company in India. Our team of expert Ionic Developers builds Ionic Framework & offers cost-effective Ionic App Development solutions."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hybrid-ionic-development.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "ReactnativeAppDev": {
      "title": "React Native App Development Company And Service Provider - Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Yug Enterprises is a leading app development company that offers React Native app development services for Android and iOS in India and Singapore."
          },
          {
              "name": "keyword",
              "content": "react native development, react native developer, react native app development company, react native development services, react native development company, react native"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"React Native App Development Company And Service Provider - Yug Enterprises",
            },
            {
              "property": "og:description",
              "content": "Yug Enterprises is a leading app development company that offers React Native app development services for Android and iOS in India and Singapore."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/hybrid-react-native.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "ARAppDev": {
      "title": " Augmented Reality App Development Services | AR App Development",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "We are one of the top Augmented Reality Companies offering the best Augmented Reality Software to accelerate your business growth. We develop AR & VR solutions as per client needs."
          },
          {
              "name": "keyword",
              "content": "augmented reality company, top augmented reality companies, augmented reality agency, augmented reality app development services, best augmented reality software, augmented reality"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Augmented Reality App Development Services - Yug Enterprises",
            },
            {
              "property": "og:description",
              "content": "We are one of the top Augmented Reality Companies offering the best Augmented Reality Software to accelerate your business growth. We develop AR & VR solutions as per client needs."
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/augumented-reality.html"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/2022824164358-143hire-developers-all.jpg"
            },
            

            
            
            
            
            
        ]
    
    },
    "CareerDev": {
      "title": "Grow your career with Yug Enterprises",
      "titleTemplate": "%s | Yug Enterprises",
        "meta": [
          {
          "name": "description",
          "content": "Experience the best working environment and get a chance to work with the best clients globally. Check out our job openings and apply today! "
          },
          {
              "name": "keyword",
              "content": "Career, career opportunities"
            },
            {
              "property": "og:type",
              "content": "website"
            },
            {
              "property": "og:title",
              "content":"Grow your career with Yug Enterprises",
            },
            {
              "property": "og:description",
              "content": "Experience the best working environment and get a chance to work with the best clients globally. Check out our job openings and apply today! "
            },
            {
              "property": "og:url",
              "content": "https://yuginfo.in/career"
            },
            {
              "property": "og:site_name",
              "content": "YugInfo"
            },
            {
              "property": "og:image",
              "content": "https://info.yuginfo.in/BlogImagesyug/202282910387-972hiring-latest.jpg"
            },
            

            
            
            
            
            
        ]
    
    },

  }
}