import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-hire-us',
  templateUrl: './why-hire-us.component.html',
  styleUrls: ['./why-hire-us.component.scss']
})
export class WhyHireUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
