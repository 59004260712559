import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
})
export class ContactusComponent implements OnInit {
  objForm: any;
  errorFlag: boolean = false;
  cat: any;
  checkVar : any;
  checkVar2 : any;
  styles : any = [
    {
      id:"removethisdevelop",
      href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
      rel:"stylesheet",
      elementType:"link"
    }
  ]
  constructor(
    private builder: FormBuilder,
    private apiservice: ApisService,
    private meta: MetafieldsService,
    private router: Router
  ) {
    this.apiservice.addAndRemoveClass(
      'aboutus contactus',
      'profilepagesection blogcontentbody mainaboutus services careers technology getaquote HomeMain blog'
    );
    this.objForm = this.builder.group({
      full_name: ['', Validators.required],
      category: [''],
      phone_num: ['', Validators.required],
      email_add: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required],
      country: [''],
    });
  }

  saveUser() {
    console.log('helloofujfyh');
    this.objForm.patchValue({
      category: this.cat,
    });
    if (this.objForm.status == 'INVALID') {
      this.errorFlag = true;
      console.log('invalid', this.objForm);
    } else {
      let obj = {
        email_add: this.objForm.value.email_add,
        category: this.objForm.value.category,
        full_name: this.objForm.value.full_name,
        phone_num: this.objForm.value.phone_num,
        message: this.objForm.value.message,
        pathname: this.router.url,
        country: this.objForm.value.country,
        form_type: 'submitFormContactForm',
      };

      this.apiservice.add(obj, environment.apis.users).then((res) => {
        this.objForm.reset();
        this.errorFlag = false;
      });
    }
  }
  ngOnInit(): void {
    this.meta.setMetaFields('ContactUs');
    this.styles.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }

  check(event : any){
    console.log("hello");
    this.checkVar = event.target.value;
  }
  check2(event : any){
    console.log("hello");
    this.checkVar2 = event.target.value;
  }
  categories(divided: any) {
    this.cat = divided;
  }
  ngOnDestroy(): void {
    this.styles.map((element:any)=>{
      this.meta.removeLink("#removethisdevelop");
    });
  }
}
