<div class="top-section service-page">

    <section class="healthcare-banner instool aashi" style="background-image: url('https://info.yuginfo.in/yuginfo/home-new/image/about/VRImage.jpg')">
        <div class="container">
            <div class="row zmc">
                <div class="col-lg-7 col-md-7 col-sm-12 col-12 service-heading">
                    <div class="headtext">						
                        <h1>Augmented Reality (AR) Development Services
                        </h1>
                        <br>
                        <p>Thrive your business with Augmented Reality (AR) App Development Solutions and get a futuristic technology experience.

                        </p>
                        <a class="ar_contect-industries-header" href="#footer-form"><span>Hire Developers</span></a>
                        
                    </div>
                </div>
            </div>
       </div>
    </section>
</div>

<section class="services-row">
    <div class="container">
        <app-services-content-point [firstMAtchData]="firstMAtch"></app-services-content-point>
        <div class="softeare-developemnt-isv-main">
            <div class="row">
                <div class="col-lg-12 headingsec">
                    <h2>Augmented Reality Development with Authentic AR-Powered Solutions for your Business
                    </h2>
                    <p>The expert team of Augmented Reality leverage the potential of this amazing technology and ensure that all your business requirements are converted into extensive AR apps giving a lucrative user experience.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 softeare-developemnt-isv">
                    <ul>
                        <li class="mobi">
                            <div class="img-box-isv">
                                <img src="https://info.yuginfo.in/yuginfo/home-new/image/undraw_kotlin-mobile.svg" loading="lazy" alt="Ecommerce Application Development">
                                
                            </div>
                        </li>
                        <li>
                            <h3>Augmented Reality Healthcare Apps
                            </h3>
                            <p>We deliver ISO 13485-compliant Augmented Reality apps that reinforce young and accomplished healthcare professionals in operating multiple types of processes that too from simple injections or blood drawing to surgeries or operations. When it is supported by extremely precise data, healthcare specialists feel less stressed and more assured in their actions. 

                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 softeare-developemnt-isv">
                    <ul>
                        <li>
                            <h3>Education and Training AR Apps</h3>
                            <p>Yug Enterprises has a guide for Augmented Reality’s Education and Training Apps for unskilled professionals via medical procedure, product assembly, equipment installation, and other processes by providing visual tips and rapid texts. These AR apps can support on-the-job education in various spheres, including construction, manufacturing, healthcare, public services, and many others.

                            </p>
                        </li>
                        <li>
                            <h3>AR Apps for Tourism and Culture</h3>
                            <p>Augmented Reality applications take your sightseeing tours or museum gallery rounds to the next level. They will guide tourists over described locations,historical information, show directions, and display visual advertisements. Such kind of applications also boost the impressions of the visitors of your museum gallery by computing interactive and intriguing content for selected showpieces.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>			
</section>
<section class="quote-box" style="    background-color: #0d052a;">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <h4>Are You Ready To Discuss Your Project With Us?</h4>
        </div>
        <div class="col-md-3">
          <button>Hire Our Experts</button>
        </div>
      </div>
    </div>
</section>
<section class="services-row services-row-new white_bg border-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 headingsec">
                <h2>AR Application Development Process</h2>
                <p>To design excellent idea into reality, we process systematic methodology to accomplish the end objectives</p>
            </div>
            <div class="col-lg-12 smart-software-product-isv java-tech ">
                <ul>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/analysis.png" alt="Shopify Development">
                        <h3>Analysis</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/brainstorming.png" alt="Magento Development">
                        <h3>Brainstorming</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/time.png" alt="Shopware Development">
                        <h3>Turn Around Time</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/documentation.png" alt="Drupal Development">
                        <h3>Documentation</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/prototype.png" alt="PrestaShop Development">
                        <h3>Prototype</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/development.png" alt="WooCommerce Development">
                        <h3>Development</h3>
                    </li>
                    <li>
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/testing.png" alt="Opencart Development">
                        <h3>Testing</h3>
                    </li>
                    <li >
                        <img src="https://info.yuginfo.in/yuginfo/home-new/image/support.png" alt="OsCommerce Development">
                        <h3>Support</h3>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<app-why-choose-us></app-why-choose-us>

