import { Component, ElementRef, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
   service:boolean=false;
   type : any = "";
   stylesBootstrap : any = [
      {
        id:"removethisbootstrap",
        href:"https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.bundle.min.js",
        rel:"stylesheet",
        elementType:"link"
      }
  ]
   scripts : any = [
    {
      src: "https://www.googletagmanager.com/gtag/js?id=UA-101762804-2",
      type: "text/javascript",
      id: "gtag",
      elementType:"script"
    },
  ] 
  constructor(private el: ElementRef, private meta : MetafieldsService) { 
    
  }
  changeRole(type:any){
    if(this.type!=type){
      this.type = type;

    }else{
      this.type = ""
    }
  }

  ngOnInit(): void {
    this.scripts.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
    this.stylesBootstrap.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }
  addClickEvent(type:any){
   let  cols : any= document.getElementsByClassName('dropdown-menu-max');
   for(let i = 0; i < cols.length; i++) {
     cols[i].style.display = "none";
     setTimeout(() => {
     cols[i].style.display = "block";
     }, 10);

   }
    this.service=type
    // console.log(this.service);
  }
  closebox(){
    let myTag = this.el.nativeElement.querySelector(".mobile-menu-section");
    myTag.classList.remove('show');  
  }
}
