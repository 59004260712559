<section class="services-row services-row-new">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 headingsec">
          <h2>WHY CHOOSE Yug Enterprises?</h2>
          <p>Here’s what you get when you choose us as your preferred business partner:</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-12 whychoose-isv">
          <ul>
            <li>
              <h3>Top-Tier Talent</h3>
              <p>We have a competent team of <a href="#" class="page-link-p">software developers</a> who are at the same time learners as well. We provide you with the best ISVs and software solution with our cross functional expertise.</p>
            </li>
            <li>
              <h3>Supreme Quality</h3>
              <p>Our developers work their whole efforts to provide you with the best ISVs and software in the required time frame.</p>
            </li>
            <li>
              <h3>Seamless Communication</h3>
              <p>Our team members are extrovert collaborative &amp; accessible and make sure to address every queries regardless of the time-zone.</p>
            </li>
            <li>
              <h3>Transparency &amp; Accountability</h3>
              <p>We ensure 100% transparency with our clients and provide you with a clear insight into all aspects of the work, scope &amp; beyond.</p>
            </li>
            <li>
              <h3>Deep Tech Expertise</h3>
              <p>Our team members are always keen in learning new technologies and uses recent technological trends to meet the growing needs of the customers.</p>
            </li>
            <li>
              <h3>Post-Release Support</h3>
              <p> We provide endless post-release support for your products even after the deployment without any further cost.</p>
            </li>
          </ul>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 img-box-container">
          <div class="img-box-why-chosse">
            <img src="https://info.yuginfo.in/yuginfo/home-new/image/whyus-image.png" loading="lazy" alt="Product Web Development">
          </div>
        </div>
      </div>
    </div>
    
  </section>