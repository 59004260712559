<div class="steps mobile-custom-spacing-bottom-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 step_main_parent">
                <div class="step_block">
                    <div class="number"><span>1</span></div>
                    <h4>INQUIRY</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 step_main_parent">
                <div class="step_block">
                    <div class="number"><span>2</span></div>
                    <h4>INTERVIEW</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 step_main_parent">
                <div class="step_block">
                    <div class="number"><span>3</span></div>
                    <h4>SELECTION</h4>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-12 step_main_parent">
                <div class="step_block">
                    <div class="number"><span>4</span></div>
                    <h4>PAY & GET STARTED</h4>
                </div>
            </div>
        </div>
    </div>
</div>
