import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-java',
  templateUrl: './java.component.html',
  styleUrls: ['./java.component.scss']
})
export class JavaComponent implements OnInit {

  constructor(private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus technology','portfolio profilepagesection blogcontentbody mainaboutus blog contactus services getaquote careers HomeMain')
  }

  ngOnInit(): void {
    this.meta.setMetaFields('HireJava');
  }

}
