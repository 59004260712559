import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-android-kotlin',
  templateUrl: './android-kotlin.component.html',
  styleUrls: ['./android-kotlin.component.scss']
})
export class AndroidKotlinComponent implements OnInit {

  constructor() { }
  firstMAtch = ['4']
  ngOnInit(): void {
  }

}
