<!-- about us hero section start -->

<section  class="healthcare-banner instool aashi">
  <div  class="container">
          <div  class="row zmc">
                  <div 
                          class="col-lg-9 col-md-9 col-sm-12 col-12 service-heading">
                          <div  class="headtext">
                                  <h1 >DELIVERING YOU IMPECCABLE MOBILE AND WEB APP DEVELOPMENT SERVICES</h1><br
                                          >
                                  <p >We design and build superior digital products, delegating seamless user experiences across all modern platforms and devices and helping brands to expand their business globally.
                                  </p><a  routerLink="/getaquote.html"
                                          class="ar_contect-industries-header"><span
                                                  >Request a Free
                                                  Quote</span></a>
                          </div>
                  </div>
          </div>
  </div>
</section>

<!-- about us hero section end -->

<!-- our core values section start  -->

<section class="services-row-fresh core-value">
  <div class="container">
          <div class="row">
                  <div class="col-lg-12 HeadingsecAboutFresh">
                          <h2>Our Core Values</h2>
                          <p>Our main motive is to build successful relationships with our clients. These six rules are the foundation of the company’s success. Try our services to experience the better and undo the opportunities to growing your business in front of global users.
                        </p>
                  </div>
                  <div class="col-lg-12 AboutCoreValues">
                          <ul>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/authenticity.png"
                                                  alt="Website Development">
                                          <h3>Authenticity</h3>
                                          <p>We always believe in offering credibility to our clients. We manage your projects accurately and ensure trustworthiness in our work.
                                        </p>
                                  </li>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/Exceptional-Value.png"
                                                  alt="Web App Development">
                                          <h3>Offering Exceptional Benefits</h3>
                                          <p>Our passionate developers understand the customer choices and we keep them as a top priority. Our developers are always available to assist you.</p>
                                  </li>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/leadership.png"
                                                  alt="App Development">
                                          <h3>Leadership</h3>
                                          <p>We follow the latest technologies and industry trends to expedite your business venture globally.</p>
                                  </li>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/perfection.png" alt="Web Design">
                                          <h3>Dwell in Perfection
                                        </h3>
                                          <p>We believe in pursuing excellence and precision in everything we do. Our team of Yug Enterprises always ready to learn and improve. 
                                        </p>
                                  </li>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/respect.png"
                                                  alt="Magento Mobile App Development">
                                          <h3>Respect</h3>
                                          <p>Giving respect and taking respect is the key to success! We always respects our clients and their work. We make relationships healthy and deliver the projects on time.</p>
                                  </li>
                                  <li>
                                          <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/employee-satisfaction.png"
                                                  alt="NFT Development">
                                          <h3>Employee Satisfaction</h3>
                                          <p>Our family of Yug Enterprises is a great asset that anybody could have. We work as a family and enjoy every success and failure together. We all believe in enjoying everyday with at the office while working together. </p>
                                  </li>
                          </ul>
                  </div>
          </div>
  </div>
</section>

<!-- our core values section end  -->


<!-- our team section start  -->

<!-- <section class="services-row-fresh">
  <div class="container">
          <div class="row">
                  <div class="col-lg-12 HeadingsecAboutFresh">
                          <h2>Our Yug Enterprises’s Family</h2>
                          <p>We have a team of 50+ passionate and skilled professionals consisting of developers, UI/UX designers, project managers, business analysts, and digital marketers. 
                        </p>
                          <p>Yug Enterprises aims to create a young, healthy, fun yet professional, and hard-working office environment. We want our employees to be as relaxed and calm as possible to sustain an optimal work-life balance. We provide opportunities for job seekers to make significant contributions to the company and help in expanding their careers through training and guidance.
                        </p>
                  </div>
                  <div class="col-lg-12 AboutOurTeam">
                          <ul>
                                  <li>
                                    <iframe width="900" height="506" src="https://www.youtube.com/embed/TWCU-LYBJkY?autoplay=1&loop=1&playlist=TWCU-LYBJkY" title="Three Months Anniversary Video of Yug Enterprises at New Office" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </li>
                          </ul>
                  </div>
          </div>
  </div>
</section> -->

<!-- our core values section end  -->




<!-- Why Yug Enterprises section start-->

<section class="ourvalueBlock selected">
  <div class="container">
          <div class=""><h2 class="capitalText text-center">WHY YUG ENTERPRISES</h2></div>
          <p class="text-center main-section">Our core purpose is to serve customers well</p>
          <div class="row">
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock left">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/quality-development.png" class="purposeIcons real"
                                          alt="High-quality Development" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">01/06</div>
                                          <div class="purposeTitle">High-quality Development</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>No compromise in the quality of our work, 100% customer satisfaction by maintaining clients trust.</p>
                          </div>
                  </div>
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock right">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/expert-developers.png"
                                          class="purposeIcons accountable" alt="Expert Developers" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">02/06</div>
                                          <div class="purposeTitle">Expert Developers</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>We have innovative minds who are professionally skilled and dedicated to their work.</p>
                          </div>
                  </div>
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock left">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/confidencial-security.png"
                                          class="purposeIcons pas-Innov" alt="Confidentiality & Security" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">03/06</div>
                                          <div class="purposeTitle">Confidentiality & Security</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>Your personal information is safeguarded with us. We maintain your project privacy for a lifetime.</p>
                          </div>
                  </div>
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock right">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/impressive-portfolio.png"
                                          class="purposeIcons making-diff" alt="Impressive Portfolio" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">04/06</div>
                                          <div class="purposeTitle">Impressive Portfolio</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>We have 5+ years of experience and completed over 426+ successful projects.</p>
                          </div>
                  </div>
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock left last">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/customer-support.png"
                                          class="purposeIcons quest-learn" alt="Customer Support" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">05/06</div>
                                          <div class="purposeTitle">Customer Support</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>We bring you the high work values and aim to facilitate 24/7 customer support.</p>
                          </div>
                  </div>
                  <div class="col-sm-6 purpose">
                          <div class="purposeBlock right last">
                                  <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/affordable-services.png"
                                          class="purposeIcons collab-work" alt="Affordable Services" />
                                  <div class="titlesBlocks">
                                          <div class="stageType">06/06</div>
                                          <div class="purposeTitle">Affordable Services</div>
                                  </div>
                                  <div class="clearFix"></div>
                                  <p>We delivered more than 90% of projects on time under the estimated budget.
                                  </p>
                          </div>
                  </div>
                
          </div>
  </div>
</section>



<app-quoteform></app-quoteform>