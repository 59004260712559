
<div >
  
      <section id="content" class="servesdetails blog blog-Det">
        <div class="content-wrap" id="yuginfo_content">
          <div class="container clearfix">
  
                      <div class="row clearfix">
  
  
  
                          
  
                      </div>
  
                  </div>
  
          <div class="section nobottommargin" id="serviceslist">
  
            
            <div id="servicedetailss" class="container-fluid" style="background-color:var(--whitecolor);">
              <div class="container clear-bottommargin clearfix" id="servicedetails">
              
              
                <div *ngIf="BlogData && BlogData.metatags" class="col-md-12 col-xs-12 col-sm-12">
                  <div class="col-md-8 col-sm-7 col-xs-12 largecontentdiv listser">
                    <div class="blog-content blogcontent-ss">
  
  
                      <h3 class="left"><span>{{BlogData.title}}</span></h3>
                      <p class="type-date">{{BlogData.technology}} / {{formateDate(BlogData.date)}}</p>
                      <button class="social" style="background: #0077b5;" (click)="openSocial('linkedin')">
                        <i class="fa fa-linkedin"></i> LinkedIn
                      </button>
                      <button class="social" style="background: rgb(59, 89, 152);" (click)="openSocial('facebook')">
                        <i class="fa fa-facebook"></i> Facebook
                      </button>
                      
                      <button class="social" style="background: #00aaf2" (click)="openSocial('skype')">
                        <i class="fa fa-skype"></i> Skype
                      </button>

                      <button class="social" style="background: #1bd741" (click)="openSocial('whatsapp')">
                        <i class="fa fa-whatsapp"></i> Whatsapp
                      </button>
                      
                      <button class="social" style="background: rgb(29, 161, 242)" (click)="openSocial('twitter')">
                        <i class="fa fa-twitter"></i> Twitter
                      </button>

  
                      <h5 class="left"><img src="{{BlogData.metatags.imgUrl}}" alt="BlogData.metatags.imgAlt" title="BlogData.metatags.imgTitle"></h5>  
                        <p innerHtml={{BlogData.description}}> </p>

                      <h4 class="about_auth">About Author</h4>
                      <div class="col-md-4 col-sm-12 col-xs-12 about_authimg">
                        <img src="{{BlogData?.author?.image}}" alt="OWNER IMAGE">
                      </div>
                        <div class="col-md-8 col-sm-12 col-xs-12 about_authtext">
                        <h5>{{BlogData?.author?.name}}</h5>
                        <p>
                         {{BlogData?.author?.desc}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-5 col-xs-12">
                    <h3>Categories</h3>
                    <ul class="categores-list">
                      <li *ngFor="let category of BlogData.categories" class="cat-item cat-item-1">
                        <a routerLink="{{category.url}}">{{category.title}}</a>
                      </li>
                      </ul>
                      <h5 class="center"><a routerLink="/getaquote.html"><img src="https://info.yuginfo.in/BlogImagesyug/2021322121016-936development.jpg" alt="Development with yuginfo" title="Development with yuginfo"></a></h5>
                      <h5 class="center"><a routerLink="/getaquote.html"><img src="https://info.yuginfo.in/BlogImagesyug/20213221271-60benefits.jpg" alt="Benefits of yuginfo" title="Benefits of join yuginfo"></a></h5>
                  </div>
                </div>
              </div>
            </div>
            
                    
            
                  </div>
         
        </div>
        <app-quoteform></app-quoteform>

      </section><!-- #content end -->
      
    <a href="#" id="back-to-top" title="Back to top" ><i class="fa fa-arrow-up"></i></a>
    </div>