import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-augumented-reality',
  templateUrl: './augumented-reality.component.html',
  styleUrls: ['./augumented-reality.component.scss']
})
export class AugumentedRealityComponent implements OnInit {
  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }
 
  ngOnInit(): void {
    this.meta.setMetaFields('ARAppDev');
  }
  firstMAtch = ['16']
}
