<nav class="navbar fixed-top">
    <div class="container-fluid">
        <div class="navbar-desktop">
            <a class="navbar-brand white-logo-vid" href="./index.html"><img
                src="./assets/images/yug-logo-white.png" alt="Yug Logo"></a>
            <a class="navbar-brand dark-logo-vid" href="index.html">
                <img src="./assets/images/yug-logo-dark.png" alt="Yug">
            </a>
            <!--header-menu-->

            <div class="custom-navbar desktop-nav">
                <nav class="navbar-menu">
                    <div id="navbar-w" class="navbar-collapse navigation-part">

                        <ul class="menu-links">
                            <li class="submenu">
                                <a href="javascript:void(0)" class="transition main-menu-title" tabindex="-1">Company
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                </a>
                                <div class="dropdown-menu-max col-md-12" style="display:block;">
                                    <div class="sub-menu-section">
                                        <div class="container-fluid" style="padding:0 !important;">
                                            <div class="col-md-12">
                                                <div class="sub-menu-center-block rows" style="">
                                                    <div class="btn-block highlight-link">
                                                        
                                                        
                                                        <div>
                                                            <a routerLink="/hire-magento-developers.html" (click)="addClickEvent(false)" class="comman-btn btn "
                                                                tabindex="-1">Outsource with Us</a>
                                                        </div>
                                                    </div>
                                                    <div class="outer-sub-menu-sec menu-spacing">
                                                        <div class="sub-menu-sec">
                                                            <div class="menuHeadTitle">
                                                                
                                                                Why Yug Enterprises
                                                            </div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/about-us.html" tabindex="-1">About
                                                                        Us</a></li>
                                                                <!-- <li><a (click)="addClickEvent(false)" routerLink="/career"
                                                                        tabindex="-1">Career</a></li> -->
                                                               
                                                                <li><a (click)="addClickEvent(false)" routerLink="/contact-us.html"
                                                                        tabindex="-1">Contact Us</a></li>
                                                               
                                                                 
                                                            </ul>
                                                        </div>

                                                        <div class="block">
                                                            <div class="block1">
                                                                <h3 class="">Our Expertise</h3>
                                                                <p>Yug Enterprises has a proven record of
                                                                    developing websites, applications, and software.
                                                                    We are proud to be the top-most choice in the
                                                                    tech market for leading enterprises and have
                                                                    offered our services to many clients around
                                                                    every corner of the world.</p>
                                                            </div>
                                                            <div class="block2">
                                                                <h3 class="">Our Environment</h3>
                                                                <p>When a company can rely on its employees is the
                                                                    best honor they could get. An excellent work
                                                                    environment with good opportunities makes an
                                                                    employee work efficiently. Combining all these
                                                                    factors is an inspiration that can boost their
                                                                    career. We are thrilled and gratified for the
                                                                    people who work fervently with us to build a
                                                                    great workplace!</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>


                            <!-- <li class="submenu"><a href="javascript:void(0)" class="transition main-menu-title" tabindex="-1">Services
                                    <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                                <div class="dropdown-menu-max col-md-12" style="display: block;">

                                    <div class="sub-menu-section">
                                        <div class="container-fluid" style="padding:0 !important;">
                                            <div class="col-md-12">
                                                <div class="sub-menu-center-block cus-full-block rows">
                                                    <div class="btn-block highlight-link">
                                                        
                                                        
                                                        <div>
                                                            <a routerLink="/hire-magento-developers.html" (click)="addClickEvent(false)" class="comman-btn btn "
                                                                tabindex="-1">Outsource with Us</a>
                                                        </div>
                                                    </div>
                                                    <div class="outer-sub-menu-sec">
                                                        <div class="sub-menu-sec sub-menu-sec-mobile">
                                                            <div class="menuHeadTitle"><i class="fa fa-mobile"></i><a
                                                                    tabindex="-1">Mobile</a></div>
                                                            <ul class="mt16">
                                                                <li class="service-category" (click)="addClickEvent(false)" routerLink="/ios-app-development.html"><a
                                                                    >iOS</a>
                                                                </li>
                                                            
                                                            </ul>
                                                            <ul class="mt16">
                                                                <li class="service-category"><a (click)="addClickEvent(false)" routerLink="/android-app-development.html"
                                                                    >Android</a>
                                                                </li>
                                                                
                                                            </ul>
                                                            <ul class="mt16">
                                                                <li class="service-category submenu-name"><a
                                                                    >Hybrid</a>
                                                                </li>
                                                                <li class="ssub-menu super-sub-menu-div">
                                                                    <a (click)="addClickEvent(false)" routerLink="/hybrid-flutter-development.html"
                                                                        tabindex="-1">Flutter</a><span>|</span>
                                                                    <a (click)="addClickEvent(false)" routerLink="/hybrid-ionic-development.html"
                                                                        tabindex="-1">Ionic</a><span>|</span>
                                                                    <a (click)="addClickEvent(false)" routerLink="/hybrid-react-native.html"
                                                                        tabindex="-1">React Native</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec">
                                                            <div class="menuHeadTitle">
                                                                <i class="fa fa-desktop" aria-hidden="true"></i>
                                                                <a tabindex="-1">Web</a>
                                                            </div>
                                                            <ul class="mt16">
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/front-end-development.html" tabindex="-1">Front End Development</a>
                                                                </li>
                                                                
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/ecommerce-development.html" tabindex="-1">Ecommerce Website Development Services</a>
                                                                </li>
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/cms-development.html" tabindex="-1">CMS Web Development Company</a>
                                                                </li>
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/web-application-development.html" tabindex="-1">Web Application Development Services</a>
                                                                </li>
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/js-based-full-stack-development.html" tabindex="-1">Javascript Web Development Company</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec">
                                                            <div class="menuHeadTitle">
                                                                <i class="fa fa-cloud-upload"
                                                                    aria-hidden="true"></i>Emerging Technologies
                                                            </div>
                                                            <ul class="mt16">
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/nft-development.html"> NFT Development </a>
                                                                </li>
                                                               
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/augumented-reality.html"> Augmentation Reality (AR) Implementation  </a>
                                                                </li>
                                                                <li class="service-category">
                                                                    <a (click)="addClickEvent(false)" routerLink="/progressive-web-app-development.html" tabindex="-1">Progressive Web Development</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                            <li class="submenu"><a href="javascript:void(0)" class="transition main-menu-title" tabindex="-1">Hire Resource
                                    <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                                <div class="dropdown-menu-max col-md-12" style="display:block;">
                                    <div class="sub-menu-section">
                                        <div class="container-fluid" style="padding:0 !important;">
                                            <div class="col-md-12">
                                                <div class="sub-menu-center-block rows">
                                                    <div class="btn-block highlight-link">
                                                        
                                                        
                                                        <div>
                                                            <a routerLink="/hire-magento-developers.html" (click)="addClickEvent(false)" class="comman-btn btn "
                                                                tabindex="-1">Outsource with Us</a>
                                                        </div>
                                                    </div>
                                                    <div class="outer-sub-menu-sec technologies-sec">
                                                        <div class="sub-menu-sec">
                                                            <div class="menuHeadTitle"><i class="fa fa-desktop"
                                                                    aria-hidden="true"></i>Web</div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-java-developers.html"
                                                                        tabindex="-1">Java</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-dotnet-developers.html"
                                                                            tabindex="-1">.Net</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-php-developers.html"
                                                                        tabindex="-1">PHP</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-python-developers.html"
                                                                        tabindex="-1">Python</a></li>
                                                                
                                                                
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec">
                                                            <div class="menuHeadTitle"><i class="fa fa-mobile"></i>Mobile
                                                            </div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-android-developers.html"
                                                                    tabindex="-1">Android</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-ios-developers.html"
                                                                            tabindex="-1">iOS</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-flutter-developers.html"
                                                                        tabindex="-1">Flutter</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-react-native-developers.html"
                                                                            tabindex="-1">React Native</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-ionic-developers.html"
                                                                                tabindex="-1">Ionic</a></li>
                                                                
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec without-heading">
                                                            <div class="menuHeadTitle"><i class="fa fa-shopping-cart"
                                                                    aria-hidden="true"></i>E-Commerce</div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-shopify-developers.html"
                                                                    tabindex="-1">Shopify </a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-magento-developers.html"
                                                                    tabindex="-1">Magento</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-shopware-developers.html"
                                                                            tabindex="-1">Shopware</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-woocommerce-developers.html"
                                                                        tabindex="-1">WooCommerce</a></li>
                                                              
                                                                        
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec without-heading">
                                                            <div class="menuHeadTitle">
                                                                <i class="fa fa-laptop" aria-hidden="true"></i>CMS
                                                            </div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-wordpress-developers.html"
                                                                    tabindex="-1">WordPress </a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-drupal-developers.html"
                                                                            tabindex="-1">Drupal</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-wix-developers.html"
                                                                        tabindex="-1">Wix </a></li>
                                                              
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec without-heading">
                                                            <div class="menuHeadTitle"><i class="fa fa-jsfiddle"></i>JS
                                                                Framework</div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-vue-js-developers.html" tabindex="-1">Vue
                                                                        JS</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-angular-js-developers.html" tabindex="-1">Angular
                                                                        JS</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-node-js-developers.html" tabindex="-1">Node
                                                                        JS</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-react-js-developers.html"
                                                                        tabindex="-1">React JS</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="sub-menu-sec without-heading">
                                                            <div class="menuHeadTitle">
                                                                <img src="https://info.yuginfo.in/yuginfo/home-new/image/nft-icon.png"
                                                                    alt="NFT Icon">
                                                                NFT / <i class="fa fa-btc"></i>Blockchain
                                                            </div>
                                                            <ul>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-nft-ecommerce-developers.html" tabindex="-1">NFT
                                                                        E-Commerce</a></li>
                                                                <li><a (click)="addClickEvent(false)" routerLink="/hire-nft-marketplace-developers.html" tabindex="-1">NFT
                                                                        Marketplace</a></li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="submenu">
                                <a (click)="addClickEvent(false)" routerLink="/portfolio.html" class="transition main-menu-title" tabindex="-1">Our Portfolio</a>
                            </li> -->
                         
                            <!-- <li class="submenu">
                                <a (click)="addClickEvent(false)" routerLink="/career" class="transition main-menu-title" tabindex="-1">Career</a>
                            </li> -->
                            <li class="submenu">
                                <a (click)="addClickEvent(false)" routerLink="/contact-us.html" class="transition main-menu-title" tabindex="-1">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div class="inquiry-btn">
                        <a routerLink="/getaquote.html" class="transition" id="InquireNow" tabindex="-1">Request a
                            Quote</a>
                    </div>
                </nav>
            </div>
        </div>
        

        <!--header-menu-->


        <button class="navbar-toggler mobile-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar">
            <span><i class="fa fa-bars hamburger hamburger-white" aria-hidden="true" style="color:var(--whitecolor);"></i></span>
        </button>
        <div class="mobile-menu-section" tabindex="-1" id="" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <button type="button" class="btn-close btn-close-menu" ></button>
            </div>
            <div class="offcanvas-body">
                <div class="logo">
                    <img src="https://yuginfo.in/assets/images/yug-logo-dark.png"
                        alt="Yug Enterprises Logo">
                </div>
                <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                    <li class="nav-item dropdown" (click) = "changeRole('Company')">
                        <a class="nav-link active dropdown-toggle"  role="button"
                           ><span>Company</span></a>

                        <ul *ngIf = "type == 'Company'"  aria-labelledby="offcanvasNavbarDropdown">
                            <li><a (click)="closebox()" routerLink="/about-us.html" tabindex="-1">About
                                Us</a></li>
                                <!-- <li><a (click)="closebox()" class="anchor-mobile" routerLink="/career"
                                        tabindex="-1">Career</a></li> -->
                                <li><a (click)="closebox()" routerLink="/contact-us.html"
                                        tabindex="-1">Contact Us</a></li>
                        </ul>

                    </li>
                    <li class="nav-item dropdown" (click) = "changeRole('Mobile')">
                        <a class="nav-link active dropdown-toggle"  role="button"
                           ><span>Mobile</span></a>

                        <ul *ngIf = "type == 'Mobile'" aria-labelledby="offcanvasNavbarDropdown">
                            <li><a (click)="closebox()" routerLink="/ios-app-development.html"
                                >iOS</a></li>
                            <li><a (click)="closebox()" routerLink="/android-app-development.html"
                                >Android</a></li>
                            <li><a (click)="closebox()" routerLink="/hybrid-flutter-development.html"
                                tabindex="-1">Flutter</a></li>
                            <li> <a (click)="closebox()" routerLink="/hybrid-ionic-development.html"
                                tabindex="-1">Ionic</a></li>
                            <li><a (click)="closebox()" routerLink="/hybrid-react-native.html"
                                tabindex="-1">React Native</a></li>
                        </ul>

                    </li>
                    <li class="nav-item dropdown" (click) = "changeRole('Web')">
                        <a class="nav-link active dropdown-toggle"  role="button"
                           ><span>Web</span></a>

                        <ul *ngIf = "type == 'Web'" aria-labelledby="offcanvasNavbarDropdown">
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/front-end-development.html" tabindex="-1">Front End Development</a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/progressive-web-app-development.html" tabindex="-1">Progressive Web Development</a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/ecommerce-development.html" tabindex="-1">Ecommerce Website Development Services</a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/cms-development.html" tabindex="-1">CMS Web Development Company</a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/web-application-development.html" tabindex="-1">Web Application Development Services</a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/js-based-full-stack-development.html" tabindex="-1">Javascript Web Development Company</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" (click) = "changeRole('Emerging Technologies')">
                        <a class="nav-link active dropdown-toggle"  role="button"
                           ><span>Emerging Technologies</span></a>

                        <ul *ngIf = "type == 'Emerging Technologies'" aria-labelledby="offcanvasNavbarDropdown">
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/nft-development.html"> NFT Development </a>
                            </li>
                            <li class="service-category">
                                <a (click)="closebox()" routerLink="/augumented-reality.html"> Augmentation Reality (AR) Implementation  </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown" (click)="changeRole('Technologies')">
                        <a class="nav-link"><span>Hire Developer</span></a>
                        <ul *ngIf="type == 'Technologies'">
                            <li><a (click)="closebox()" routerLink="/hire-java-developers.html"
                                tabindex="-1">Java</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-dotnet-developers.html"
                                        tabindex="-1">.Net</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-php-developers.html"
                                    tabindex="-1">PHP</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-python-developers.html"
                                    tabindex="-1">Python</a></li>

                            <li><a (click)="closebox()" routerLink="/hire-android-developers.html"
                                tabindex="-1">Android</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-ios-developers.html"
                                        tabindex="-1">iOS</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-flutter-developers.html"
                                    tabindex="-1">Flutter</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-react-native-developers.html"
                                        tabindex="-1">React Native</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-ionic-developers.html"
                                            tabindex="-1">Ionic</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-shopify-developers.html"
                                tabindex="-1">Shopify </a></li>
                            <li><a (click)="closebox()" routerLink="/hire-magento-developers.html"
                                tabindex="-1">Magento</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-shopware-developers.html"
                                        tabindex="-1">Shopware</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-woocommerce-developers.html"
                                    tabindex="-1">WooCommerce</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-wordpress-developers.html"
                                tabindex="-1">WordPress </a></li>
                            <li><a (click)="closebox()" routerLink="/hire-drupal-developers.html"
                                        tabindex="-1">Drupal</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-wix-developers.html"
                                    tabindex="-1">Wix </a></li>
                            <li><a (click)="closebox()" routerLink="/hire-vue-js-developers.html" tabindex="-1">Vue
                                    JS</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-angular-js-developers.html" tabindex="-1">Angular
                                    JS</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-node-js-developers.html" tabindex="-1">Node
                                    JS</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-react-js-developers.html"
                                    tabindex="-1">React JS</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-nft-ecommerce-developers.html" tabindex="-1">NFT
                                    E-Commerce</a></li>
                            <li><a (click)="closebox()" routerLink="/hire-nft-marketplace-developers.html" tabindex="-1">NFT
                                    Marketplace</a></li>
                        </ul>
                    </li>
                    <!-- <li class="submenu">
                        <a (click)="closebox()" routerLink="/portfolio.html" class="transition" tabindex="-1">Our Portfolio</a>
                    </li> -->
                    <!-- <li class="submenu">
                        <a (click)="closebox()" routerLink="/career" class="transition" tabindex="-1">Career</a>
                    </li> -->
                    <li class="submenu">
                        <a (click)="closebox()" routerLink="/contact-us.html" class="transition" tabindex="-1">Contact Us</a>
                    </li>
                    <li class="submenu">
                        <a (click)="closebox()" routerLink="/getaquote.html" class="transition quote-button" tabindex="-1">Get a Quote</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>