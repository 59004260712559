import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  objForm: any;
  errorFlag: boolean = false;
  cat: any;
  constructor(
    private meta: MetafieldsService,
    private builder: FormBuilder,
    private apiservice: ApisService,
    private router: Router
  ) {
    this.objForm = this.builder.group({
      full_name: ['', Validators.required],
      phone_num: [''],
      email_add: ['', [Validators.required, Validators.email]],
      country: [''],
      message: ['', Validators.required],
      contactscategory: [''],
    });
  }
  saveUser() {
    console.log('2111111111111111111111111');
    this.objForm.patchValue({
      category: this.cat,
    });
    console.log('working', this.objForm.status);

    if (this.objForm.status == 'INVALID') {
      this.errorFlag = true;

      console.log('invalid');
    } else {
      let obj = {
        email_add: this.objForm.value.email_add,
        full_name: this.objForm.value.full_name,
        phone_num: this.objForm.value.phone_num,
        country: this.objForm.value.country,
        message: this.objForm.value.message,
        contactscategory: this.objForm.value.contactscategory,
      };


      this.apiservice.add(obj, environment.apis.users).then((res) => {
        this.objForm.reset();
        this.errorFlag = false;
      });
    }
  }

  ngOnInit(): void {
  }
}
