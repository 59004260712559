import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment.prod';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { Injector } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  pager:any=  {};
  count:any=0;
  Blogs:any=[];
  ulStyles:any= {};
  liStyles:any= {};
  aStyles:any= {};
  initialPage: any= 1;
  page:any = 1;
  pageSize:any = 10;
  maxPages: any = 10;
  labels:any = {
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next'
    };
    stylesdeveloper : any = [
      {
        id:"removethisdevelop",
        href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
        rel:"stylesheet",
        elementType:"link"
      }
    ]  
  styles:any ={};
  disableDefaultStyles:any = false;
  defaultStyles:any = {
    ul: {
        margin: 0,
        padding: 0,
        display: 'inline-block'
    },
    li: {
        listStyle: 'none',
        display: 'inline',
        textAlign: 'center'
    },
    a: {
        cursor: 'pointer',
        padding: '6px 12px',
        display: 'block',
        float: 'left'
    }
};

  constructor(
    private apisService : ApisService,
    @Inject(PLATFORM_ID) private platformId: any,
    private meta:MetafieldsService,
    private _injector: Injector,
    private router:Router,
    private activateRoute:ActivatedRoute
) {
  this.activateRoute.paramMap.subscribe((params: Params) => {
    if(params.params.id){
      this.callData(parseFloat(params.params.id));
    }else{
      this.callData();
    }
  });
  this.meta.setMetaFields('Blog');
    this.apisService.addAndRemoveClass('aboutus blog','portfolio profilepagesection blogcontentbody mainaboutus contactus services careers technology getaquote HomeMain')
    if (!this.disableDefaultStyles) {
      this.ulStyles = this.defaultStyles.ul;
      this.liStyles = this.defaultStyles.li;
      this.aStyles = this.defaultStyles.a;
  }
  // merge custom styles with default styles
  if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
  }
   
 
}

callData(page:any = 1){
  if(isPlatformBrowser(this.platformId)){
    if(document.getElementsByTagName('body')[0].clientWidth > 991){
      this.maxPages = 10;
    }else{
      this.maxPages = 3;
    }
    if(page > 1 ){
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      })
    }else{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
  if(this.apisService.storedBlogs[page]){
    this.Blogs = this.apisService.storedBlogs[page]
  }else{
    var  filter ={
      "where":{
        "status":true,
        "date":{$lte:new Date()}
      },
      "limit":10,
      "skip":((page -1)*10),
      "fields":["title","shortDescription","url","date","featured_image"],
      "order": {"date":-1}
    }
    this.apisService.getAllWithQuerywihName(environment.apis.blogs,filter).then((res:any)=>{
      if(res.length > 0){
        this.Blogs = res;
        this.apisService.storedBlogs[page] = res;
      }else{
        this.router.navigateByUrl('/notfound');
      }
    })
  }
  if(this.apisService.totalBlogs){
    this.count = this.apisService.totalBlogs;
    this.setPage(page,this.apisService.totalBlogs);
  }else{
    this.apisService.getCount(environment.apis.blogs).then((res:any)=>{
      this.count =res.count;
      this.apisService.totalBlogs = res.count;
      this.setPage(page,res.count);
    })
  }
}

setPage(page:any,count=this.count) {
  var {pageSize, maxPages } = this;
  this.page = page;
  const pager = this.paginate(count, page, pageSize, maxPages);
  this.pager = pager;
}

  ngOnInit(): void {
    this.stylesdeveloper.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }

  paginate(
    totalItems:any,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage:any, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}
ngOnDestroy(): void {
  this.stylesdeveloper.map((element:any)=>{
    this.meta.removeLink("#removethisdevelop");
  });
}

}
