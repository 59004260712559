<div class="col-lg-12 listof-hiring">
    <ul>
        <li>
            <h3>Best Talent</h3>
            <span>01</span>
            <p>We have employed the best developers who have passed the special skill tests.</p>
        </li>
        <li>
            <h3>Passion</h3>
            <span>02</span>
            <p>We hire those developers who are tech enthusiasts and love their jobs. With their
                dedicated passion.</p>
        </li>
        <li>
            <h3>Expertise</h3>
            <span>03</span>
            <p>Our developers are masters in their domains, continually invest in the learning process
                and keep their skills up-to-date to compete in the market.</p>
        </li>
        <li>
            <h3>Experience</h3>
            <span>04</span>
            <p>Working with skilled and experienced Android developers who value the client's investment
                and vision towards their project.</p>
        </li>
        <li>
            <h3>Right-Size</h3>
            <span>05</span>
            <p>At we, we provide a flexible and scalable working model which is followed in an
                efficient plus transparent way.</p>
        </li>
        <li>
            <h3>Speedy Set-up</h3>
            <span>06</span>
            <p>We can quickly onboard projects of all scales. Our team familiarised themselves with the
                project quickly.</p>
        </li>
        <li>
            <h3>Cost Savings</h3>
            <span>07</span>
            <p>With we, you will get the best Android developers team plus save costs on IT,
                infrastructure.</p>
        </li>
        <li>
            <h3>100% Transparency</h3>
            <span>08</span>
            <p>We are agile practitioners and follow a sprint-based development process with the daily
                scrum meetings.
            </p>
        </li>
        <li>
            <h3>Easy Communication</h3>
            <span>09</span>
            <p>Along with other things, we have a smooth communication process followed through Skype,
                Email, and Phone.</p>
        </li>
    </ul>
</div>
