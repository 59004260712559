<div class="row" >
    <div class="col-lg-12 headingsec">
    <span *ngFor="let datai of firstMAtchData">
        <h2 *ngIf="datai === '1'">{{headingVariable1[0]}}</h2>
        <p *ngIf="datai === '1'">{{subheadingVariable1[0]}}</p>
        <h2 *ngIf="datai === '2'">{{headingVariable1[1]}}</h2>
        <p *ngIf="datai === '2'">{{subheadingVariable1[1]}}</p>
        <h2 *ngIf="datai === '3'">{{headingVariable1[2]}}</h2>
        <p *ngIf="datai === '3'">{{subheadingVariable1[2]}}</p>
        <h2 *ngIf="datai === '4'">{{headingVariable1[3]}}</h2>
        <p *ngIf="datai === '4'">{{subheadingVariable1[3]}}</p>
        <h2 *ngIf="datai ==='5'">{{headingVariable1[4]}}</h2>
        <p *ngIf="datai === '5'">{{subheadingVariable1[4]}}</p>
        <h2 *ngIf="datai === '6'">{{headingVariable1[5]}}</h2>
        <p *ngIf="datai === '6'">{{subheadingVariable1[5]}}</p>
        <h2 *ngIf="datai === '7'">{{headingVariable1[6]}}</h2>
        <p *ngIf="datai==='7'">{{subheadingVariable1[6]}}</p>
        <h2 *ngIf="datai==='8'">{{headingVariable1[7]}}</h2>
        <p *ngIf="datai==='8'"> {{subheadingVariable1[7]}}</p>
        <h2 *ngIf="datai==='9'">{{headingVariable1[8]}}</h2>
        <p *ngIf="datai==='9'">{{subheadingVariable1[8]}}</p>
        <h2 *ngIf="datai==='10'">{{headingVariable1[9]}}</h2>
        <p *ngIf="datai==='10'">{{subheadingVariable1[9]}}</p>
        <h2 *ngIf="datai === '11'">{{headingVariable1[10]}}</h2>
        <p *ngIf="datai === '11'">{{subheadingVariable1[10]}}</p>
        <h2 *ngIf="datai === '12'">{{headingVariable1[11]}}</h2>
        <p *ngIf="datai === '12'">{{subheadingVariable1[11]}}</p>
        <h2 *ngIf="datai === '13'">{{headingVariable1[12]}}</h2>
        <p *ngIf="datai === '13'">{{subheadingVariable1[12]}}</p>
        <h2 *ngIf="datai === '14'">{{headingVariable1[13]}}</h2>
        <p *ngIf="datai === '14'">{{subheadingVariable1[13]}}</p>
        <h2 *ngIf="datai ==='15'">{{headingVariable1[14]}}</h2>
        <p *ngIf="datai === '15'">{{subheadingVariable1[14]}}</p>
        <h2 *ngIf="datai === '16'">{{headingVariable1[15]}}</h2>
        <p *ngIf="datai === '16'">{{subheadingVariable1[15]}}</p>
        <h2 *ngIf="datai === '17'">{{headingVariable1[16]}}</h2>
        <p *ngIf="datai==='17'">{{subheadingVariable1[16]}}</p>
    </span>
    </div>
    <div class="col-lg-12 offers-services-isv ionic-app" >
        <ul *ngFor="let datas of firstMAtchData">
            <li *ngIf="datas==='1' || datas==='13' || datas==='9' || datas==='5' || datas==='17'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/native-performance.png" alt="Native Performance">
                <h3>Native Performance</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable,100)}} </span> 
                 
                    <a class="moreChips"  
                      [matTooltip]="newVariable"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="above">View All</a>
                      
                  
                </p>
                
                <div id="post-popover1" class="multiple-chips-popover hide">
                    <p>In the widgets of the new mobile UI framework, here we incorporate all imperative differences of the platform such as scrolling, navigation, fonts, icons, and more for a fully native performance on iOS and Android both. Our mobile app developers offer high-quality experiences across devices and platforms for the development process </p> 
                    
                </div>
            </li>
            <li *ngIf="datas==='2' || datas==='14' || datas==='10' || datas==='6' || datas==='17'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/authenticity.png" alt="Security & Confidentiality">
                <h3>Security and Confidentiality</h3>
                <p data-maxlength="120" class="content-para">
                    <span>{{trimString(newVariable1,100)}}</span>
                    <a class="moreChips"  
                      [matTooltip]="newVariable1"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover2" class="multiple-chips-popover hide">
                    <p>We always believe in keeping our clients’ data confidential. We use accurate data confidentiality measures to keep the workflow appropriate and glitch-free.</p>
                    
                </div>
            </li>
            <li *ngIf="datas==='3' || datas==='15' || datas==='11' || datas==='7' || datas==='17'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/scalablity-and-efficient.png" alt="Scalablity and Efficient">
                <h3>Scalability and Efficiently Friendly </h3>
                <p data-maxlength="120" class="content-para">
                    <span>{{trimString(newVariable2,100)}} </span>
                    <a class="moreChips"  
                    [matTooltip]="newVariable2"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover3" class="multiple-chips-popover hide">
                    <p>Our quick-witted team of developers performs their tasks efficiently and delivers scalable and robust apps to the clients. Our main motive is to offer on-time delivery with flexible features. You can rely on our developers as they will update you regarding the ongoing process of the project. </p>
                    
                </div>
            </li>
            <li *ngIf="datas==='4' || datas==='16' || datas==='12' || datas==='8'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/single-code-base.png" alt="Single Code Base">
                <h3>Single Codebase</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable3,100)}} </span> 
                    <a class="moreChips"  
                    [matTooltip]="newVariable3"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover4" class="multiple-chips-popover hide">
                    <p>With a single codebase, our team of expert developers performs code that is useful for both iOS and Android applications. The team of developers performs processes with code advantages to make your app and website attractive.</p>
                    
                </div>
            </li>
            <li *ngIf="datas==='5' || datas==='1' || datas==='13' || datas==='9'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/rapid-development.png" alt="Rapid Development">
                <h3>Rapid Development </h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable4,100)}} </span> 
                    <a class="moreChips"  
                    [matTooltip]="newVariable4"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover5" class="multiple-chips-popover hide">
                    <p>Our development team works adroitly to get maximum leverages of the framework. Developers make the code scalable so that even after the launch and deployment we can scale your product in terms of performance. We follow imperative guidelines for coding the respective mobile platforms. We have delivered various applications in a short time with rich features and high quality.  </p>
                    
                </div>
            </li>
            <li *ngIf="datas==='6' || datas==='2' || datas==='14' || datas==='10'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/easy-integration.png" alt="Easy Integration">
                <h3>Easy Integration</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable5,100)}} </span> 
                    <a class="moreChips"  
                    [matTooltip]="newVariable5"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover6" class="multiple-chips-popover hide">
                    <p>There are different processes and techniques that are used by our dedicated team of developers. We believe in managing the procedure in a seamless way to make sure that there are less or no issues at the time of deployment of the product. The widgets that are present in the frameworks and other things, we integrate effortlessly to make the product engaging.</p>
                
                </div>
            </li>
            <li *ngIf="datas==='7' || datas==='3' || datas==='15' || datas==='11'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/expressive-ui.png" alt="Flexible & Expressive UI">
                <h3>Flexible & Expressive UI </h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable6,100)}} </span> 
                    <a class="moreChips"  
                      [matTooltip]="newVariable6"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover7" class="multiple-chips-popover hide">
                    <p>Our developers focus on native end-user experiences and provide a plethora of designs that are interactive and responsive along with rich widget sets and ample animation libraries.  </p>
                    
                </div>
            </li>
            <li *ngIf="datas==='8' || datas==='4' || datas==='16' || datas==='12'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/agile-methodology.png" alt="Agile Methodology">
                <h3>Agile Methodology</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable7,100)}} </span> 
                    <a class="moreChips"  
                    [matTooltip]="newVariable7"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover8" class="multiple-chips-popover hide">
                    <p>We engage in agile methodologies, starting with a meticulous understanding of the clientele requirements. The approach and development methods we use are continuously evolving throughout the collaboration of cross-functional teams.</p>
                
                </div>
            </li>
            <li *ngIf="datas==='9' || datas==='5' || datas==='1' || datas==='13'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/transparency.png" alt="Transparency">
                <h3>Transparency</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable8,100)}} </span> 
                    <a class="moreChips"  
                    [matTooltip]="newVariable8"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover9" class="multiple-chips-popover hide">
                    <p>We consider transparency as the key to any business. The client can access all procedures and codes relevant to their app whenever they want to. Everything is visible to them. We keep updating the clients about the progress of the project at every stage of the development process in our Ionic app development services. </p>
                
                </div>
            </li>
            <li *ngIf="datas==='10' || datas==='6' || datas==='2' || datas==='14'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/cost-effective.png" alt="Cost Effective">
                <h3>Cost-Effective Solutions</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable9,100)}} </span> 
                    <a class="moreChips"  
                      [matTooltip]="newVariable9"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover10" class="multiple-chips-popover hide">
                    <p>We are a prominent Ionic app development company and our team analyzes your business requirements and offers affordable Ionic app development solutions. We help you in proposing the technology stack that will meet the specifications of the clients. </p> 
                </div>
            </li>
            <li *ngIf="datas==='11' || datas==='7' || datas==='3' || datas==='15'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/flexibility.png" alt="Flexibility">
                <h3>Flexibility</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable10,100)}} </span> 
                    <a class="moreChips"  
                      [matTooltip]="newVariable10"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover11" class="multiple-chips-popover hide">
                    <p>We are a leading Ionic app development service provider and we offer flexibility in many ways. We are flexible in terms of working hours, time-zone specific, coding, the development approach to be taken into consideration, and 24/7 technical support. </p>
                </div>
            </li>
            <li *ngIf="datas==='12' || datas==='8' || datas==='4' || datas==='16'">
                <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/making-difference.png" alt="Social Media Application Development">
                <h3>Hire Developers of your Choice</h3>
                <p data-maxlength="120" class="content-para">
                    <span >{{trimString(newVariable11,100)}} </span> 
                    <a class="moreChips"  
                      [matTooltip]="newVariable11"  matTooltipClass="example-tooltip-theme"  matTooltipPosition="below">View All</a>
                </p>
                <div id="post-popover12" class="multiple-chips-popover hide">
                    <p>Hire a pool of talented developers on the basis of your project requirements. We offer comprehensive support to hire adept developers and the right team. You can select the developers to build a competent team.</p> 
                </div>
            </li>
        </ul>
    </div>
</div>
        