import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-get-aquote',
  templateUrl: './get-aquote.component.html',
  styleUrls: ['./get-aquote.component.scss'],
})
export class GetAquoteComponent implements OnInit {
  searchForm: any;
  errorFlag: boolean = false;
  selectedCountries : any = [];
  styles : any = [
    {
      id:"removethisdevelop",
      href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
      rel:"stylesheet",
      elementType:"link"
    }
  ]
  countries:any = [
      
  {name:"Afghanistan",
  flag : "flagstrap-af"},
  {name:"Albania",
  flag : "flagstrap-al"},
  {name:"Algeria",
  flag : "flagstrap-dz"},
  {name:"American Samoa",
  flag : "flagstrap-as"},
  {name:"Andorra",
  flag : "flagstrap-ad"},
  {name:"Angola",
  flag : "flagstrap-ao"},
  {name:"Anguilla",
  flag : "flagstrap-ai"},
  {name:"Antigua and Barbuda",
  flag : "flagstrap-ag"},
  {name:"Argentina",
  flag : "flagstrap-ar"},
  {name:"Armenia",
  flag : "flagstrap-am"},
  {name:"Aruba",
  flag : "flagstrap-aw"},
  {name:"Australia",
  flag : "flagstrap-au"},
  {name:"Austria",
  flag : "flagstrap-at"},
  {name:"Azerbaijan",
  flag : "flagstrap-az"},
  {name:"Bahamas",
  flag : "flagstrap-bs"},
  {name:"Bahrain",
  flag : "flagstrap-bh"},
  {name:"Bangladesh",
  flag : "flagstrap-bd"},
  {name:"Barbados",
  flag : "flagstrap-bb"},
  {name:"Belarus",
  flag : "flagstrap-by"},
  {name:"Belgium",
  flag : "flagstrap-be"},
  {name:"Belize",
  flag : "flagstrap-bz"},
  {name:"Benin",
  flag : "flagstrap-bj"},
  {name:"Bermuda",
  flag : "flagstrap-bm"},
  {name:"Bhutan",
  flag : "flagstrap-bt"},
  {name:"Bolivia, Plurinational State of",
  flag : "flagstrap-bo"},
  {name:"Bosnia and Herzegovina",
  flag : "flagstrap-ba"},
  {name:"Botswana",
  flag : "flagstrap-bw"},
  {name:"Bouvet Island",
  flag : "flagstrap-bv"},
  {name:"Brazil",
  flag : "flagstrap-br"},
  {name:"British Indian Ocean Territory",
  flag : "flagstrap-io"},
  {name:"Brunei Darussalam",
  flag : "flagstrap-bn"},
  {name:"Bulgaria",
  flag : "flagstrap-bg"},
  {name:"Burkina Faso",
  flag : "flagstrap-bf"},
  {name:"Burundi",
  flag : "flagstrap-bi"},
  {name:"Cambodia",
  flag : "flagstrap-kh"},
{name:"Cameroon",
  flag : "flagstrap-cm"},
{name:"Canada",
  flag : "flagstrap-ca"},
{name:"Cape Verde",
  flag : "flagstrap-cv"},
{name:"Cayman Islands",
  flag : "flagstrap-ky"},
{name:"Central African Republic",
  flag : "flagstrap-cf"},
{name:"Chad",
  flag : "flagstrap-td"},
{name:"Chile",
  flag : "flagstrap-cl"},
{name:"China",
  flag : "flagstrap-cn"},
  {name:"Colombia",
  flag : "flagstrap-co"},
  {name:"Comoros",
  flag : "flagstrap-km"},
  {name:"Congo",
  flag : "flagstrap-cg"},
  {name:"Congo, the Democratic Republic of the",
  flag : "flagstrap-cd"},
  {name:"Cook Islands",
  flag : "flagstrap-ck"},
  {name:"Costa Rica",
  flag : "flagstrap-cr"},
  {name:"C" +"&ocirc;" +"te d'Ivoire",
  flag : "flagstrap-ci"},
  {name:"Croatia",
  flag : "flagstrap-hr"},
  {name:"Cuba",
  flag : "flagstrap-cu"},
  {name:"Cura" +"&ccedil;" +"ao",
  flag : "flagstrap-cw"},
  {name:"Cyprus",
  flag : "flagstrap-cy"},
 {name:"Czech Republic",
  flag : "flagstrap-cz"},
  {name:"Denmark",
  flag : "flagstrap-dk"},
  {name:"Djibouti",
  flag : "flagstrap-dj"},
  {name:"Dominica",
  flag : "flagstrap-dm"},
  {name:"Dominican Republic",
  flag : "flagstrap-do"},
  {name:"Ecuador",
  flag : "flagstrap-ec"},
  {name:"Egypt",
  flag : "flagstrap-eg"},
  {name:"El Salvador",
  flag : "flagstrap-sv"},
  {name:"Equatorial Guinea",
  flag : "flagstrap-gq"},
  {name:"Eritrea",
  flag : "flagstrap-er"},
  {name:"Estonia",
  flag : "flagstrap-ee"},
  {name:"Ethiopia",
  flag : "flagstrap-et"},
  {name:"Falkland Islands (Malvinas)",
  flag : "flagstrap-fk"},
  {name:"Faroe Islands",
  flag : "flagstrap-fo"},
  {name:"Fiji",
  flag : "flagstrap-fj"},
  {name:"Finland",
  flag : "flagstrap-fi"},
  {name:"France",
  flag : "flagstrap-fr"},
  {name:"French Guiana",
  flag : "flagstrap-gf"},
  {name:"French Polynesia",
  flag : "flagstrap-pf"},
  {name:"French Southern Territories",
  flag : "flagstrap-tf"},
  {name:"Gabon",
  flag : "flagstrap-ga"},
  {name:"Gambia",
  flag : "flagstrap-gm"},
  {name:"Georgia",
  flag : "flagstrap-ge"},
  {name:"Germany",
  flag : "flagstrap-de"},
  {name:"Ghana",
  flag : "flagstrap-gh"},
  {name:"Gibraltar",
  flag : "flagstrap-gi"},
  {name:"Greece",
  flag : "flagstrap-gr"},
  {name:"Greenland",
  flag : "flagstrap-gl"},
  {name:"Grenada",
  flag : "flagstrap-gd"},
  {name:"Guadeloupe",
  flag : "flagstrap-gp"},
  {name:"Guam",
  flag : "flagstrap-gu"},
  {name:"Guatemala",
  flag : "flagstrap-gt"},
  {name:"Guernsey",
  flag : "flagstrap-gg"},
  {name:"Guinea",
  flag : "flagstrap-gn"},
  {name:"Guinea-Bissau",
  flag : "flagstrap-gw"},
  {name:"Guyana",
  flag : "flagstrap-gy"},
  {name:"Haiti",
  flag : "flagstrap-ht"},
  {name:"Heard Island and McDonald Islands",
  flag : "flagstrap-hm"},
  {name:"Holy See (Vatican City State)",
  flag : "flagstrap-va"},
  {name:"Honduras",
  flag : "flagstrap-hn"},
  {name:"Hong Kong",
  flag : "flagstrap-hk"},
  {name:"Hungary",
  flag : "flagstrap-hu"},
  {name:"Iceland",
  flag : "flagstrap-is"},
  {name:"India",
  flag : "flagstrap-in"},
  {name:"Indonesia",
  flag : "flagstrap-id"},
  {name:"Iran, Islamic Republic of",
  flag : "flagstrap-ir"},
  {name:"Iraq",
  flag : "flagstrap-iq"},
  {name:"Ireland",
  flag : "flagstrap-ie"},
  {name:"Isle of Man",
  flag : "flagstrap-im"},
  {name:"Israel",
  flag : "flagstrap-il"},
  {name:"Italy",
  flag : "flagstrap-it"},
  {name:"Jamaica",
  flag : "flagstrap-jm"},
  {name:"Japan",
  flag : "flagstrap-jp"},
  {name:"Jersey",
  flag : "flagstrap-je"},
  {name:"Jordan",
  flag : "flagstrap-jo"},
  {name:"Kazakhstan",
  flag : "flagstrap-kz"},
  {name:"Kenya",
  flag : "flagstrap-ke"},
  {name:"Kiribati",
  flag : "flagstrap-ki"},
  {name:"Korea, Democratic People's Republic of",
  flag : "flagstrap-kp"},
  {name:"Korea, Republic of",
  flag : "flagstrap-kr"},
  {name:"Kuwait",
  flag : "flagstrap-kw"},
  {name:"Kyrgyzstan",
  flag : "flagstrap-kg"},
  {name:"Lao People's Democratic Republic",
  flag : "flagstrap-la"},
  {name:"Latvia",
  flag : "flagstrap-lv"},
  {name:"Lebanon",
  flag : "flagstrap-lb"},
  {name:"Lesotho",
  flag : "flagstrap-ls"},
  {name:"Liberia",
  flag : "flagstrap-lr"},
  {name:"Libya",
  flag : "flagstrap-ly"},
  {name:"Liechtenstein",
  flag : "flagstrap-li"},
  {name:"Lithuania",
  flag : "flagstrap-lt"},
  {name:"Luxembourg",
  flag : "flagstrap-lu"},
  {name:"Macao",
  flag : "flagstrap-mo"},
  {name:"Macedonia the former Yugoslav Republic of",
  flag : "flagstrap-mk"},
  {name:"Madagascar",
  flag : "flagstrap-mg"},
  {name:"Malawi",
  flag : "flagstrap-mw"},
  {name:"Malaysia",
  flag : "flagstrap-my"},
  {name:"Maldives",
  flag : "flagstrap-mv"},
  {name:"Mali",
  flag : "flagstrap-ml"},
  {name:"Malta",
  flag : "flagstrap-mt"},
  {name:"Marshall Islands",
  flag : "flagstrap-mh"},
  {name:"Martinique",
  flag : "flagstrap-mq"},
  {name:"Mauritania",
  flag : "flagstrap-mr"},
  {name:"Mauritius",
  flag : "flagstrap-mu"},
  {name:"Mayotte",
  flag : "flagstrap-yt"},
  {name:"Mexico",
  flag : "flagstrap-mx"},
  {name:"Micronesia, Federated States of",
  flag : "flagstrap-fm"},
  {name:"Moldova, Republic of",
  flag : "flagstrap-md"},
  {name:"Monaco",
  flag : "flagstrap-mc"},
  {name:"Mongolia",
  flag : "flagstrap-mn"},
  {name:"Montenegro",
  flag : "flagstrap-me"},
  {name:"Montserrat",
  flag : "flagstrap-ms"},
  {name:"Morocco",
  flag : "flagstrap-ma"},
  {name:"Mozambique",
  flag : "flagstrap-mz"},
  {name:"Myanmar",
  flag : "flagstrap-mm"},
  {name:"Namibia",
  flag : "flagstrap-na"},
  {name:"Nauru",
  flag : "flagstrap-nr"},
  {name:"Nepal",
  flag : "flagstrap-np"},
  {name:"Netherlands",
  flag : "flagstrap-nl"},
  {name:"New Caledonia",
  flag : "flagstrap-nc"},
  {name:"New Zealand",
  flag : "flagstrap-nz"},
  {name:"Nicaragua",
  flag : "flagstrap-ni"},
  {name:"Niger",
  flag : "flagstrap-ne"},
  {name:"Nigeria",
  flag : "flagstrap-ng"},
  {name:"Niue",
  flag : "flagstrap-nu"},
  {name:"Norfolk Island",
  flag : "flagstrap-nf"},
  {name:"Northern Mariana Islands",
  flag : "flagstrap-mp"},
  {name:"Norway",
  flag : "flagstrap-no"},
  {name:"Oman",
  flag : "flagstrap-om"},
  {name:"Pakistan",
  flag : "flagstrap-pk"},
  {name:"Palau",
  flag : "flagstrap-pw"},
  {name:"Palestinian Territory, Occupied",
  flag : "flagstrap-ps"},
  {name:"Panama",
  flag : "flagstrap-pa"},
  {name:"Papua New Guinea",
  flag : "flagstrap-pg"},
  {name:"Paraguay",
  flag : "flagstrap-py"},
  {name:"Peru",
  flag : "flagstrap-pe"},
  {name:"Philippines",
  flag : "flagstrap-ph"},
  {name:"Pitcairn",
  flag : "flagstrap-pn"},
  {name:"Poland",
  flag : "flagstrap-pl"},
  {name:"Portugal",
  flag : "flagstrap-pt"},
  {name:"Puerto Rico",
  flag : "flagstrap-pr"},
  {name:"Qatar",
  flag : "flagstrap-qa"},
  {name:"R" +"&eacute;" +"union",
  flag : "flagstrap-re"},
  {name:"Romania",
  flag : "flagstrap-ro"},
  {name:"Russian Federation",
  flag : "flagstrap-ru"},
  {name:"Rwanda",
  flag : "flagstrap-rw"},
  {name:"Saint Helena, Ascension and Tristan da Cunha",
  flag : "flagstrap-sh"},
  {name:"Saint Kitts and Nevis",
  flag : "flagstrap-kn"},
  {name:"Saint Lucia",
  flag : "flagstrap-lc"},
  {name:"Saint Martin (French part)",
  flag : "flagstrap-mf"},
  {name:"Saint Pierre and Miquelon",
  flag : "flagstrap-pm"},
  {name:"Saint Vincent and the Grenadines",
  flag : "flagstrap-vc"},
  {name:"Samoa",
  flag : "flagstrap-ws"},
  {name:"San Marino",
  flag : "flagstrap-sm"},
  {name:"Sao Tome and Principe",
  flag : "flagstrap-st"},
  {name:"Saudi Arabia",
  flag : "flagstrap-sa"},
  {name:"Senegal",
  flag : "flagstrap-sn"},
  {name:"Serbia",
  flag : "flagstrap-rs"},
  {name:"Seychelles",
  flag : "flagstrap-sc"},
  {name:"Sierra Leone",
  flag : "flagstrap-sl"},
  {name:"Singapore",
  flag : "flagstrap-sg"},
  {name:"Sint Maarten (Dutch part)",
  flag : "flagstrap-sx"},
  {name:"Slovakia",
  flag : "flagstrap-sk"},
  {name:"Slovenia",
  flag : "flagstrap-si"},
  {name:"Solomon Islands",
  flag : "flagstrap-sb"},
  {name:"Somalia",
  flag : "flagstrap-so"},
  {name:"South Africa",
  flag : "flagstrap-za"},
  {name:"South Georgia and the South Sandwich Islands",
  flag : "flagstrap-gs"},
  {name:"South Sudan",
  flag : "flagstrap-ss"},
  {name:"Spain",
  flag : "flagstrap-es"},
  {name:"Sri Lanka",
  flag : "flagstrap-lk"},
  {name:"Sudan",
  flag : "flagstrap-sd"},
  {name:"Suriname",
  flag : "flagstrap-sr"},
  {name:"Swaziland",
  flag : "flagstrap-sz"},
  {name:"Sweden",
  flag : "flagstrap-se"},
  {name:"Switzerland",
  flag : "flagstrap-ch"},
  {name:"Syrian Arab Republic",
  flag : "flagstrap-sy"},
  {name:"Taiwan, Province of China",
  flag : "flagstrap-tw"},
  {name:"Tajikistan",
  flag : "flagstrap-tj"},
  {name:"Tanzania, United Republic of",
  flag : "flagstrap-tz"},
  {name:"Thailand",
  flag : "flagstrap-th"},
  {name:"Timor-Leste",
  flag : "flagstrap-tl"},
  {name:"Togo",
  flag : "flagstrap-tg"},
  {name:"Tokelau",
  flag : "flagstrap-tk"},
  {name:"Tonga",
  flag : "flagstrap-to"},
  {name:"Trinidad and Tobago",
  flag : "flagstrap-tt"},
  {name:"Tunisia",
  flag : "flagstrap-tn"},
  {name:"Turkey",
  flag : "flagstrap-tr"},
  {name:"Turkmenistan",
  flag : "flagstrap-tm"},
  {name:"Turks and Caicos Islands",
  flag : "flagstrap-tc"},
  {name:"Tuvalu",
  flag : "flagstrap-tv"},
  {name:"Uganda",
  flag : "flagstrap-ug"},
  {name:"Ukraine",
  flag : "flagstrap-ua"},
  {name:"United Arab Emirates",
  flag : "flagstrap-ae"},
  {name:"United Kingdom",
  flag : "flagstrap-gb"},
  {name:"United States",
  flag : "flagstrap-us"},
  {name:"United States Minor Outlying Islands",
  flag : "flagstrap-um"},
  {name:"Uruguay",
  flag : "flagstrap-uy"},
  {name:"Uzbekistan",
  flag : "flagstrap-uz"},
  {name:"Vanuatu",
  flag : "flagstrap-vu"},
  {name:"Venezuela, Bolivarian Republic of",
  flag : "flagstrap-ve"},
  {name:"Viet Nam",
  flag : "flagstrap-vn"},
  {name:"Virgin Islands, British",
  flag : "flagstrap-vg"},
  {name:"Virgin Islands, U.S.",
  flag : "flagstrap-vi"},
  {name:"Wallis and Futuna",
  flag : "flagstrap-wf"},
  {name:"Western Sahara",
  flag : "flagstrap-eh"},
  {name:"Yemen",
  flag : "flagstrap-ye"},
  {name:"Zambia",
  flag : "flagstrap-zm"},
  {name:"Zimbabwe",
  flag : "flagstrap-zw"}


  ]
  constructor(
    private builder: FormBuilder,
    private apiservice: ApisService,
    private meta: MetafieldsService,
    private router: Router
  ) {
    this.apiservice.addAndRemoveClass(
      'getaquote',
      'profilepagesection aboutus blogcontentbody blog mainaboutus contactus services technology HomeMain careers'
    );
    this.searchForm = this.builder.group({
      name: ['', Validators.required],
      phone_num: ['', Validators.required],
      email_add: ['', [Validators.required, Validators.email]],
      skype_id: ['', Validators.required],
      project: [''],
      country: [''],
      countryCode: [''],
      MobileAppDevelopment: [true],
      ApplicationService: [false],
      HireDedicatedResources: [false],
      ProgressiveWebApp: [false],
      CloudSolutions: [false],
      range: [''],
      duration: [''],
    });

    this.selectedCountries = this.countries;
  }

  getAquote() {
    let element: any = document.getElementById('select_modelcountry');



    if (this.searchForm.status == 'INVALID') {
      this.errorFlag = true;
    } else {
      let obj: any = {
        email_add: this.searchForm.value.email_add,
        skype_id: this.searchForm.value.skype_id,
        full_name: this.searchForm.value.name,
        phone_num: this.searchForm.value.phone_num,
        about_project: this.searchForm.value.project,
        budget: this.searchForm.value.range,
        budgetstart: this.searchForm.value.duration,
        requirement: [],
        pathname: this.router.url,
        country: this.searchForm.value.country,
        countryCode: this.searchForm.value.countryCode,
        form_type: 'submitForm',
      };
      
      if (this.searchForm.value.MobileAppDevelopment) {
        obj.requirement.push('Mobile App Development');
      }
      if (this.searchForm.value.ApplicationService) {
        obj.requirement.push('Application Service');
      }
      if (this.searchForm.value.HireDedicatedResources) {
        obj.requirement.push('Cloud Solution');
      }
      if (this.searchForm.value.ProgessiveWebApp) {
        obj.requirement.push('Progressive Web App');
      }
      if (this.searchForm.value.CloudSolutions) {
        obj.requirement.push('Hire Dedicated Resources');
      }
      this.apiservice.add(obj, environment.apis.users).then((res) => {
        this.searchForm.reset();
        this.errorFlag = false;
      });
    }
  }
  ngOnInit(): void {
    this.meta.setMetaFields('ContactUs');
    this.styles.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }

  range(xyz: any) {
    this.searchForm.value.range = xyz;
    this.searchForm.patchValue({
      range: this.searchForm.value.range,
    });
  }

  duration(time: any) {
    this.searchForm.value.duration = time;
    this.searchForm.patchValue({
      duration: this.searchForm.value.duration,
    });
  }

  onEnter(event:any) {
    // if (.keyCode === 13) {
      let check = event.target.value;
    this.selectedCountries = this.search(check);
    // }
  }
  search(value: string) {
    let filter = this.countries.filter((item : any) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    return [...filter];
  }
  ngOnDestroy(): void {
    this.styles.map((element:any)=>{
      this.meta.removeLink("#removethisdevelop");
    });
  }
}
