import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hiring-developers',
  templateUrl: './hiring-developers.component.html',
  styleUrls: ['./hiring-developers.component.scss']
})
export class HiringDevelopersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
