import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-js-full-stack',
  templateUrl: './js-full-stack.component.html',
  styleUrls: ['./js-full-stack.component.scss']
})
export class JsFullStackComponent implements OnInit {

  constructor( 
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
    }
    firstMAtch = ['12']
  ngOnInit(): void {
    this.meta.setMetaFields('FullStackDev');
  }

}
