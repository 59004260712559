<footer id="footer" style="background-image: url('../../../assets/images/contact.jpg');background-attachment: fixed;">
    <div class="container">
        
        <div class="row contact-wrapper">

            <div class="col-md-8 col-sm-12 quick-links-container">
                <div class="row quick-links">
                    <div class="col-md-4 col-sm-4 col-xs-6">
                        <div class="links-heading">Quick Links</div>
                        <ul>
                            <li><a routerLink="/about-us.html">About us</a></li>
                            <li><a routerLink="/contact-us.html">Contact Us</a></li>
                            <!-- <li><a routerLink="/blog">Blog</a></li> -->
                            <li><a routerLink="/career">Career</a></li>
                            <li><a routerLink="/portfolio.html">Our Portfolio</a></li>
                        </ul>
                    </div>
                    <!-- <div class="col-md-4 col-sm-4 col-xs-6">
                        <div class="links-heading">Services</div>
                        <ul>
                            <li><a routerLink="/nft-development.html">NFT Development Company</a></li>
                            <li><a routerLink="/augumented-reality.html">AR Development Company</a></li>
                            <li><a routerLink="/ecommerce-development.html">Ecommerce Development Services</a></li>
                            <li><a routerLink="/web-application-development.html">Web App Development Services</a></li>
                        </ul>
                    </div> -->
                    <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="links-heading">Hire Resource</div>
                        <ul>
                            <li><a routerLink="/hire-nft-ecommerce-developers.html">Hire NFT Developers</a></li>
                            <li><a routerLink="/hire-magento-developers.html">Hire Magento Developers</a></li>
                            <li><a routerLink="/hire-shopify-developers.html">Hire Shopify Developers</a></li>
                            <li><a routerLink="/hire-react-js-developers.html">Hire React JS Developers</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12 communicate">
                <h3 style="margin-bottom: 20px;"><img src="https://info.yuginfo.in/yuginfo/home-new/image/about/support.png" alt="Singapore" style="margin-right: 10px;max-width: 20px;"> Get in Touch with Us</h3>
                <h2 class="call_us-h2">
                    <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/sg.png" alt="Singapore" style="margin-right: 10px;">
                    <a href="tel:+6580151152">(+65)80151152</a>
                </h2>
                <!-- <h2 class="call_us-h2">
                    <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/in.png" alt="Singapore" style="margin-right: 10px;">
                    <a href="tel:+91-7426853194">(+91)7426853194</a>
                </h2> -->
                <div class="detail_fine">
                    <i class="fa fa-envelope email" aria-hidden="true"></i>
                    <a href="mailto:sales@yuginfo.in?Subject=Hello%20again">sales@yuginfo.in</a>
                </div>
            </div>
        </div>
    </div>

    <div class="container copyright-container">
        <div class="row copyright">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="copright_text" style="text-align: center !important;"> Copyright © <a href="javascript:void(0);">2021-2022 Yug Enterprises
                        </a> All Rights Reserved.<br>
                </div>
            </div>
            <div class="col-md-5 col-sm-4 col-xs-12 social_blockfooter">
                
            </div>
        </div>
    </div>
</footer>