<div class="wrapper section__wrapper" >
    <h1 class="section__header"  style="margin-bottom: 0px; ">
      {{name}}
    </h1>
    <div class="section__subheader">
      San Diego
    </div>

  </div>
<div class=" section-v2">
    <div class="careers__wrapper_flexed" style="position: relative;">
      <div >
        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2"  >
          <a href="/careers" target="_blank" class="careers__back_btn">
            &lt; See all openings </a>
            <div   innerHtml="{{job[0]?.description}}">
              <!-- This is a once-in-a-lifetime opportunity to expand a community for a hyper-growth startup. ClickUp is a
              product-led growth company that, up until 2020, has spent $0 on marketing and grew purely from organic,
              viral growth as users discovered, grew to love, and shared our software. ClickUp is the fastest growing
              productivity app, growing to 200,000+ teams and a $1 billion 🦄 valuation in under 3 years. We’re now one
              of the fastest-growing software companies in the world.
              <br><br>
              We're looking for a passionate, highly motivated individual to own the strategy and performance of paid
              search activity. You will lead paid search planning and media-buying across all aspect of search, ensuring
              both functions are optimized to achieve high levels of growth with an ROI-focus. You have an excellent
              understanding of market trends and developments, and will identify new copy, keyword and targeting
              opprotunities to drive our search ad campaigns forward. You'll be responsible for further establishing our
              brand across a variety of channels. -->
              <!-- {{jobs.description}} -->
             
        </div>
            
             <!-- <div innerHtml="{{name.description}}"> </div> -->
             <!-- <div [innerHTML]="htmlStr"></div> -->
        </div>
        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2">
          <div class="careers__question">The Role:</div>
        </div>
        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2" >
          <ul>
            <li *ngFor="let results of result">- {{results}}</li>
          </ul>
          
        </div>

        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2">
          <div class="careers__question">Qualifications:</div>
        </div>
        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2">
          <ul>
            <li *ngFor="let qualities of qualify">-{{qualities}}</li>
           
          </ul>
          
        </div>

        <div class="wrapper section__wrapper careers__wrapper careers__wrapper-v2" *ngFor="let jobs of job">
          <a (click)=moveTo(jobs.title) class="btn btn_rose" style="margin: 25px 0 0;">Apply Now</a>
        </div>

      </div>
      <div class="" style=""><div class="inner-wrapper-sticky" style="position: relative;">
        <a href="https://dev-forms.clickup.com/f/ad-101224/9B7JHWIY1C6F12B26S" target="_blank" class="btn btn_rose" style="margin: 0 auto 35px;">Apply Now</a>

        <div class="share">
          <div class="share__title">
            Or share this role
          </div>
          <style>
            [data-tooltip]::before {
              width: max-content;
            }

            [data-tooltip]::after {
              left: 10px;
            }
          </style>
          <!-- facebook -->
          <a href="javascript:void(0)" id="fb-share-button" class="share__item fb">
            <svg viewBox="0 0 12 12" class="share__icon" preserveAspectRatio="xMidYMid meet">
              <path class="svg-icon-path" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z">
              </path>
            </svg>
          </a>

          <!-- twitter -->
          <a href="https://twitter.com/intent/tweet" class="share__item tw" target="_blank"><svg class="share__icon" viewBox="0 0 310 310">
              <path d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73   c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783   c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598   C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467   c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977   c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889   c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597   c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961   c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895   c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367   c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998   C307.394,57.037,305.009,56.486,302.973,57.388z">
              </path>
            </svg></a>

          <!-- linkedin -->
          <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://clickup.com/careers/media-buyer-search&amp;title=Media%20Buyer%20Search&amp;source=ClickUp" class="share__item ln" target="_blank"><svg class="share__icon" fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M19,19H16V13.7A1.5,1.5 0 0,0 14.5,12.2A1.5,1.5 0 0,0 13,13.7V19H10V10H13V11.2C13.5,10.36 14.59,9.8 15.5,9.8A3.5,3.5 0 0,1 19,13.3M6.5,8.31C5.5,8.31 4.69,7.5 4.69,6.5A1.81,1.81 0 0,1 6.5,4.69C7.5,4.69 8.31,5.5 8.31,6.5A1.81,1.81 0 0,1 6.5,8.31M8,19H5V10H8M20,2H4C2.89,2 2,2.89 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z">
              </path>
            </svg></a>

          <!-- Trigger -->
          <a href="javascript:void(0)" class="share__item cp" data-clipboard-action="copy" data-tooltip="Copy to clipboard" data-clipboard-text="https://clickup.com/careers/media-buyer-search"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" class="share__icon" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="612.001px" height="612.001px" viewBox="0 0 612.001 612.001" style="enable-background:new 0 0 612.001 612.001;" xml:space="preserve">
              <g>
                <g id="Clip">
                  <g>
                    <path d="M565.488,74.616l-2.868-2.833c-63.386-61.375-164.907-60.728-227.507,1.889L45.34,363.532
            C23.501,385.406,0,425.134,0,460.683c0,33.38,13.027,64.802,36.65,88.407c23.641,23.658,55.08,36.686,88.53,36.686h0.018
            c33.45-0.018,64.89-13.045,88.513-36.702l250.151-250.168c17.188-17.188,26.596-41.004,25.756-65.379
            c-0.786-22.364-9.932-43.364-25.756-59.154c-16.646-16.629-38.749-25.792-62.284-25.792c-23.536,0-45.655,9.145-62.249,25.792
            L147.754,365.963c-4.826,4.773-7.851,11.383-7.851,18.691c0,14.479,11.733,26.229,26.229,26.229
            c7.239,0,13.779-2.938,18.517-7.676l0.018,0.018l191.766-191.8c6.854-6.837,16.314-10.194,25.739-10.037
            c9.04,0.14,18.027,3.515,24.619,10.089c6.383,6.382,10.072,14.88,10.404,23.851c0.35,10.002-3.357,19.427-10.422,26.491
            l-250.15,250.167c-13.744,13.744-31.999,21.315-51.425,21.333h-0.018c-19.427,0-37.699-7.589-51.443-21.333
            c-13.709-13.709-21.28-31.929-21.28-51.303c0-16.297,13.744-43.784,29.988-60.063l289.773-289.843
            c42.455-42.49,111.349-42.788,154.188-1.049l2.78,2.798c41.074,42.945,40.497,111.297-1.73,153.542L287.623,505.918
            c-4.809,4.773-7.799,11.349-7.799,18.657c0,14.479,11.733,26.229,26.229,26.229c7.24,0,13.761-2.938,18.518-7.658l0.017,0.018
            l239.975-239.991C627.51,240.188,627.807,137.967,565.488,74.616z"></path>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </div>
      <div class="resize-sensor" style="position: absolute; inset: 0px; overflow: hidden; z-index: -1; visibility: hidden;"><div class="resize-sensor-expand" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0px; top: 0px; transition: all 0s ease 0s; width: 100000px; height: 100000px;"></div></div><div class="resize-sensor-shrink" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0; top: 0; transition: 0s; width: 200%; height: 200%"></div></div></div></div></div>
    <div class="resize-sensor" style="position: absolute; inset: 0px; overflow: hidden; z-index: -1; visibility: hidden;"><div class="resize-sensor-expand" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0px; top: 0px; transition: all 0s ease 0s; width: 100000px; height: 100000px;"></div></div><div class="resize-sensor-shrink" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0; top: 0; transition: 0s; width: 200%; height: 200%"></div></div></div></div>
  </div>
  <div class="wrapper section__wrapper wrapper_horizontal" style="max-width: 1450px;">
    <div>
      <ul>
        <li>You love design</li>
        <li>You want to work somewhere where you’ll make a massive impact</li>
        <li>You’re able to work with a team of diverse thought leaders</li>
      </ul>
      <ul>
        <li>You are a product person</li>
        <li>You are creative but you also appreciate data</li>
        <li>You are willing to dedicate yourself to this product </li>
        <li>You believe in the mission of making the world more productive</li>
        <li>You consider yourself DIFFERENT and EXCEPTIONAL</li>
      </ul>
    </div>
    <div>
      <h2 class="section-v2__header section-v2__header_v2" style="margin: 0;  padding-left: 60px; font-size: 46px;  font-weight: revert;">About You</h2>
      <div class="section-v2__subheader section-v2__subheader_v1 section-v2__subheader_v2 section-v2__subheader_v2-sm" style="text-align: left;color: grey;">If
        you love what you do and wake up excited,<br> you'll fit right in!</div>
    </div>
  </div>
  <div class="wrapper section__wrapper wrapper_horizontal" style="max-width: 1450px;">
    <div>
      <h2 class="section-v2__header section-v2__header_v2" style="margin: 0;  padding-left: 60px; font-size: 46px;  font-weight: revert;">Benefits</h2>
      <div class="section-v2__subheader section-v2__subheader_v1 section-v2__subheader_v2 section-v2__subheader_v2-sm" style="text-align: left;color: grey;">If
        you love what you do and wake up excited,<br> you'll fit right in!</div>
    </div>
    <div>
      <ul>
        <li>You love design</li>
        <li>You want to work somewhere where you’ll make a massive impact</li>
        <li>You’re able to work with a team of diverse thought leaders</li>
      </ul>
      <ul>
        <li>You are a product person</li>
        <li>You are creative but you also appreciate data</li>
        <li>You are willing to dedicate yourself to this product </li>
        <li>You believe in the mission of making the world more productive</li>
        <li>You consider yourself DIFFERENT and EXCEPTIONAL</li>
      </ul>
    </div>
  </div>
  <div class="section section-v2 section_process">
    <div class="wrapper section__wrapper">
      <div class="section-v2__subheader section-v2__subheader_v2 section-v2__subheader_main">process</div>
      <h2 class="section-v2__header section-v2__header_main">Our six step process.</h2>
    </div>
    <div class="clickup-process">
      <div class="clickup-process__wrapper">
        <div class="clickup-process__row clickup-process__slider" style="">
          
          
          
          
          
          
        <div class="clickup-process__item clickup-process__item_pink" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide20">
            <div class="clickup-process__num">01.</div>
            <div class="clickup-process__header">🔍 Review</div>
            <div class="clickup-process__text">Applicants are reviewed to ensure a good fit.</div>
          </div><div class="clickup-process__item clickup-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide21">
            <div class="clickup-process__num">02.</div>
            <div class="clickup-process__header">👋 Initial Interview</div>
            <div class="clickup-process__text">We want to get to know you better!</div>
          </div><div class="clickup-process__item clickup-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide22">
            <div class="clickup-process__num">03.</div>
            <div class="clickup-process__header">📥 Mini Project</div>
            <div class="clickup-process__text">A project for the team you’re applying for is distributed.</div>
          </div><div class="clickup-process__item clickup-process__item_purple" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide23">
            <div class="clickup-process__num">04.</div>
            <div class="clickup-process__header">🤓 Final Interview</div>
            <div class="clickup-process__text">This interview is conducted with team leads.</div>
          </div><div class="clickup-process__item clickup-process__item_yellow" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide24">
            <div class="clickup-process__num">05.</div>
            <div class="clickup-process__header">💰 Offer</div>
            <div class="clickup-process__text">A carefully tailored offer is sent to the candidate.</div>
          </div><div class="clickup-process__item clickup-process__item_green" style="" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide25">
            <div class="clickup-process__num">06.</div>
            <div class="clickup-process__header">🚀 Career Launch</div>
            <div class="clickup-process__text">Blast off into the future of productivity and unlimited possibility!
            </div>
          </div></div>
        <div class="clickup-process__image"></div>
      </div>

    </div>
  </div>