import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TooltipPosition} from '@angular/material/tooltip';
@Component({
  selector: 'app-services-content-point',
  templateUrl: './services-content-point.component.html',
  styleUrls: ['./services-content-point.component.scss']
})
export class ServicesContentPointComponent implements OnInit {
serchVariable:any;
showVariables:boolean=false;
newVariable='In the widgets of the new mobile UI framework, here we incorporate all imperative differences of the platform such as scrolling, navigation, fonts, icons, and more for a fully native performance on iOS and Android both. Our mobile app developers offer high-quality experiences across devices and platforms for the development process.'
newVariable1='We always believe in keeping our clients’ data confidential. We use accurate data confidentiality measures to keep the workflow appropriate and glitch-free.'
newVariable2='Our quick-witted team of developers performs their tasks efficiently and delivers scalable and robust apps to the clients. Our main motive is to offer on-time delivery with flexible features. You can rely on our developers as they will update you regarding the ongoing process of the project.' 
newVariable3='With a single codebase, our team of expert developers performs code that is useful for both iOS and Android applications. The team of developers performs processes with code advantages to make your app and website attractive.' 
newVariable4='Our development team works adroitly to get maximum leverages of the framework. Developers make the code scalable so that even after the launch and deployment we can scale your product in terms of performance. We follow imperative guidelines for coding the respective mobile platforms. We have delivered various applications in a short time with rich features and high quality.' 
newVariable5='There are different processes and techniques that are used by our dedicated team of developers. We believe in managing the procedure in a seamless way to make sure that there are less or no issues at the time of deployment of the product. The widgets that are present in the frameworks and other things, we integrate effortlessly to make the product engaging.' 
newVariable6='Our developers focus on native end-user experiences and provide a plethora of designs that are interactive and responsive along with rich widget sets and ample animation libraries.  ' 
newVariable7='We engage in agile methodologies, starting with a meticulous understanding of the clientele requirements. The approach and development methods we use are continuously evolving throughout the collaboration of cross-functional teams.' 
newVariable8='We consider transparency as the key to any business. The client can access all procedures and codes relevant to their app whenever they want to. Everything is visible to them. We keep updating the clients about the progress of the project at every stage of the development process in our Ionic app development services. ' 
newVariable9='We are a prominent Ionic app development company and our team analyzes your business requirements and offers affordable Ionic app development solutions. We help you in proposing the technology stack that will meet the specifications of the clients. ' 
newVariable10='We are a leading Ionic app development service provider and we offer flexibility in many ways. We are flexible in terms of working hours, time-zone specific, coding, the development approach to be taken into consideration, and 24/7 technical support. ' 
newVariable11='Hire a pool of talented developers on the basis of your project requirements. We offer comprehensive support to hire adept developers and the right team. You can select the developers to build a competent team.' 

headingVariable1=
['objective c',
'TOP iOS APP DEVELOPMENT SERVICES',
'Reinforce Your Outreach with an Android App Development Solutions',
'kotlin',
'Leverage the Benefits of Flutter App With Cost-Effective Development',
'Ionic App Development Services',
'Make Your Website Engaging & User-Friendly',
'Why Choose Us As Your Progressive Web App Developers',
'Building Compelling E-Commerce Website Development',
'Why Choose Yug Enterprises As Your CMS Website Developer',
'Expedite Your Business Workflow With Our Brilliant Development Process',
'Building Compelling JavaScript Applications With Our Prolific Development Services',
'',
'',
'',
'Why you should choose Yug Enterprises for Implementation of Augmented Reality?',
'Hire Us to Experience the Wide Spectrum Advantages of React Development Services'];
subheadingVariable1=[
  'Ionic Sub',
  'We are a top iOS app development company acknowledged for helping enterprises to boost their business strategy, design catchy experiences, develop feasible solutions, & code apps that add up value to the users’.',
  'We build top-notch Android applications on time and within clients’ budgets. Our prolific team of developers develops custom Android apps for all domains and scopes of businesses.',
  'java',
  'We are a leading Flutter web development company that has given many businesses a design with high-quality native interfaces for cross-platform web applications.',
  'We closely monitor and analyze every stage of the business process and provide Ionic application development services to startups, mid-level, and large enterprises. Leverage a wide variety of mobile-optimized components with our services.  ',
  'Our front-end development services will convey your business brand story in front of your global customers with impeccable designs.',
  'We aim to deliver responsive & custom web development services and establish long-term relationships with our clients.',
  'We help you build an innovative and engaging E-Commerce website that will help your business to attract more customers and meet their business requirements.',
  'We promise to deliver high-tech end-to-end solutions to our clients and offer them a full range of CMS services to expand their business globally.',
  'We promise to deliver excellent web development services for multiple industries. We offer cost-effective services with on time delivery of the projects.',
  'Yug Enterprises has dedicated team of JavaScript developers who quickly find the way to reach your business goals.',
  '',
  '',
  '',
  'The expert team of Augmented Reality leverage the potential of this amazing technology and ensure that all your business requirements are converted into extensive AR apps giving a lucrative user experience. ',
  'We make sure that our React Native mobile app development provides you with quality, transparency, precise communication, and human relations in a cost-effective way.',
];
@Input() public firstMAtchData:any[] = []
  constructor(public routers:Router) { 
this.serchVariable=routers.url;
  }

  ngOnInit(): void {
    console.log("firstMAtchData",this.firstMAtchData)
    
  }
  newdataSet(){
    this.showVariables=!this.showVariables

  }
  trimString(string: any, length: any) {
    return string.length > length
      ? string.substring(0, length) + '...'
      : string;
  }
  
}
