import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-vue-js',
  templateUrl: './vue-js.component.html',
  styleUrls: ['./vue-js.component.scss']
})
export class VueJsComponent implements OnInit {

  constructor(private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus technology','portfolio profilepagesection blogcontentbody mainaboutus blog contactus services getaquote careers HomeMain')
  }

  ngOnInit(): void {
    this.meta.setMetaFields('HireVue');
  }

}
