import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-pagenot-found',
  templateUrl: './pagenot-found.component.html',
  styleUrls: ['./pagenot-found.component.scss']
})
export class PagenotFoundComponent implements OnInit {

  constructor(private meta:MetafieldsService) { }

  ngOnInit(): void {
  }

}
