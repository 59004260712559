import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-swift',
  templateUrl: './ios-swift.component.html',
  styleUrls: ['./ios-swift.component.scss']
})
export class IosSwiftComponent implements OnInit {

  constructor() { }
  firstMAtch = ['2']
  ngOnInit(): void {
  }

}
