import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, NgZone } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { environment } from 'src/environments/environment';
import { ApisService } from '../services/apis.service';
import { FileUpload } from '../services/FileUpload/file-upload';
import { FileUploadService } from '../services/FileUpload/file-upload.service';
import { Location } from '@angular/common';
import { ToastComponent } from '../shared/toast/toast.component';
import { MetafieldsService } from '../services/metafields.service';
declare var google: any;

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
   geoCoder:any;
  name: any;
  form: any;
  questions: any;
  latitude: any;
  longitude: any;
  location: any;
  data : any = {};
  application: unknown;
  profileModel:any;
  address:any;
  selectedFiles: any;
  currentUpload: any;
  resume:any;
  scripts : any = [
    {
      src: "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCm7-U4MjLR88D3gO8HuJ3emPAfXSdCCO4",
      type: "text/javascript",
      id: "popper",
      elementType:"script"
    },
    {
      src: "https://sdk.amazonaws.com/js/aws-sdk-2.1.24.min.js",
      type: "text/javascript",
      id: "sdk",
      elementType:"script"
    }
  ]
  constructor(private activatedRoute:ActivatedRoute,
    private apisService:ApisService,
    private ngZone:NgZone,
    private upSvc : FileUploadService,
    private locat:Location,
    private toast : ToastComponent,
    private meta : MetafieldsService ) {
      
     
     }

  ngOnInit(): void {
    this.scripts.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }
  ngAfterViewInit() {
      let filter2={
        "where":{
          title:this.name
        }
      }
      this.apisService.getAllWithQuerywihName(environment.apis.job,filter2).then((res:any)=>{
        console.log(res);
      
      let filter={
        "where":{
          jobId:res[0]._id
        }
      }
      this.apisService.getAllWithQuerywihName(environment.apis.form,filter).then((res)=>{
        console.log(res);
        this.form=res;
        let ids=this.form[0].questions.sort((a:any,b:any) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).map((val:any)=>val.questionId)
        console.log(ids)
        let filter1={
          where:{
            _id:{"$in":ids}
          }
        }
        this.apisService.getAllWithQuerywihName(environment.apis.questions,filter1).then((res)=>{
          console.log(res);
          
          this.questions=res
          this.questions.map((element:any)=>{
            element.field=element.title.replace(/ /g,"_")
            element.fieldValue=""
          })
        })

       
      })
    }) 
}
funct(){
  this.geoCoder = new google.maps.Geocoder;
      var input = document.getElementById('searchTextField');
      let autocomplete = new google.maps.places.Autocomplete(input, {});
  autocomplete.addListener("place_changed", () => {
    this.ngZone.run(() => {
      let place = autocomplete.getPlace();
      console.log(place)
      this.address=place.formatted_address
      if (place.geometry === undefined || place.geometry === null) {
      return;
      }
 
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      console.log(this.latitude)
      console.log(this.longitude)
    });
    });
}
detectFiles(event:any) {
  console.log(event);
    this.selectedFiles = event.target.files;
    this.uploadSingle();
}
uploadSingle() {
  let file = this.selectedFiles.item(0);
  this.currentUpload = new FileUpload(file);
  this.upSvc.pushUpload(this.currentUpload,"/career/").then((res:any)=>{
    this.resume = res;
    console.log(res,this.resume);
  });

}
submit(form:any){
  console.log(form)
 
let data:any={}

this.questions.map((element:any)=>{
  if(element.type=="googleSearch"){
    data[element.field]={let:this.latitude,long:this.longitude,place:this.address};
  }
  else  if(element.type=='fileupload'){
    data[element.field]=this.resume;
  }
  else{
 data[element.field]=JSON.parse(JSON.stringify(element.fieldValue));

      }
})


let temp:any
temp={questions:data}
console.log(this.questions)

  this.apisService.add(temp,environment.apis.application).then((res)=>{
    console.log(res);
    this.toast.toastSuccess("Applied Successfully")
    this.locat.back();
})

}


}
