<div>
  <section id="tinyslider">
    <div
      class="dark"
      style="
        background-image: linear-gradient(
            rgb(7 2 45 / 81%) 0%,
            rgb(41 17 76 / 90%) 100%
          ),
          url('https://img.freepik.com/free-photo/top-view-business-man-hands-working-laptop-tablet-pc-wooden-desk_1423-278.jpg?size=626&ext=jpg');
        background-position: center top;
        background-size: cover;
        background-attachment: fixed;
      "
    >
      <div class="container clearfix">
        <div class="slider-caption slider-caption-center quoteslider">
          <h1 >
            CROSS PLATFORM AND RICH USER EXPERIENCE – MOBILE AND WEB APP
            SOLUTIONS
          </h1>

          <p  >
            PROGRESSIVE WEB APPS ARE RELIABLE + FAST + FEEL LIKE NATIVE APP ON
            THE DEVICE, WITH AN IMMERSIVE USER EXPERIENCE.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- Content
  
          ============================================= -->

  <section id="content">
    <div class="content-wrap" id="yuginfo_content">
      <div class="container clearfix">
        <div class="row clearfix"></div>
      </div>

      <div class="section nobottommargin contact quoteget" id="Contact">
        <div class="container clearfix" id="Contactus">
          <div class="row clearfix">
            <h3 class="" style="text-align: center">
              <span>GET A PERFECT QUOTE</span>
            </h3>
            <h4>
              We’re eager to work with you. Please share your project goals and
              contact information.
            </h4>
            <h4>
              We respond to 97% of messages within 1-2 business day. Really!
            </h4>
            <div
              class="col-md-12 col-sm-12 col-xs-12"
              id="contactusform_header"
            >
              <form [formGroup]="searchForm" (submit)="getAquote()">
                <div class="col-md-12 col-sm-12 col-xs-12 div_colbox">
                  <mat-form-field style="display:block">
                    <mat-label>Your Name*</mat-label>
                    <input matInput
                    formControlName="name"
                    placeholder=""
                    autocomplete="off"
                    id="name"
                    type="text"
                    required="required"
                    >
                  </mat-form-field>
                  
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 div_colbox">
                  <mat-form-field style="display:block">
                    <mat-label>Email Address*</mat-label>
                    <input matInput
                    formControlName="email_add"
                        placeholder=""
                        autocomplete="off"
                        id="email"
                        type="text"
                        required="required"
                    >
                  </mat-form-field>
                  
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12 div_colbox">
                  <mat-form-field style="display:block">
                    <mat-label>Skype ID*</mat-label>
                    <input matInput
                    name="data[Contacts][skypeid]"
                     placeholder=""  formControlName="skype_id"
                    autocomplete="off" tabindex="0" type="text" 
                    >
                  </mat-form-field>
                  
                </div>
                <div
                  class="col-md-6 col-sm-12 col-xs-12"
                  style="min-height: 50px"
                >
                <mat-form-field style="display:block">
                  <mat-label>Select Country</mat-label>
                  <input type="text" matInput
                  [matAutocomplete]="auto" formControlName="country" (keyup)="onEnter($event)"/>
                  <mat-autocomplete class="dropdown" #auto="matAutocomplete">
                  <mat-option  value="{{ct.name}}" *ngFor = "let ct of selectedCountries">
                    <a 
                      ><i
                        class="flagstrap-icon {{ct.flag}}"
                        style="margin-right: 20px"
                      ></i
                      >{{ct.name}}
                    </a>
                  </mat-option>
                </mat-autocomplete>

                  
                </mat-form-field>
                
                  
                
                </div>

                <div
                  class="col-md-6 col-sm-12 col-xs-12"
                  style="min-height: 50px"
                >
                <div class="col-sm-4 plrz contact-phone-num">
                  <mat-form-field>
                      <mat-label>Code</mat-label>
                      <mat-select
                          formControlName="countryCode" name="data[Contacts][country_code]" tabindex="0" id="country"
                          
                          >
                          <mat-option value="+1">US +1</mat-option>
                          <mat-option value="+20">EG +20</mat-option>
                          <mat-option value="+212">MA +212</mat-option>
                          <mat-option value="+213">DZ +213</mat-option>
                          <mat-option value="+216">TN +216</mat-option>
                          <mat-option value="+218">LY +218</mat-option>
                          <mat-option value="+220">GM +220</mat-option>
                          <mat-option value="+221">SN +221</mat-option>
                          <mat-option value="+222">MR +222</mat-option>
                          <mat-option value="+223">ML +223</mat-option>
                          <mat-option value="+224">GN +224</mat-option>
                          <mat-option value="+225">CI +225</mat-option>
                          <mat-option value="+226">BF +226</mat-option>
                          <mat-option value="+227">NE +227</mat-option>
                          <mat-option value="+228">TG +228</mat-option>
                          <mat-option value="+229">BJ +229</mat-option>
                          <mat-option value="+230">MU +230</mat-option>
                          <mat-option value="+231">LR +231</mat-option>
                          <mat-option value="+232">SL +232</mat-option>
                          <mat-option value="+233">GH +233</mat-option>
                          <mat-option value="+234">NG +234</mat-option>
                          <mat-option value="+235">TD +235</mat-option>
                          <mat-option value="+236">CF +236</mat-option>
                          <mat-option value="+237">CM +237</mat-option>
                          <mat-option value="+238">CV +238</mat-option>
                          <mat-option value="+239">ST +239</mat-option>
                          <mat-option value="+240">GQ +240</mat-option>
                          <mat-option value="+241">GA +241</mat-option>
                          <mat-option value="+242">CG +242</mat-option>
                          <mat-option value="+243">ZR +243</mat-option>
                          <mat-option value="+244">AO +244</mat-option>
                          <mat-option value="+245">GW +245</mat-option>
                          <mat-option value="+246">BB +246</mat-option>
                          <mat-option value="+248">SC +248</mat-option>
                          <mat-option value="+249">SD +249</mat-option>
                          <mat-option value="+250">RW +250</mat-option>
                          <mat-option value="+251">ET +251</mat-option>
                          <mat-option value="+252">SO +252</mat-option>
                          <mat-option value="+253">DJ +253</mat-option>
                          <mat-option value="+254">KE +254</mat-option>
                          <mat-option value="+255">TZ +255</mat-option>
                          <mat-option value="+256">UG +256</mat-option>
                          <mat-option value="+257">BI +257</mat-option>
                          <mat-option value="+258">MZ +258</mat-option>
                          <mat-option value="+260">ZM +260</mat-option>
                          <mat-option value="+261">MG +261</mat-option>
                          <mat-option value="+263">ZW +263</mat-option>
                          <mat-option value="+264">NA +264</mat-option>
                          <mat-option value="+265">MW +265</mat-option>
                          <mat-option value="+266">LS +266</mat-option>
                          <mat-option value="+267">BW +267</mat-option>
                          <mat-option value="+268">SZ +268</mat-option>
                          <mat-option value="+269">KM +269</mat-option>
                          <mat-option value="+27">ZA +27</mat-option>
                          <mat-option value="+290">SH +290</mat-option>
                          <mat-option value="+299">GL +299</mat-option>
                          <mat-option value="+30">GR +30</mat-option>
                          <mat-option value="+31">NL +31</mat-option>
                          <mat-option value="+32">BE +32</mat-option>
                          <mat-option value="+33">FR +33</mat-option>
                          <mat-option value="+34">ES +34</mat-option>
                          <mat-option value="+345">KY +345</mat-option>
                          <mat-option value="+351">PT +351</mat-option>
                          <mat-option value="+352">LU +352</mat-option>
                          <mat-option value="+353">IE +353</mat-option>
                          <mat-option value="+354">IS +354</mat-option>
                          <mat-option value="+355">AL +355</mat-option>
                          <mat-option value="+356">MT +356</mat-option>
                          <mat-option value="+357">CY +357</mat-option>
                          <mat-option value="+358">FI +358</mat-option>
                          <mat-option value="+359">BG +359</mat-option>
                          <mat-option value="+36">HU +36</mat-option>
                          <mat-option value="+370">LT +370</mat-option>
                          <mat-option value="+371">LV +371</mat-option>
                          <mat-option value="+372">EE +372</mat-option>
                          <mat-option value="+373">MD +373</mat-option>
                          <mat-option value="+374">AM +374</mat-option>
                          <mat-option value="+375">BY +375</mat-option>
                          <mat-option value="+376">AD +376</mat-option>
                          <mat-option value="+377">MC +377</mat-option>
                          <mat-option value="+378">SM +378</mat-option>
                          <mat-option value="+380">UA +380</mat-option>
                          <mat-option value="+381">RS +381</mat-option>
                          <mat-option value="+382">ME +382</mat-option>
                          <mat-option value="+385">HR +385</mat-option>
                          <mat-option value="+387">BA +387</mat-option>
                          <mat-option value="+389">MK +389</mat-option>
                          <mat-option value="+39">IT +39</mat-option>
                          <mat-option value="+40">RO +40</mat-option>
                          <mat-option value="+41">CH +41</mat-option>
                          <mat-option value="+420">CZ +420</mat-option>
                          <mat-option value="+421">SK +421</mat-option>
                          <mat-option value="+423">LI +423</mat-option>
                          <mat-option value="+43">AT +43</mat-option>
                          <mat-option value="+44">GB +44</mat-option>
                          <mat-option value="+441">BM +441</mat-option>
                          <mat-option value="+45">DK +45</mat-option>
                          <mat-option value="+46">SE +46</mat-option>
                          <mat-option value="+47">NO +47</mat-option>
                          <mat-option value="+473">GD +473</mat-option>
                          <mat-option value="+48">PL +48</mat-option>
                          <mat-option value="+49">DE +49</mat-option>
                          <mat-option value="+501">BZ +501</mat-option>
                          <mat-option value="+502">GT +502</mat-option>
                          <mat-option value="+503">SV +503</mat-option>
                          <mat-option value="+504">HN +504</mat-option>
                          <mat-option value="+505">NI +505</mat-option>
                          <mat-option value="+506">CR +506</mat-option>
                          <mat-option value="+507">PA +507</mat-option>
                          <mat-option value="+509">HT +509</mat-option>
                          <mat-option value="+51">PE +51</mat-option>
                          <mat-option value="+52">MX +52</mat-option>
                          <mat-option value="+53">CU +53</mat-option>
                          <mat-option value="+54">AR +54</mat-option>
                          <mat-option value="+55">BR +55</mat-option>
                          <mat-option value="+56">CL +56</mat-option>
                          <mat-option value="+57">CO +57</mat-option>
                          <mat-option value="+58">VE +58</mat-option>
                          <mat-option value="+591">BO +591</mat-option>
                          <mat-option value="+592">GY +592</mat-option>
                          <mat-option value="+593">EC +593</mat-option>
                          <mat-option value="+595">PY +595</mat-option>
                          <mat-option value="+597">SR +597</mat-option>
                          <mat-option value="+598">UY +598</mat-option>
                          <mat-option value="+60">MY +60</mat-option>
                          <mat-option value="+61">AU +61</mat-option>
                          <mat-option value="+62">ID +62</mat-option>
                          <mat-option value="+63">PH +63</mat-option>
                          <mat-option value="+64">NZ +64</mat-option>
                          <mat-option value="+65">SG +65</mat-option>
                          <mat-option value="+66">TH +66</mat-option>
                          <mat-option value="+664">MS +664</mat-option>
                          <mat-option value="+673">BN +673</mat-option>
                          <mat-option value="+674">NR +674</mat-option>
                          <mat-option value="+675">PG +675</mat-option>
                          <mat-option value="+676">TO +676</mat-option>
                          <mat-option value="+677">SB +677</mat-option>
                          <mat-option value="+678">VU +678</mat-option>
                          <mat-option value="+679">FJ +679</mat-option>
                          <mat-option value="+685">WS +685</mat-option>
                          <mat-option value="+686">KI +686</mat-option>
                          <mat-option value="+691">FM +691</mat-option>
                          <mat-option value="+7">KZ +7</mat-option>
                          <mat-option value="+758">LC +758</mat-option>
                          <mat-option value="+767">DM +767</mat-option>
                          <mat-option value="+784">VC +784</mat-option>
                          <mat-option value="+809">DO +809</mat-option>
                          <mat-option value="+81">JP +81</mat-option>
                          <mat-option value="+82">KR +82</mat-option>
                          <mat-option value="+84">VN +84</mat-option>
                          <mat-option value="+850">KP +850</mat-option>
                          <mat-option value="+852">HK +852</mat-option>
                          <mat-option value="+853">MO +853</mat-option>
                          <mat-option value="+855">KH +855</mat-option>
                          <mat-option value="+856">LA +856</mat-option>
                          <mat-option value="+86">CN +86</mat-option>
                          <mat-option value="+868">TT +868</mat-option>
                          <mat-option value="+869">KN +869</mat-option>
                          <mat-option value="+876">JM +876</mat-option>
                          <mat-option value="+880">BD +880</mat-option>
                          <mat-option value="+886">TW +886</mat-option>
                          <mat-option value="+90">TR +90</mat-option>
                          <mat-option value="+91" selected="selected">
                            IN +91
                          </mat-option>
                          <mat-option value="+92">PK +92</mat-option>
                          <mat-option value="+93">AF +93</mat-option>
                          <mat-option value="+94">LK +94</mat-option>
                          <mat-option value="+95">MM +95</mat-option>
                          <mat-option value="+960">MV +960</mat-option>
                          <mat-option value="+961">LB +961</mat-option>
                          <mat-option value="+962">JO +962</mat-option>
                          <mat-option value="+963">SY +963</mat-option>
                          <mat-option value="+964">IQ +964</mat-option>
                          <mat-option value="+965">KW +965</mat-option>
                          <mat-option value="+966">SA +966</mat-option>
                          <mat-option value="+967">YE +967</mat-option>
                          <mat-option value="+968">OM +968</mat-option>
                          <mat-option value="+971">AE +971</mat-option>
                          <mat-option value="+972">IL +972</mat-option>
                          <mat-option value="+973">BH +973</mat-option>
                          <mat-option value="+974">QA +974</mat-option>
                          <mat-option value="+975">BT +975</mat-option>
                          <mat-option value="+976">MN +976</mat-option>
                          <mat-option value="+977">NP +977</mat-option>
                          <mat-option value="+98">IR +98</mat-option>
                          <mat-option value="+993">TM +993</mat-option>
                          <mat-option value="+994">AZ +994</mat-option>
                          <mat-option value="+995">GE +995</mat-option>
                          <mat-option value="+996">KG +996</mat-option>
                          <mat-option value="+998">UZ +998</mat-option>
                          </mat-select>
                        </mat-form-field>
                      
                  </div>
                  <div class="col-sm-8 plrz">
                      <mat-form-field>
                          <mat-label>Phone Number</mat-label>
                          <input matInput
                          name="data[Contacts][phone]" formControlName="phone_num"
                          placeholder=""
                          autocomplete="off" tabindex="0" type="tel" id="phone_num"
                          >
                      </mat-form-field>
                  </div>
                </div>
              
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <h2 class="tellus">TELL US ABOUT YOUR PROJECT</h2>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12 div_colbox">
                  <mat-form-field style="display:block">
                    <mat-label>What’s your project all about?*</mat-label>
                    <input matInput
                    name="data[Contacts][skypeid]"
                     placeholder=""  formControlName="project"
                    autocomplete="off" tabindex="0" type="text" 
                    >
                  </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 form-group waylf">
                  <p id="demos">What Are You Looking For?*</p>
                  <p id="demo" style="color: red"></p>
                  <div class="row" style="margin-top: 2%; margin-left: 0%">
                    <div class="col-sm-6 col-md-4 first_sboxcon">
                      <label class="slect_box"
                        ><input
                          formControlName="MobileAppDevelopment"
                          class="checkbox_enquiry"
                          type="checkbox"
                          name="requirement[]"
                          value="Mobile App Development"
                          checked
                          v-model.trim="requirement"
                        /><span>Mobile App Development</span></label
                      >
                      <label class="slect_box"
                        ><input
                          formControlName="HireDedicatedResources"
                          class="checkbox_enquiry"
                          type="checkbox"
                          name="requirement[]"
                          value="Hire Dedicated Resources"
                          v-model.trim="requirement"
                        /><span>Hire Dedicated Resources</span></label
                      >
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="slect_box"
                        ><input
                          formControlName="ApplicationService"
                          class="checkbox_enquiry"
                          type="checkbox"
                          name="requirement[]"
                          value="Application Service"
                          v-model.trim="requirement"
                        /><span>Application Service</span></label
                      >
                      <label class="slect_box"
                        ><input
                          formControlName="ProgressiveWebApp"
                          class="checkbox_enquiry"
                          type="checkbox"
                          name="requirement[]"
                          value="Progressive Web App"
                          v-model.trim="requirement"
                        /><span>Progressive Web App</span></label
                      >
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="slect_box"
                        ><input
                          formControlName="CloudSolutions"
                          class="checkbox_enquiry"
                          type="checkbox"
                          name="requirement[]"
                          value="Cloud Solution"
                          v-model.trim="requirement"
                        /><span>Cloud Solution</span></label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12"></div>
                <div
                  class="col-md-6 col-sm-12 col-xs-12 dropdownboxquote"
                  style="min-height: 50px"
                >
                <mat-form-field style="display: block;">
                  <mat-label>Select your budget range</mat-label>
                  <mat-select name="data[Contacts][category]" formControlName="range" 
                  tabindex="0" id="range" >
                  <mat-option value="lessthan$5000">Less than $5000</mat-option>
                  <mat-option value="$5000-$10000">$5000-$10000</mat-option>
                  <mat-option value="$10000-$15000">$10000-$15000</mat-option>
                  <mat-option value="$15000-$20000">$15000-$20000</mat-option>
                  </mat-select>
                </mat-form-field>
                  
                </div>
                <div
                  class="col-md-6 col-sm-12 col-xs-12 dropdownboxquote"
                  style="min-height: 50px"
                >
                <mat-form-field style="display: block;">
                  <mat-label>Select your budget range</mat-label>
                  <mat-select name="data[Contacts][category]" formControlName="duration" 
                  tabindex="0" id="range" >
                    <mat-option value="Immediately">Immediately</mat-option>
                    <mat-option value="WithinaMonth">Within a Month</mat-option>
                    <mat-option value="1-3month">1-3 month</mat-option>
                    <mat-option value="3-6Month">3-6 Month</mat-option>
                  </mat-select>
                </mat-form-field>
                  
                </div>
                <div
                  class="col-md-12 col-sm-12 col-xs-12"
                  style="min-height: 20px"
                ></div>

                <div class="col-md-5 col-sm-12 col-xs-12"></div>
                <div class="col-md-7 col-sm-12 col-xs-12 signupbtnbtnsgq">
                  <input
                    type="submit"
                    value="Let's go!"
                    id="signupbtn1"
                    class="red-new-btn nextButton"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- #content end -->

  <a href="#" id="back-to-top" title="Back to top"
    ><i class="fa fa-arrow-up"></i
  ></a>
</div>
