export const environment = {
  production: true,
  apis:{
    blogs:"blogs",
    users:"users/vidhema-inquiry-form",
    AUTHOR:"authors",
    CATEGORY:"categories",
    job:"career/jobs",
    form:"career/form",
    careerCategory:"career/category",
    categoryJob:"career/category/jobs",
    questions:"career/question",
    application:"career/application"
  }
};
