
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { ApplyComponent } from './apply/apply.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BlogDetailViewComponent } from './pages/blog-detail-view/blog-detail-view.component';
import { BlogComponent } from './pages/blog/blog.component';
import { CareerComponent } from './pages/career/career.component';
import { ConnectWithUsComponent } from './pages/connect-with-us/connect-with-us.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { GetAquoteComponent } from './pages/get-aquote/get-aquote.component';
import { PagenotFoundComponent } from './pages/pagenot-found/pagenot-found.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { CmsComponent } from './pages/services/cms/cms.component';
import { ECommerceComponent } from './pages/services/e-commerce/e-commerce.component';
import { FrontEndComponent } from './pages/services/front-end/front-end.component';
import { JsFullStackComponent } from './pages/services/js-full-stack/js-full-stack.component';
import { NftDevelopmentComponent } from './pages/services/nft-development/nft-development.component';
import { ProgressiveWebAppComponent } from './pages/services/progressive-web-app/progressive-web-app.component';
import { WebApplicationComponent } from './pages/services/web-application/web-application.component';
import { AndroidComponent } from './pages/technologies/android/android.component';
import { AngularJsComponent } from './pages/technologies/angular-js/angular-js.component';
import { DotnetComponent } from './pages/technologies/dotnet/dotnet.component';
import { DrupalComponent } from './pages/technologies/drupal/drupal.component';
import { IosComponent } from './pages/technologies/ios/ios.component';
import { JavaComponent } from './pages/technologies/java/java.component';
import { MagentoComponent } from './pages/technologies/magento/magento.component';
import { NodeJsComponent } from './pages/technologies/node-js/node-js.component';
import { PhpComponent } from './pages/technologies/php/php.component';
import { PythonComponent } from './pages/technologies/python/python.component';
import { ReactJsComponent } from './pages/technologies/react-js/react-js.component';
import { RubyonrailComponent } from './pages/technologies/rubyonrail/rubyonrail.component';
import { ShopifyComponent } from './pages/technologies/shopify/shopify.component';
import { VueJsComponent } from './pages/technologies/vue-js/vue-js.component';
import { WoocommerceComponent } from './pages/technologies/woocommerce/woocommerce.component';
import { WordpressComponent } from './pages/technologies/wordpress/wordpress.component';
import { NftEcommerceComponent } from './pages/technologies/nft-ecommerce/nft-ecommerce.component';
import { SearchComponent } from './search/search.component';
import { NftMarketplaceComponent } from './pages/technologies/nft-marketplace/nft-marketplace.component';
import { HomeNewComponent } from './pages/home-new/home-new.component';
import { AndroidJavaComponent } from './pages/services/android-java/android-java.component';
import { HybridIonicComponent } from './pages/services/hybrid-ionic/hybrid-ionic.component';
import { AndroidKotlinComponent } from './pages/services/android-kotlin/android-kotlin.component';
import { IosSwiftComponent } from './pages/services/ios-swift/ios-swift.component';
import { HybridFlutterComponent } from './pages/services/hybrid-flutter/hybrid-flutter.component';
import { FlutterComponent } from './pages/technologies/flutter/flutter.component';
import { IonicComponent } from './pages/technologies/ionic/ionic.component';
import { ReactNativeComponent } from './pages/technologies/react-native/react-native.component';
import { ShopwareComponent } from './pages/technologies/shopware/shopware.component';
import { WixComponent } from './pages/technologies/wix/wix.component';
import { AndroidAppDevelopmentComponent } from './pages/services/android-app-development/android-app-development.component';
import { IosAppDevelopmentComponent } from './pages/services/ios-app-development/ios-app-development.component';
import { ReactNativeComponentServices } from './pages/services/react-native/react-native.component';
import { AugumentedRealityComponent } from './pages/services/augumented-reality/augumented-reality.component';
import { TestingComponentComponent } from './pages/testing-component/testing-component.component';
const routes: Routes = [
  { path: '', component: HomeNewComponent },
  { path: 'home.html', component: HomeNewComponent },
  {
    path: 'about-us.html',
    component : AboutUsComponent
  },
  {
    path: 'blog',
    component : BlogComponent
  },
  {
    matcher: (url) => {
      if (url.length === 2 && (/^[0-9]*$/).test(url[1].path)) {
        return {
          consumed: url,
          posParams: {
            id: new UrlSegment(url[1].toString(),{})
          }
        };
      }
      return null;
    },
    component: BlogComponent
  },
  {
    path: 'blog/:url',
    component : BlogDetailViewComponent
  },
  {
    path: 'portfolio.html',
    component : PortfolioComponent
  },
  {
    path: 'contact-us.html',
    component : ContactusComponent
  },
  
   { path:'getaquote.html',
    component:GetAquoteComponent
  },
  {
    path:'ConnectWithUs.html',
    component:ConnectWithUsComponent
  },
  {
    path:'ecommerce-development.html',
    component:ECommerceComponent
  },
  {
    path:'nft-development.html',
    component:NftDevelopmentComponent
  },
  {
    path:'resource-as-a-service.html',
    component:ECommerceComponent
  },
  {
    path:'magento-mobile-app-development.html',
    component:MagentoComponent
  },
  {
    path:'web-application-development.html',
    component:WebApplicationComponent
  },
  {
    path:'progressive-web-app-development.html',
    component:ProgressiveWebAppComponent
  },
  {
    path:'mobile-apps-development.html',
    component:IosAppDevelopmentComponent
  },
  {
    path:'front-end-development.html',
    component:FrontEndComponent
  },
  {
    path:'js-based-full-stack-development.html',
    component:JsFullStackComponent
  },
  {
    path:'psd-to-html-development.html',
    component:FrontEndComponent
  },
  {
    path:'cms-development.html',
    component:CmsComponent
  },
  {
    path:'web-design.html',
    component:FrontEndComponent
  },
  {
    path:'dedicated-developer.html',
    component:MagentoComponent
  },
  {
    path:'technologies-web-java.html',
    component:JavaComponent
  },
  {
    path:'technologies-web-dotnet.html',
    component:DotnetComponent
  },
  {
    path:'technologies-web-php.html',
    component:PhpComponent
  },
  {
    path:'technologies-web-rubyonrails.html',
    component:RubyonrailComponent
  },
  {
    path:'technologies-web-python.html',
    component:PythonComponent
  },
  {
    path:'technologies-mobile-android.html',
    component:AndroidComponent
  },
  {
    path:'technologies-mobile-ios.html',
    component:IosComponent
  },
  {
    path:'technologies-mobile-phonegap.html',
    component:IonicComponent
  },
  {
    path:'technologies-mobile-xamarin.html',
    component:ProgressiveWebAppComponent
  },
  {
    path:'technologies-ecommerce-magento.html',
    component:MagentoComponent
  },
  {
    path:'technologies-ecommerce-opencart.html',
    component:ECommerceComponent
  },
  {
    path:'technologies-ecommerce-woocommerce.html',
    component:WoocommerceComponent
  },
  {
    path:'technologies-ecommerce-nopcommerce.html',
    component:ECommerceComponent
  },
  {
    path:'technologies-ecommerce-prestashop.html',
    component:ECommerceComponent
  },
  {
    path:'technologies-ecommerce-shopify.html',
    component:ShopifyComponent
  },
  {
    path:'technologies-cms-wordpress.html',
    component:WordpressComponent
  },
  {
    path:'technologies-cms-drupal.html',
    component:DrupalComponent
  },
  {
    path:'technologies-cms-umbraco.html',
    component:CmsComponent
  },
  {
    path:'technologies-cms-sitefinity.html',
    component:CmsComponent
  },
  {
    path:'technologies-cms-kentico.html',
    component:CmsComponent
  },
  {
    path:'technologies-javascript-vue-js.html',
    component:VueJsComponent
  },
  {
    path:'technologies-javascript-angular-js.html',
    component:AngularJsComponent
  },
  {
    path:'technologies-javascript-node-js.html',
    component:NodeJsComponent
  },
  {
    path:'technologies-javascript-react-js.html',
    component:ReactJsComponent
  },
  {
    path:'technologies-javascript-react-js.html',
    component:ReactJsComponent
  },
  {
    path:'technologies-nft-ecommerce.html',
    component: NftEcommerceComponent
  },
  {
    path:'technologies-nft-marketplace.html',
    component: NftMarketplaceComponent
  },
  {
    path:'career',
    component:CareerComponent
  },
  {
    path:'notfound',
    component:PagenotFoundComponent
  },
  {
    path:'apply/:name',
    component:ApplyComponent
  },
  {
    path:'search/:name',
    component:SearchComponent
  },
  {
    path:'home-new',
    component: HomeNewComponent
  },
  {
    path:'android-java-development.html',
    component: AndroidJavaComponent
  },
  {
    path: 'hybrid-ionic-development.html',
    component: HybridIonicComponent
  },
  {
    path: 'hybrid-flutter-development.html',
    component: HybridFlutterComponent
  },
  {
    path: 'android-kotlin-development.html',
    component: AndroidKotlinComponent
  },
  {
    path: 'ios-swift-development.html',
    component: IosSwiftComponent
  },
  // Hire Developer Start
  {
    path: 'hire-java-developers.html',
    component: JavaComponent,
  },
  {
    path: 'hire-dotnet-developers.html',
    component:DotnetComponent
  },
  {
    path:'hire-php-developers.html',
    component:PhpComponent
  },
  {
    path:'hire-python-developers.html',
    component:PythonComponent
  },
  {
    path:'hire-android-developers.html',
    component:AndroidComponent
  },
  {
    path:'hire-ios-developers.html',
    component:IosComponent
  },
  {
    path: 'hire-flutter-developers.html',
    component: FlutterComponent
  },
  {
    path: 'hire-ionic-developers.html',
    component: IonicComponent
  },
  {
    path: 'hire-react-native-developers.html',
    component: ReactNativeComponent
  },
  {
    path:'hire-magento-developers.html',
    component:MagentoComponent
  },
  {
    path:'hire-woocommerce-developers.html',
    component:WoocommerceComponent
  },
  {
    path:'hire-shopify-developers.html',
    component:ShopifyComponent
  },
  {
    path: 'hire-shopware-developers.html',
    component: ShopwareComponent
  },
  {
    path:'hire-wordpress-developers.html',
    component:WordpressComponent
  },
  {
    path:'hire-drupal-developers.html',
    component:DrupalComponent
  },
  {
    path: 'hire-wix-developers.html',
    component: WixComponent
  },
  {
    path:'hire-vue-js-developers.html',
    component:VueJsComponent
  },
  {
    path:'hire-angular-js-developers.html',
    component:AngularJsComponent
  },
  {
    path:'hire-node-js-developers.html',
    component:NodeJsComponent
  },
  {
    path:'hire-react-js-developers.html',
    component:ReactJsComponent
  },
  {
    path:'hire-nft-ecommerce-developers.html',
    component: NftEcommerceComponent
  },
  {
    path:'hire-nft-marketplace-developers.html',
    component: NftMarketplaceComponent
  },
  //Hire Developer End
  {
    path: 'android-app-development.html',
    component: AndroidAppDevelopmentComponent
  },
  {
    path: 'ios-app-development.html',
    component: IosAppDevelopmentComponent
  },
  {
    path: 'hybrid-react-native.html',
    component: ReactNativeComponentServices
  },
  {
    path: 'augumented-reality.html',
    component: AugumentedRealityComponent
  },
  {
    path: 'testing-component.html',
    component: TestingComponentComponent
  },
  {
    path:'**',
    component:PagenotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
