import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-e-commerce',
  templateUrl: './e-commerce.component.html',
  styleUrls: ['./e-commerce.component.scss']
})
export class ECommerceComponent implements OnInit {

  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
    }
    firstMAtch = ['9']
  ngOnInit(): void {
    this.meta.setMetaFields('EcommDev');
      }
      
}
