import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {

  constructor(
    private meta: MetafieldsService,
    private router:Router
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
   }
   firstMAtch = ['10']
  ngOnInit(): void {
    this.meta.setMetaFields('CmsDev');
  }

}
