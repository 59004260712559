import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { BlogDetailViewComponent } from './pages/blog-detail-view/blog-detail-view.component';
import { BlogComponent } from './pages/blog/blog.component';
import { ConnectWithUsComponent } from './pages/connect-with-us/connect-with-us.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { GetAquoteComponent } from './pages/get-aquote/get-aquote.component';
import { PagenotFoundComponent } from './pages/pagenot-found/pagenot-found.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { CmsComponent } from './pages/services/cms/cms.component';
import { ECommerceComponent } from './pages/services/e-commerce/e-commerce.component';
import { FrontEndComponent } from './pages/services/front-end/front-end.component';
import { JsFullStackComponent } from './pages/services/js-full-stack/js-full-stack.component';
import { ProgressiveWebAppComponent } from './pages/services/progressive-web-app/progressive-web-app.component';
import { WebApplicationComponent } from './pages/services/web-application/web-application.component';
import { AndroidComponent } from './pages/technologies/android/android.component';
import { AngularJsComponent } from './pages/technologies/angular-js/angular-js.component';
import { DotnetComponent } from './pages/technologies/dotnet/dotnet.component';
import { DrupalComponent } from './pages/technologies/drupal/drupal.component';
import { IosComponent } from './pages/technologies/ios/ios.component';
import { JavaComponent } from './pages/technologies/java/java.component';
import { MagentoComponent } from './pages/technologies/magento/magento.component';
import { NodeJsComponent } from './pages/technologies/node-js/node-js.component';
import { PhpComponent } from './pages/technologies/php/php.component';
import { PythonComponent } from './pages/technologies/python/python.component';
import { ReactJsComponent } from './pages/technologies/react-js/react-js.component';
import { RubyonrailComponent } from './pages/technologies/rubyonrail/rubyonrail.component';
import { ShopifyComponent } from './pages/technologies/shopify/shopify.component';
import { VueJsComponent } from './pages/technologies/vue-js/vue-js.component';
import { WoocommerceComponent } from './pages/technologies/woocommerce/woocommerce.component';
import { WordpressComponent } from './pages/technologies/wordpress/wordpress.component';
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgProgressModule } from 'ngx-progressbar';
import { environment } from '../environments/environment';
import { CareerComponent } from './pages/career/career.component';
import { ApplyComponent } from './apply/apply.component';
import { SearchComponent } from './search/search.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ToastComponent } from './shared/toast/toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NftDevelopmentComponent } from './pages/services/nft-development/nft-development.component';
import { NftEcommerceComponent } from './pages/technologies/nft-ecommerce/nft-ecommerce.component';
import { NftMarketplaceComponent } from './pages/technologies/nft-marketplace/nft-marketplace.component';
import { HeaderNewComponent } from './layout/header-new/header-new.component';
import { FooterNewComponent } from './layout/footer-new/footer-new.component';
import { HomeNewComponent } from './pages/home-new/home-new.component';
import { AndroidJavaComponent } from './pages/services/android-java/android-java.component';
import { HybridIonicComponent } from './pages/services/hybrid-ionic/hybrid-ionic.component';
import { AndroidKotlinComponent } from './pages/services/android-kotlin/android-kotlin.component';
import { IosSwiftComponent } from './pages/services/ios-swift/ios-swift.component';
import { HybridFlutterComponent } from './pages/services/hybrid-flutter/hybrid-flutter.component';
import { ContactFormComponent } from './layout/contact-form/contact-form.component';
import { FlutterComponent } from './pages/technologies/flutter/flutter.component';
import { IonicComponent } from './pages/technologies/ionic/ionic.component';
import { ReactNativeComponent } from './pages/technologies/react-native/react-native.component';
import { CompanyDataComponent } from './layout/company-data/company-data.component';
import { WhyHireUsComponent } from './layout/why-hire-us/why-hire-us.component';
import { ShopwareComponent } from './pages/technologies/shopware/shopware.component';
import { WixComponent } from './pages/technologies/wix/wix.component';
import { ServicesContentPointComponent } from './layout/services-content-point/services-content-point.component';
import { StepsOfHiringProcessComponent } from './layout/steps-of-hiring-process/steps-of-hiring-process.component';
import { HiringDevelopersComponent } from './layout/hiring-developers/hiring-developers.component';
import { AndroidAppDevelopmentComponent } from './pages/services/android-app-development/android-app-development.component';
import { IosAppDevelopmentComponent } from './pages/services/ios-app-development/ios-app-development.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import {MatSelectModule} from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactNativeComponentServices } from './pages/services/react-native/react-native.component';
import { AugumentedRealityComponent } from './pages/services/augumented-reality/augumented-reality.component';
import { WhyChooseUsComponent } from './layout/why-choose-us/why-choose-us.component';
import { LazyLoadingDirective } from './services/lazy-loading.directive';
import { QuoteformComponent } from './layout/quoteform/quoteform.component';
import { TestingComponentComponent } from './pages/testing-component/testing-component.component';
import { ScrollAnimationDirective } from './pages/home-new/scroll-animation.directive';

@NgModule({
  
  declarations: [
    AppComponent,
    AboutUsComponent,
    BlogComponent,
    BlogDetailViewComponent,
    PortfolioComponent,
    ContactusComponent,
    GetAquoteComponent,
    ConnectWithUsComponent,
    ECommerceComponent,
    WebApplicationComponent,
    ProgressiveWebAppComponent,
    FrontEndComponent,
    JsFullStackComponent,
    CmsComponent,
    AndroidComponent,
    AngularJsComponent,
    DotnetComponent,
    DrupalComponent,
    IosComponent,
    JavaComponent,
    MagentoComponent,
    NodeJsComponent,
    PhpComponent,
    PythonComponent,
    ReactJsComponent,
    RubyonrailComponent,
    ShopifyComponent,
    VueJsComponent,
    WoocommerceComponent,
    WordpressComponent,
    PagenotFoundComponent,
    CareerComponent,
    ApplyComponent,
    SearchComponent,
    NftDevelopmentComponent,
    NftEcommerceComponent,
    NftMarketplaceComponent,
    HeaderNewComponent,
    FooterNewComponent,
    HomeNewComponent,
    AndroidJavaComponent,
    HybridIonicComponent,
    AndroidKotlinComponent,
    IosSwiftComponent,
    HybridFlutterComponent,
    ContactFormComponent,
    FlutterComponent,
    IonicComponent,
    ReactNativeComponent,
    CompanyDataComponent,
    WhyHireUsComponent,
    ShopwareComponent,
    WixComponent,
    ServicesContentPointComponent,
    StepsOfHiringProcessComponent,
    HiringDevelopersComponent,
    AndroidAppDevelopmentComponent,
    IosAppDevelopmentComponent,
    ReactNativeComponentServices,
    AugumentedRealityComponent,
    WhyChooseUsComponent,
    LazyLoadingDirective,
    QuoteformComponent,
    TestingComponentComponent,
    ScrollAnimationDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    GooglePlaceModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    NgProgressHttpModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    BrowserAnimationsModule,
    NgProgressModule.withConfig({
      spinnerPosition: "left",
      color: "#f41f0c",
      thick:true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [Meta,ToastComponent,ScrollAnimationDirective ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

