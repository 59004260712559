
<div class="top-section" id = "checc" appScrollAnimation [ngStyle]="{'opacity': api.checkScroll + 0.5 }" style="" >
    <div class="banner-my-section" style="    background: linear-gradient(to bottom, #0f022c, transparent);padding-top: 10%;padding-bottom: 10%;">

        <div class="wrapper">

            <div class="container-fluid header-banner-section" >
                <div class="col-md-6">
                    <h2 id= "mainp" class="sentence">You focus on your <span>Planning</span> we’ll redefine your <span>Business</span>
                        
                    </h2>
        
                    <div class="hero-para animate__animated animate__fadeInDown animate__slower">
                        <p>Employers are always looking to recruit a pool of talented candidates. We provide job seekers the insight to anticipate what they are searching for and take prompt steps to fulfill those requirements. 
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
    <svg id="my_svg_element" viewBox="0 0 1425 885" fill="none" xmlns="http://www.w3.org/2000/svg" class=" drawsvg-initialized " style="
">
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1425" height="885">
                            <rect width="1425" height="885" fill="#C4C4C4"></rect>
                            </mask>
                            <g mask="url(#mask0)">
                            <path d="M1234.72 1197.88C1234.72 1197.88 1252.8 647.228 685.93 636.622C607.34 635.135 58.4301 618.807 -395.928 605.028" stroke="white" stroke-opacity="0.2" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1965.18, 1965.18; stroke-dashoffset: 0;"></path>
                            <path d="M1276.39 1137.72C1276.39 1137.72 1249.57 635.389 704.334 625.201C628.193 623.771 65.1712 624.567 -396.711 625.544" stroke="white" stroke-opacity="0.3" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1950.49, 1950.49; stroke-dashoffset: 0;"></path>
                            <path d="M1317.95 1077.56C1317.95 1077.56 1246.12 623.458 722.766 613.651C649.074 612.278 71.9185 630.757 -397.627 646.509" stroke="white" stroke-opacity="0.4" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1940.94, 1940.94; stroke-dashoffset: 0;"></path>
                            <path d="M1359.63 1017.41C1359.63 1017.41 1242.89 611.64 741.308 602.232C670.181 600.919 78.7543 637.66 -398.342 668.275" stroke="#9AD2FF" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1936.91, 1936.91; stroke-dashoffset: 0;"></path>
                            <path d="M1401.31 957.252C1401.31 957.252 1239.55 599.711 759.832 590.684C691.27 589.431 85.4117 644.99 -399.194 690.599" stroke="#9AD2FF" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1939.35, 1939.35; stroke-dashoffset: 0;"></path>
                            <path d="M1442.87 897.093C1442.87 897.093 1236.24 587.762 778.259 579.263C712.376 578.072 91.8623 652.897 -400.077 713.74" stroke="#9AD2FF" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1948.49, 1948.49; stroke-dashoffset: 0;"></path>
                            <path d="M1484.55 836.937C1484.55 836.937 1232.88 575.941 796.806 567.716C733.604 566.587 98.392 661.151 -400.973 737.205" stroke="#9AD2FF" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1964.87, 1964.87; stroke-dashoffset: 0;"></path>
                            <path d="M1526.11 776.779C1526.11 776.779 1229.56 564.013 815.21 556.294C754.83 555.124 104.784 669.983 -401.9 761.488" stroke="#9AD2FF" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 1988.36, 1988.36; stroke-dashoffset: 0;"></path>
                            <path d="M1567.79 716.602C1567.79 716.602 1226.22 552.171 833.753 544.855C776.192 543.75 111.156 679.353 -402.843 786.201" stroke="#9AD2FF" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2018.87, 2018.87; stroke-dashoffset: 0;"></path>
                            <path d="M1609.46 656.447C1609.46 656.447 1223 540.224 852.301 533.307C797.419 532.265 117.371 689.194 -403.808 811.496" stroke="#9AD2FF" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2055.89, 2055.89; stroke-dashoffset: 0;"></path>
                            <path d="M1651.03 596.288C1651.03 596.288 1219.55 528.293 870.71 521.756C818.763 520.783 123.453 699.485 -404.791 837.242" stroke="#9AD2FF" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2098.61, 2098.61; stroke-dashoffset: 0;"></path>
                            <path d="M1692.7 536.133C1692.7 536.133 1216.32 516.475 889.137 510.335C840.239 509.433 129.541 710.227 -405.8 863.7" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2146.6, 2146.6; stroke-dashoffset: 0;"></path>
                            <path d="M1734.38 475.977C1734.38 475.977 1212.99 504.546 907.657 498.895C861.694 498.06 135.584 721.55 -406.845 890.457" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2199.09, 2199.09; stroke-dashoffset: 0;"></path>
                            <path d="M1775.94 415.818C1775.94 415.818 1209.64 492.726 926.087 487.366C883.175 486.581 141.387 733.084 -407.862 917.711" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2255.43, 2255.43; stroke-dashoffset: 0;"></path>
                            <path d="M1817.62 355.663C1817.62 355.663 1206.31 480.776 944.631 475.926C904.79 475.235 147.284 745.201 -408.927 945.61" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2315.44, 2315.44; stroke-dashoffset: 0;"></path>
                            <path d="M1859.18 295.504C1859.18 295.504 1202.97 468.955 963.039 464.397C926.368 463.65 152.909 757.764 -410.002 973.768" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2378.41, 2378.41; stroke-dashoffset: 0;"></path>
                            <path d="M1900.86 235.349C1900.86 235.349 1199.63 457.027 981.581 452.957C947.961 452.281 158.66 770.675 -411.094 1002.38" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2444.23, 2444.23; stroke-dashoffset: 0;"></path>
                            <path d="M1942.42 175.169C1942.42 175.169 1196.29 445.056 1000.01 441.407C969.697 440.808 164.251 784.143 -412.207 1031.55" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2512.52, 2512.52; stroke-dashoffset: 0;"></path>
                            <path d="M1984.1 115.013C1984.1 115.013 1192.95 433.235 1018.56 429.967C991.404 429.464 169.737 797.953 -413.333 1061.06" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2583.14, 2583.14; stroke-dashoffset: 0;"></path>
                            <path d="M1978.63 75.3254C1799.92 152.368 1167.73 420.925 1037.08 418.44C1013.25 417.992 175.184 812.127 -414.358 1090.9" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2604.32, 2604.32; stroke-dashoffset: 0;"></path>
                            <path d="M1859.79 90.4618C1590.81 212.846 1145.58 408.667 1055.51 407.02C1034.96 406.649 180.496 826.86 -415.63 1121.23" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2501.77, 2501.77; stroke-dashoffset: 0;"></path>
                            <path d="M1737.49 115.88C1466.63 245.395 1136.37 396.688 1074.03 395.472C1056.79 395.178 185.661 841.783 -416.822 1151.86" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2393.34, 2393.34; stroke-dashoffset: 0;"></path>
                            <path d="M1635.94 138.959C1388.04 263.407 1135.12 384.679 1092.46 383.922C1078.64 383.707 190.947 857.183 -417.982 1182.86" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2306.04, 2306.04; stroke-dashoffset: 0;"></path>
                            <path d="M1556.11 157.395C1336.8 272.943 1138.62 373.018 1111.01 372.503C1100.61 372.261 196.1 873.053 -419.072 1214.45" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2241.57, 2241.57; stroke-dashoffset: 0;"></path>
                            <path d="M1494.67 171.24C1303.37 277.055 1145.68 361.31 1129.43 361.061C1122.48 360.899 201.004 889.37 -420.377 1246.22" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2196.82, 2196.82; stroke-dashoffset: 0;"></path>
                            <path d="M1447.67 181.09C1281.68 277.584 1155.17 349.681 1147.95 349.621C1144.42 349.538 205.965 906.012 -421.51 1278.34" stroke="#9AD2FF" stroke-width="0.7937" stroke-miterlimit="10" style="stroke-dasharray: 2167.66, 2167.66; stroke-dashoffset: 0;"></path>
                            </g>
                        </svg>
    <!-- <video id="video_background" preload="auto" autoplay="true" loop="loop" muted="muted" volume="0" width="100%" height="600"
        poster="../../../assets/images/slider07-img-01.jpg">
      
    </video> -->
</div>
<section class="mt-n5 z-index-9 position-relative our-slient-section brand-logo-mobile">
    <div class="container carousel-box">
        <div class="our-client">
            <h5>Our Clients</h5>
        </div>
        <div class="owl-carousel owl-theme owl-loaded owl-drag our-client-wrapper" id="client-slider">

                    <div class="item">
                        <img src="https://info.yuginfo.in/BlogImagesyug/202296165918-952love-bonito.png" alt="Love Bonito" loading="lazy" />
                    </div>
                    <div class="item ramybrook">
                        <img src="https://info.yuginfo.in/BlogImagesyug/20229617449-343ramybrook.png" alt="ramybrook" loading="lazy">
                    </div>
                    <div class="item">
                            <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/sp-commerce.webp" class="spcomlogo" alt="sp-commerce" loading="lazy">
                    </div>
                    <div class="item">
                            <img src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/bodyshop-logo.svg" alt="The Body Shop" loading="lazy">
                    </div>
                    <div class="item">
                            <img class="small-client-logos" src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/vans.svg" alt="Vans" loading="lazy">
                    </div>
                    <div class="item">
                            <img class="small-client-logos" src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/handm.svg" alt="H&M" loading="lazy">
                    </div>
                    <div class="item">
                        <img class="small-client-logos" src="https://info.yuginfo.in/BlogImagesyug/202291318425-161ada-digital-logo.jpg" alt=" ADA Digital " loading="lazy">
                    </div>
                    <div class="item">
                            <img class="medium-client-logos" src="https://info.yuginfo.in/BlogImagesyug/202291319339-702lockwood-logo-jpg.jpg" loading="lazy" alt="Lockwood" loading="lazy">
                    </div>
                    <div class="item">
                            <img src="https://info.yuginfo.in/yuginfo/home-new/image/about/stc.webp" loading="lazy" alt="STC" loading="lazy">
                    </div>
                    <div class="item">
                            <img src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/ilastword.webp" loading="lazy" alt="ilastword" loading="lazy">
                    </div>
                    
            
        </div>
    </div>
</section>

<section class="brands-logo-section-vid brand-logo-desktop">
    <div class="container-fluid">
        <div class="row-logos">
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/BlogImagesyug/20221010101441-361lockwood.png" alt="Lockwood">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/BlogImagesyug/20229617449-343ramybrook.png" alt="Ramybrook">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/yuginfo/home-new/image/about/sp-commerce.webp" alt="SP Commerce">
                </div>
            </div>
            
            
            <div class="main-logo-brand-div">
                <div>
                    <img src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/vans.svg" alt="Vans">
                </div>
            </div>
            
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/bodyshop-logo.svg" alt="The Body Shop">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img src="https://info.yuginfo.in/BlogImagesyug/202291318425-161ada-digital-logo.jpg" alt="ADA Logo">
                </div>
            </div>
            
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/yuginfo/home-new/image/about/stc.webp" alt="STC">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img class="small-logo-brand" src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/ilastword.webp" alt="ilastword">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img src="https://info.yuginfo.in/yuginfo/home-new/image/client-logo/handm.svg" alt="H&M">
                </div>
            </div>
            <div class="main-logo-brand-div">
                <div>
                    <img src="https://info.yuginfo.in/BlogImagesyug/202296165918-952love-bonito.png" alt="Love Bonito">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="profession-background left-new-section" data-aos="fade-up" data-aos-duration="2000" style="background-image: url('../../../assets/images/n-title-bg1.jpg');background-size: cover;background-position: right -260px top 10px;" id = "powerhouse" >
    <!-- appScrollAnimation [id] = "'powerhouse'" [ngStyle]="{'opacity': api && api.checkScroll && api.checkScroll.powerhouse + 0.4 }" -->
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-5 new-section-design dark-backg">
                <h2 class="" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-quad">Competent Team of Employees</h2>
                <p data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">We are creative thinkers, creators, and problem-solvers. Our professional team is dedicated to completing the project in a timely manner. We strive to assist our employees by introducing them to efficient methods of working. You can hire experts for your next project and advance your business growth in the tech world.
                </p>
            </div>
            
        </div>
    </div>
</section>
<section>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-6">
                <img src="./assets/images/staffing.jpg" data-aos="zoom-in" data-aos-duration="2000" data-aos-easing="ease-in-quad" alt="">
            </div>
            <div class="col-md-4 new-section-design">
                <h2 class="" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-quad">Tailored Staffing Process</h2>
                <p data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Our flex is our dedicated team of employees, and we consider them the most valuable asset we possess. We endeavor to create an honest workplace where employees feel appreciated and respected and have the opportunity to thrive and be successful.
                </p>
            </div>
        </div>
    </div>
</section>
<section class="profession-background left-new-section"  style="background-image: url('../../../assets/images/n-title-bg2.jpg');background-size: cover;background-position: right;">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-5 new-section-design dark-backg">
                <h2 class="" data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-quad">Optimized Recruitment Workflow</h2>
                <p data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">We provide a broad range of recruitment services in every corner of the globe. We offer services such as Online Talent Acquisition, Managed Services Programs (MSP), Recruitment Process Outsourcing (RPO), Outplacement, and Talent Mobility.
                </p>
            </div>
            
        </div>
    </div>
</section>
<div class="section about-us main-about-us">
    <div class="container">
        <div class="header-text">
            <div class="header-shadow-text">Web & Mobile App</div>
            <h1 data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-quad">Hire The Nonpareil Team of Developers and Efficiently Outsource Your Projects</h1>
            <p>We are always at your assistance</p>
        </div>
        
        <div class="row multi-info">
            <div class="col-lg-3 col-md-6 mb-4 ">
                <div class="border bg-white border-radius p-5 p-md-4 h-100 project-number-text aboutus-hover">
                    <span class="display-4 text-primary mb-3 d-block"></span>
                    <h4 class="">Projects can be developed in a flexible manner and will be completed according to the agreed timeframe</h4>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 about-us-video">
                <div class="h-100 bg-overlay-black-60 bg-holder border-radius p-3 position-relative d-flex align-items-center commondivclassmulti"
                    style="background-image: linear-gradient(to bottom, black, transparent), url(https://info.yuginfo.in/BlogImagesyug/2022817182128-138about-us-section.jpg);background-size: cover;">
                    <div class="position-relative text-center">
                        <h5 class="mb-0 text-white rating-text" style="line-height: normal;">Find jobs easily or outsource your work and forget about your concerns and worries and concentrate on your company while you hire the top talent in your workforce.
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="bg-theme border-radius p-5 p-md-4 h-100 commondivclassmulti">
                    <div class="d-flex align-items-center">

                    </div>
                    <p class="text-white mt-3">If a company can count on its staff is the best honor they could get. A great work environment and excellent opportunities help employees perform their duties adeptly. All of these elements together will give the motivation to help them advance their careers.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <div class="h-100 bg-overlay-black-60 bg-holder border-radius p-4 position-relative d-flex align-items-center commondivclassmulti"
                    style="background-image: url(https://info.yuginfo.in/yuginfo/home-new/image/7.webp);">
                    <div class="position-relative text-center">
                        <h5 class="mb-0 text-white rating-text">Rated as one of the top Outsourcing Agency
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 mb-4 mb-md-0 ">
                <div class="border h-100 text-center p-3 bg-white aboutus-hover commondivclassmulti">
                    <h5 class="mb-1 award-win-sol">We've been awarded globally</h5>
                    <img class="img-fluid" src="https://info.yuginfo.in/yuginfo/home-new/image/award.svg" alt="Recognized Awards" loading="lazy">
                    <p>As we’ve given extraordinary services to our wonderful clients.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 mb-4 mb-md-0">
                <div class="h-100 bg-theme text-center p-5 p-md-4 border-radius clients-globle commondivclassmulti">
                    <span class="display-4 text-white">120+</span>
                    <h5 class="text-white">Clients across the globe</h5>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="border bg-white h-100 p-3 border-radius d-flex align-items-center aboutus-hover commondivclassmulti">

                    <p class="mt-3">Yug Enterprises has a proven track record of outsourcing candidates, helping job seekers find jobs, and hiring a bunch of skilled candidates. We are pleased to be the best option in the market for major companies and have provided our services to a wide array of clients from around the globe.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="last-section" id="contactUs">
    <div class="flex-container">
        <!-- <div class="flex-slide singapore">
            <div class="overlay"></div>
            <img id="bgvid" width="100%" height="100%" src="https://info.yuginfo.in/BlogImagesyug/20228172306-855singapore-backg.jpg"
                class="lasyItem loadVideoPoster" alt="Singapore Location" loading="lazy">

            <div class="overly-txt">
                <div class="title">
                    <h3><span></span>SINGAPORE<i class="fa fa-arrow-right" aria-hidden="true"></i></h3>
                </div>
                <div class="flex-about flex-about-home">
                   
                </div>
            </div>
        </div>
        <div class="flex-slide hyderabad">
            <div class="overlay"></div>
            <img id="bgvid" width="100%" height="100%" src="https://info.yuginfo.in/BlogImagesyug/2022817231153-170hyderabad-backg.jpg"
                class="lasyItem loadVideoPoster" alt="Hyderabad Location" loading="lazy">

            <div class="overly-txt">
                <div class="title">
                    <h3><span></span>HYDERABAD<i class="fa fa-arrow-right" aria-hidden="true"></i></h3>
                </div>
                <div class="flex-about flex-about-home">
                    

                </div>
            </div>
        </div> -->
        <div class="flex-slide jaipur">
            <div class="overlay"></div>
            <!-- <img id="bgvid" width="100%" height="100%" src=""
                class="lasyItem loadVideoPoster" alt=" Jaipur Location" loading="lazy"> -->
            <div style="background-image:url('https://images.pexels.com/photos/7800311/pexels-photo-7800311.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');background-attachment:fixed;height: 100%;background-size: cover;"></div>    
            <div class="overly-txt">
                <div class="title">
                    <h3>India</h3>
                </div>
                <div class="flex-about flex-about-home">
                    
                </div>
            </div>
        </div>

    </div>
</section>



<app-quoteform></app-quoteform>

<!-- contact ends -->


