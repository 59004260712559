<div >
<section id="tinyslider">
 <div id="clouds" style="display:none;">
           <div class="cloud x1"></div>
           <div class="cloud x1_5"></div>
           <div class="cloud x2"></div>
           <div class="cloud x3"></div>
           <div class="cloud x4"></div>
           <div class="cloud x5"></div>
       </div>
       <div class='c'>
           <div class='_404'>404</div>
           <hr>
           <div class='_1'>THE PAGE WAS NOT FOUND</div>
            <a routerLink="/" class="text-center">BACK TO HOME</a>
       </div>
      
 <a href="#" id="back-to-top" title="Back to top" ><i class="fa fa-arrow-up"></i></a>
 </section>
 </div>