import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Injector } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var Zone: any;
declare var $: any;
@Component({
  selector: 'app-blog-detail-view',
  templateUrl: './blog-detail-view.component.html',
  styleUrls: ['./blog-detail-view.component.scss']
})
export class BlogDetailViewComponent implements OnInit,OnDestroy {
  BlogData:any;
  subscription:any;
  stylesdeveloper : any = [
    {
      id:"removethisdevelop",
      href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
      rel:"stylesheet",
      elementType:"link"
    }
  ]
  constructor(

    private apisService:ApisService,
    private meta: Meta,
    private router:Router,
    private titleSer: Title,
    @Inject(PLATFORM_ID) private platformId: any,
    private _injector: Injector,
    private metablogs : MetafieldsService
) {
  this.apisService.addAndRemoveClass('aboutus blogcontentbody','portfolio profilepagesection mainaboutus contactus services careers technology getaquote HomeMain')
   }

   formateDate(date:any){
    var newDate = new Date(date);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[newDate.getMonth()]+', '+ ("0" + newDate.getDate()).slice(-2) + ' ' + newDate.getFullYear()
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.stylesdeveloper.map((element:any)=>{
      this.metablogs.removeLink("#removethisdevelop");
    });
    
  }

  ngOnInit(): void {
      this.subscription = this.router.events.subscribe((event) =>{
        if(event instanceof NavigationEnd) {
          console.log(event)
          let url="";
          if(isPlatformBrowser(this.platformId)){
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            url = new URL(location.href).pathname
          }else{
            let req =this._injector.get(REQUEST);
            url = req.url;
          }
          this.getApiData(url);
        }
      })
    let url="";
    if(isPlatformBrowser(this.platformId)){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      url = new URL(location.href).pathname
    }else{
      let req =this._injector.get(REQUEST);
      url = req.url;
    }
    this.getApiData(url);
    this.stylesdeveloper.map((element:any)=>{
      this.metablogs.addLink(element,element.elementType);
    })
  }

  getApiData(url:any){
  var  filter ={
       "where":{
         "status":true,
         "url":url
       },
       "limit":1,
     }
     this.apisService.getAllWithQuerywihName(environment.apis.blogs,filter).then((res:any)=>{
       if(res[0]){

         this.BlogData = res[0];
         this.titleSer.setTitle(this.BlogData.title +'| Yug Enterprises')
         this.meta.updateTag({ name: 'name', content: this.BlogData.title +'| Yug Enterprises' });
         this.meta.updateTag({ name: 'description', content: this.BlogData.metatags.description });
         this.meta.updateTag({ name: 'keywords', content: this.BlogData.metatags.Keyword })
         
         this.meta.updateTag({ property: "og:url", content: 'https://yuginfo.in' + this.BlogData.url })
         this.meta.updateTag({ property: "og:title", content:this.BlogData.title +'| Yug Enterprises' })
         this.meta.updateTag({ property: "og:site_name", content: this.BlogData.title +'| Yug Enterprises' })
      this.meta.updateTag({ property: "og:description", content: this.BlogData.metatags.description })
      this.meta.updateTag({ property: 'og:type', content: "website" })
      this.meta.updateTag({ property: "og:image", content: this.BlogData.metatags.imgUrl })
      
      this.meta.updateTag({ name: 'twitter:card', content: "summary_large_image" })
      this.meta.updateTag({ name: "twitter:url", content: 'https://yuginfo.in' + this.BlogData.url })
      this.meta.updateTag({ name: "twitter:creator", content:'@Videhma' })
      this.meta.updateTag({ name: "twitter:title", content:this.BlogData.title +'| Yug Enterprises' })
      this.meta.updateTag({ name: "twitter:description", content:this.BlogData.metatags.description})
      this.meta.updateTag({ name: "twitter:image", content: this.BlogData.metatags.imgUrl })
      this.apisService.getAllWithQuerywihName(environment.apis.AUTHOR,{"where":{_id:res[0].authorId}}).then((res:any)=>{
        this.BlogData.author = res[0];
      })
      this.apisService.getAllWithQuerywihName(environment.apis.CATEGORY,{"where":{_id:{"$in":res[0].categoryids}}}).then((res:any)=>{
        this.BlogData.categories = res;
      })
    }else{
      this.router.navigateByUrl('/notfound');
    }
    })
  }
  
  
  openSocial(type:any){
    let openurl ="";
    if(type == "linkedin"){
      openurl = 'https://www.linkedin.com/shareArticle?url=@u'
    }else if(type == "facebook"){
      openurl = 'https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d'
    }else if(type == "skype"){
      openurl = 'https://web.skype.com/share?url=@t%0D%0A@u%0D%0A@d'
    }else if(type == "whatsapp"){
      openurl = 'https://api.whatsapp.com/send?text=@t%0D%0A@u%0D%0A@d'
    }else if(type == "twitter"){
      openurl = 'https://twitter.com/intent/tweet?text=@t&url=@u'
    }
       openurl = openurl
        .replace(/@u/g, encodeURIComponent('https://yuginfo.in' + this.BlogData.url))
        .replace(/@t/g, encodeURIComponent(this.BlogData.title))
        .replace(/@d/g, encodeURIComponent(this.BlogData.shortDescription))

    
      setTimeout(() => {
        window.open(openurl,'_blank');
      }, 200);

  }



}
