import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';

@Component({
  selector: 'app-nft-development',
  templateUrl: './nft-development.component.html',
  styleUrls: ['./nft-development.component.scss']
})
export class NftDevelopmentComponent implements OnInit {

  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
    }

    ngOnInit(): void {
      this.meta.setMetaFields('NFTdevelopmentSerives');
    }
    firstMAtch = ['14']
  }
