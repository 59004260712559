import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ApisService {
baseurl:any="https://api.yuginfo.in/";
accessToken : any;
headers : any;
userId : any;
loading:BehaviorSubject<any> = new BehaviorSubject<any>(false);
totalBlogs:any;
checkScroll : any;
enabledId : any = [];
storedBlogs:any={};
  constructor(private http :HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
) {

    this.headers = new Headers();

    this.headers.append("Access-Control-Allow-Origin", "*");
    this.headers.append(
    "Access-Control-Allow-Methods",
    "POST, GET, OPTIONS, PUT"
    );
    this.headers.append("Accept", "application/json");
    this.headers.append("content-type", "application/json");
    }

    addAndRemoveClass(add:string,remove:string){
      if(isPlatformBrowser(this.platformId)){ 
        if(add){
          $('body').addClass(add);
        }
        if(remove){
          $('body').removeClass(remove);
        }
      }
    }


    addClass(){
      this.loading.next(true);
      this.addAndRemoveClass('classforBody','');
    }

    removeClass(){
      this.loading.next(false);
      this.addAndRemoveClass('','classforBody');
    }




    add(AddressInfo:any, controller:any,showLoading=true) {
      if(showLoading){
        this.addClass();
      }
      let addAddressURL =
      this.baseurl +controller + "/";
      return new Promise((resolve, reject) => {
      this.http.post(addAddressURL, AddressInfo, {
      headers: this.headers
      })
      .subscribe(
      res => {
        this.removeClass();
      resolve(res);
      },
      err => {
        this.removeClass();
        reject(err);
      }
      );
      });
      }

      getAllWithQuerywihName(controller:any,filterJSON={},showLoading= true){
        if(showLoading){
          this.addClass();
        }
        let filter = encodeURIComponent(JSON.stringify(filterJSON));
        let allAddressURL = this.baseurl + controller +'?filter='+filter;
        return new Promise((resolve, reject) => {
        this.http.get(allAddressURL).subscribe(
        (res: any) => {
          this.removeClass();

        resolve(res);
        },
        err => {
          this.removeClass();

        reject(err);
        }
        );
        });
        }

        getCount(controller:any,showLoading= true){
          if(showLoading){
            this.addClass();
          }
          let filterJSON = { };
          let filter = encodeURI(JSON.stringify(filterJSON));
          let AddressURL = this.baseurl + controller+'/count'+'?filter='+filter
          return new Promise((resolve, reject) => {
          this.http.get(AddressURL).subscribe(
          res => {
            this.removeClass();

          resolve(res);
          },
          err => {
            this.removeClass();

          reject(err);
          }
          );
          });
          }
}
