import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { environment } from 'src/environments/environment';
declare var $: any;
import { ScrollAnimationDirective } from './scroll-animation.directive';
import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss']
})
export class HomeNewComponent implements OnInit,OnDestroy {
  objForm:any;
  errorFlag:boolean=false;
  cat : any;
  private delay: string = '';

  removeStylesandScripts:any =[
    {
      id:"removethistag",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    
    {
      id:"removethistagthree",
      href:"https://info.yuginfo.in/js/font-icons.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagsix",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagseven",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistageight",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagnine",
      href:"https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagten",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagrtwo",
      href:"https://info.yuginfo.in/js/responsive.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagrfive",
      href:"https://info.yuginfo.in/js/flag.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagrsix",
      href:"https://info.yuginfo.in/js/owl.carousel.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"removethistagrseven",
      href:"https://info.yuginfo.in/js/bootstrap.min.css",
      rel:"stylesheet",
      elementType:"link"
    },
    {
      id:"jqueryjs",
      type: "text/javascript",
      href:"https://info.yuginfo.in/js/jquery.js",
      elementType:"script"
    },
    {
      id: "vidhemamin",
      type: "text/javascript",
      href: "https://info.yuginfo.in/js/vidhema.min.js",
      elementType: "script"
    },
    {
      id: "wowcustom",
      type: "text/javascript",
      href: "https://info.yuginfo.in/js/wow.min.js",
      elementType: "script"
    },
    {
      id: "owlcustom",
      type: "text/javascript",
      href: "https://info.yuginfo.in/js/owl.carousel.min.js",
      elementType: "script"
    },
    {
      id: "customwrapper",
      type: "text/javascript",
      href: "https://info.yuginfo.in/js/custom.js",
      elementType: "script"
    },
    {
      id:"internaljs",
      type: "text/javascript",
      elementType: "script"
    },
    {
      id: "lettercustom",
      type: "text/javascript",
      href: " https://cdn.jsdelivr.net/gh/yesiamrocks/cssanimation.io@1.0.3/letteranimation.min.js",
      elementType: "script"
    }
  ]

  addStylesandScripts:any = [
    {
      rel:"stylesheet",
      href:"./assets/css/home-new.css",
      id:"home-new",
      elementType:"link"

    },
    // {
    //   src: "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.5/dist/umd/popper.min.js",
    //   type: "text/javascript",
    //   id: "popper",
    //   elementType:"script"
    // }
  ]
  scrollValue:any;
  
  constructor(
    private meta:MetafieldsService,private builder: FormBuilder ,private apiservice:ApisService
    ,private scrollAnimationDirective:ScrollAnimationDirective,
    private router:Router,
    private el: ElementRef,
    public api : ApisService

  ) {
    // this.scrollValue = this.scrollAnimationDirective.scrollValue
    // console.log('12312',this.scrollValue)
  }
  

  ngOnInit(): void {
  
    this.addStylesandScripts.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })

    this.removeStylesandScripts.map((element:any)=>{
      this.meta.removeLink("#"+element.id);
    });


    $('#client-slider').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      dots: false,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplaySpeed: 1000,
      autoplayHoverPause: true,
      responsive: {
          0: {
              items: 1,
              margin:0
          },
          767: {
              items: 3
          },
          991:{
              items: 3
          },
          1200: {
              items: 4.5
          }
      }
      })

      $('#slider').owlCarousel({
          loop: true,
          margin: 15,
          nav: false,
          dots: true,
          autoplay: false,
          autoplayTimeout: 3000,
          autoplaySpeed: 1000,
          autoplayHoverPause: true,
          slideTransition: 'linear',
          // stagePadding:50,
          responsive: {
              0: {
                  items: 1,
                  margin:0
              },
              767: {
                  items: 2
              },
              1000: {
                  items: 2
              }
          }
      })

      $('#blog-slider').owlCarousel({
          loop: true,
          margin: 15,
          nav: false,
          autoplay: true,
          dots: true,
          autoplayTimeout: 3000,
          autoplaySpeed: 1000,
          autoplayHoverPause: true,
          slideTransition: 'linear',
          responsive: {
              0: {
                  items: 1,
                  margin:0
              },
              767: {
                  items: 2
              },
              1000: {
                  items: 3
              }
          }
      })

  }

  ngOnDestroy(): void {
    this.addStylesandScripts.map((element:any)=>{
      this.meta.removeLink("#"+element.id);
    });
    this.removeStylesandScripts.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
    
  }
 
    }

