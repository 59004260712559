import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';

@Directive({
  selector: '[appScrollAnimation]'
})
export class ScrollAnimationDirective {
  private delay: string = '';
  scrollValue:any;
  @Input() id : any;

  @HostListener('window:scroll', [])
  // scroll(): void {

  //   this.api.enabledId.map((ele:any)=>{
  //     console.log(ele);
  //     let rect :any= document.getElementById(ele);
  //     rect = rect.getBoundingClientRect()
  //     let scroll = this.mapRange(0, window.innerHeight, 0, 1, rect.top + (rect.height / 2));
  //     scroll = scroll < 0 ? 0 : scroll > 1 ? 1 : scroll;
  //     this.scrollValue = scroll;
  //     this.api.checkScroll[this.id] = scroll;
  //     console.log(ele,"coming-scroll window scroll", this.scrollValue, "delay values", this.delay);
  //   })
  //   }

  scroll(): void {
    let rect :any= document.getElementById("checc");
      rect = rect.getBoundingClientRect()
      let scroll = this.mapRange(0, window.innerHeight, 0, 1, rect.top + (rect.height / 2));
      scroll = scroll < 0 ? 0 : scroll > 1 ? 1 : scroll;
      this.scrollValue = scroll;
      this.api.checkScroll = scroll;
  }

  @HostBinding('style.animationDelay') get animationDelay(): string {
    return this.delay;
  }

  private mapRange(a1: number, a2: number, b1: number, b2: number, value: number): number {
    return b1 + ((value - a1) * (b2 - b1)) / (a2 - a1);
  }

  constructor(private el: ElementRef,private api : ApisService) {
  //   console.log("hello");
  //   let self = this;
  //   setTimeout(() => {
      
  //     if(!this.api.enabledId.includes(this.id) && this.id){
  //       console.log(this.id)
  //       self.api.enabledId.push(this.id);
  //       self.api.checkScroll[this.id] = 0
  //     }
  //     console.log(this.id ,self.api.checkScroll,  "hellllslslslsl")
  //   }, 1000);
  }
}
