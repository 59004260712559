import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-ios-app-development',
  templateUrl: './ios-app-development.component.html',
  styleUrls: ['./ios-app-development.component.scss']
})
export class IosAppDevelopmentComponent implements OnInit {

  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }
  firstMAtch = ['2'];
  ngOnInit(): void {
    this.meta.setMetaFields('IOSAppDev');
  }

}
