import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  stylesdeveloper : any = [
    {
      id:"removethisdevelop",
      href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
      rel:"stylesheet",
      elementType:"link"
    }
  ]
  constructor(private meta:MetafieldsService
) {
  this.meta.api.addAndRemoveClass('aboutus portfolio','profilepagesection blog careers mainaboutus contactus services technology getaquote HomeMain blogcontentbody');
   }

  ngOnInit(): void {
    this.meta.setMetaFields('Portfolio');
    this.stylesdeveloper.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }
  ngOnDestroy(): void {
    this.stylesdeveloper.map((element:any)=>{
      this.meta.removeLink("#removethisdevelop");
    });
  }

}
