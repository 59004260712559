<div >
    <section id="tinyslider">
      <div class="dark" style="background-image: linear-gradient(rgb(5 2 26 / 80%) 0%, rgb(0 0 0 / 90%) 100%), url('https://info.yuginfo.in/BlogImagesyug/202132211923-221bgportfolio.jpg');background-position: center top;background-size: cover;">
        <div class="container clearfix">
  
            <div class="slider-caption slider-caption-center">
              
  
                <h1 >Our Work</h1>
  
                <p >There is no one size fits all for companies when it comes to websites. With our full suite of web development, content creation and social media marketing services, driving traffic to your website and building an online presence has never been easier!! </p>
            </div>
  
            </div>
      </div>
      
  
    </section>
  
         <section class="selection-bar">
            <div class="container">
              <div class="row"> 
             
              </div>  
            </div>  
         </section>
          <section class="portfolio"> 
            <div class="container container-box">
            
                <div class="row">
                    
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web card app ">
                          <div class="bg-img bg2">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322131511-482imgpsh_fullsize-new-7_anim-%281%29-12.webp" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>iLastWord</h2>
                              <p>Missing your love ones, then ILastword is the platform to give gratitude to them and share that with world. For the demo please click below:</p>
                                   <div class="devices">
                                   <a rel="nofollow" href="https://ilastword.com/" target="_blank">
                                      <span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.createapp.ilastword&hl=en_IN" target="_blank">
                                      <span><i class="fa fa-android" aria-hidden="true"></i></span>view
                                    </a>
                                    <a href="https://apps.apple.com/us/app/ilastword/id1465180802?mt=8&ign-mpt=uo%3D4" target="_blank">
                                      <span><i class="fa fa-apple" aria-hidden="true"></i></span>view
                                    </a>
                              </div>
                            </div> 
                            
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web ">
                          <div class="bg-img bg3">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322131043-470imgpsh_fullsize-new-5_anim-%281%29-12.png" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>STC</h2>
                              <p>Saudi Arabia Top mobile service provider, they give the service in entire gulf region. For the demo please click below:</p>
                               <div class="devices"> 
                                  <a href="#"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                                </div>
                            </div> 
                            
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce ">
                          <div class="bg-img bg4">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132410510-160zonsteps.png" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Zonsteps</h2>
                              <p>The ZonSteps platform clearly maps out in sequential order the 200+ steps required to take your Amazon business from start to launch and beyond. For the demo please click below</p>
                                <div class="devices">  
                                  <a rel="nofollow" href="https://www.zonsteps.com/" target="_blank"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                                </div>
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Brand-Strategy">
                          <div class="bg-img bg5">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132213716-741imgpsh_fullsize_new-2anim-%281%29-12.webp" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Bodyarmor</h2>
                              <p>Its one of the top sports drink brand in the world. For the demo please click below:</p>
                              <div class="devices">
                                <a rel="nofollow" href="https://www.drinkbodyarmor.com/" target="_blank"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                          
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Brand-Strategy">
                          <div class="bg-img bg5">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322112445-310penpalace.png" alt="Penpalace">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Penpalce</h2>
                              <p>Its one of the top stationery brand in the India for all items like pens, gift items and many more. For the demo please click below:</p>
                              <div class="devices">
                                <a rel="nofollow" href="https://penpalace.in/" target="_blank"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                          
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img bg6">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132213957-866imgpsh_fullsize-new-4_anim-%281%29-12.webp" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Fcc kuwait</h2>
                              <p>Future is a leading technology company that provides cutting edge ICT products and services including enterprise business, mobility, infrastructure, telecom engineering, project management and digital transformation. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.fcc-kuwait.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img bgcynth">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322111319-270cynthiarowley.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Cynthia Rowley</h2>
                              <p>Cynthia Rowley is a leading technology company that provides cutting edge ICT products and services including Shop designer dresses, wetsuits, swimsuits, and many more products. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://cynthiarowley.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img bgmarcfisher">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322111934-509marcfisher.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Marc Fisher</h2>
                              <p>Discover Marc’s unique take on understated classics made fresh and stunningly new. Shop Marc Fisher boots, heels, flats & more at the official site. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.marcfisherfootwear.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img bgbamboonature">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132211816-246Bamboonature.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Bambo Nature</h2>
                              <p>Shop Baby Diapers, Training Pants, Wipes, & Skincare Eco-friendly baby products with supernatural PERFORMANCE. SHOP baby diapers, training pants, wipes, & skincare that are CERTIFIED SKIN SAFE. *Eco-Friendly*. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://bambonatureusa.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img giftwrapmyfacebg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322111620-910giftwrapmyface.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>GIFT WRAP</h2>
                              <p>The best-selling personalized gift wrapping paper and custom parties with your face, your pets', or the people you love. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.giftwrapmyface.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img rungg-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322112649-893rungg.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Rungg</h2>
                              <p>Rungg is inspired by Nature our handcrafted shoes embody timeless quality and style, superior craftsmanship of a forgotten era. We experiment with Bold, Bright, and stunning colors on our shoes For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.runggshoes.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web ">
                          <div class="bg-img bg1">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132213911-634imgpsh_fullsize-new-3_anim-%281%29-12.webp" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Football Iq</h2>
                              <p>Football IQ provide the service to world top football league. They collected and generate the statics for top football players. For the demo please click below:.</p>
                              <div class="devices">
                                   <a rel="nofollow" href="https://football-iq.eu/" target="_blank">
                                      <span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view
                                    </a>
                              </div>
                          
                            </div> 
                       
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web ">
                          <div class="bg-img bgfood">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322111552-733fullplate.png" alt="DynamoDB">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>FullPlate</h2>
                              <p>FullPlate is a blog website to the food lovers whom create a lots of variety of dishes. For the demo please click below:.</p>
                              <div class="devices">
                                   <a rel="nofollow" href="https://fullplate.in/" target="_blank">
                                      <span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view
                                    </a>
                              </div>
                            
                            </div> 
                          
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img ramybrook-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322112542-621ramybrook.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Ramy Brook</h2>
                              <p>Ramy Brook is a ecommerce company that provides cutting edge ICT products and services including enterprise business. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.ramybrook.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
  
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img CONTINENCE-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021322111256-342continencephysiowa.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>CONTINENCE PHYSIOTHERAPY NETWORK OF WA</h2>
                              <p>This is a professional support network for physiotherapists across Western Australia . For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="http://www.continencephysiowa.com.au/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img Safratrab3-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/2021324104918-825safratrab3.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>Safratrab3</h2>
                              <p>Safratrab3 Is A Relatively Young But One Of The Fastest Rising Tour Agencies In Kuwait. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="http://safratrab3.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img blace-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132211303-461The-Place-for-Blank-Space.png" alt="Cynthiarowley">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>The Place for Blank Space</h2>
                              <p>BLACE is an online marketplace for booking unique event spaces near you. We provide our clients venues for creating memorable experiences from corporate events. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://www.blace.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12 img-box">
                      <div class="box web Ecommerce app">
                          <div class="bg-img blace-bg">
                            <img src="https://info.yuginfo.in/BlogImagesyug/202132213176-969numiindia.png" alt="NumiIndia">
                          </div>  
                          <div class="info">
                            <div class="text-box">
                              <h2>NumiIndia</h2>
                              <p>NumiIndia is the proudly one of the trusted firms in the country. As a leader in the rare coin industry carrying all types of coins. For the demo please click below:</p>
                              <div class="devices">  
                                <a rel="nofollow" href="https://numiindia.com/" target="_blank" tabindex="-1"><span><i data-v-7070b2b2="" class="fa fa-globe"></i></span>view</a>
                              </div>  
                            </div> 
                             
                          </div>  
                      </div>  
                    </div>
                </div>  
            </div>  
          </section>
  <a href="#" id="back-to-top" title="Back to top" ><i class="fa fa-arrow-up"></i></a>
    </div>