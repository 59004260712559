import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-hybrid-ionic',
  templateUrl: './hybrid-ionic.component.html',
  styleUrls: ['./hybrid-ionic.component.scss']
})
export class HybridIonicComponent implements OnInit {
  firstMAtch = ['6']
  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }
 
  ngOnInit(): void {
    this.meta.setMetaFields('IonicAppDev');
  }

}
