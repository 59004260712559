import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { environment } from 'src/environments/environment.prod';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  career: any;
  colors : any = ["#3f27cf" , "#0f7599", "#44ddcc" ,"orange", "#f670af" , "#46af61"]
  styles : any = [
    {
      id:"removethisdevelop",
      href:"https://info.yuginfo.in/yuginfo/static/developer_custom.css",
      rel:"stylesheet",
      elementType:"link"
    }
  ]

  constructor(private apisService : ApisService,private meta:MetafieldsService,
    private router:Router) { 
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')


    }

  ngOnInit(): void {
    this.meta.setMetaFields('CareerDev');
    this.styles.map((element:any)=>{
      this.meta.addLink(element,element.elementType);
    })
  }
  ngAfterViewInit() {
    this.apisService.getAllWithQuerywihName( environment.apis.categoryJob).then((res)=>{
       console.log(res);
       this.career=res;
       let j = 0;
       this.career.map((element:any , index : any)=>{
         if(this.colors[j]){
          element.color = this.colors[index];
          if(j == this.colors.length - 1){
            j = 0
          }else{
            j++;

          }
         }
       })
      })
  
      
}
getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

move(name:any){
  this.router.navigateByUrl('/apply/'+name);
}
  ngOnDestroy(): void {
    this.styles.map((element:any)=>{
      this.meta.removeLink("#removethisdevelop");
    });
  }
}
