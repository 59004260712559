import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;

@Component({
  selector: 'app-hybrid-flutter',
  templateUrl: './hybrid-flutter.component.html',
  styleUrls: ['./hybrid-flutter.component.scss']
})
export class HybridFlutterComponent implements OnInit {
  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }
 
  ngOnInit(): void {
    this.meta.setMetaFields('FlutterAppDev');
  }
  firstMAtch = ['3'];

}
