import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-rubyonrail',
  templateUrl: './rubyonrail.component.html',
  styleUrls: ['./rubyonrail.component.scss']
})
export class RubyonrailComponent implements OnInit {

  constructor(private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus technology','portfolio profilepagesection blogcontentbody mainaboutus blog contactus services getaquote careers HomeMain')
   }

  ngOnInit(): void {
    this.meta.setMetaFields('WebROR');
  }

}
