import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApisService } from '../services/apis.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  career: any;
  name: any;
  job: any=[];
  result: any;
  qualify: any;

  constructor(private apisService : ApisService,
    private activatedRoute:ActivatedRoute,
    private router:Router) { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.name = this.activatedRoute.snapshot.paramMap.get("name");
    console.log(this.name)
    // this.apisService.getAllWithQuerywihName( environment.apis.categoryJob).then((res)=>{
    //    console.log(res);
    //    this.career=res;
    //   })
    
    let filter={
      "where":{
        title:this.name
      }
    }
      this.apisService.getAllWithQuerywihName( environment.apis.job,filter).then((res)=>{
        console.log(res);
        this.job=res;
        // this.job[0].role = this.job[0].role.split(",");
        // console.log(this.result)
        var str =this.job[0]?.role ;
        this.result = str.split(",");
        console.log(this.result)

        
        var string =this.job[0]?.qualifications ;
        this.qualify = string.split(",");
        console.log(this.qualify)
        console.log(this.qualify[0])
       })
       
}
moveTo(name:any){
 this.router.navigateByUrl('/apply/'+name);
}
}
