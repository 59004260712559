<div class="cu-form__scroll">
    <div class="cu-form__container ng-untouched ng-pristine ng-invalid">
        <div class="cu-form__avatar">
            
        </div>
        <div class="cu-form__name"> Be a part of <strong class="yuginfotext">Yug Enterprises</strong>? Let's start.

        </div><div class="cu-task-editor">
            <div class="cu-editor-wrapper">
                <div class="cu-editor-content">
                    <div class="cu-editor ql-container">

                    </div>
                </div>
            </div>
        </div>
        <div class="cu-form__name-divider">

        </div>
        <div class="cu-form__body">
            <form (ngSubmit)="add.form.valid && submit(add.form)" #add="ngForm">
            <div class="cu-form__body-item" *ngFor="let qustn of questions">
                <div class="cu-form__body-label-container" >
                    <label> {{qustn.title}}<span>*</span>
                    </label>
                </div>
                <input  type={{qustn.type}} placeholder="Enter the field" class="ng-untouched ng-pristine ng-invalid" 
                [(ngModel)] = "qustn.fieldValue" name = "{{qustn.field}}" #[qustn.field]= "ngModel" required 
                [ngClass]="{ 'is-invalid': add.submitted && add.form.controls[qustn.field].invalid}" *ngIf="qustn.type!='googleSearch' && qustn.type!='fileupload'"
                >

<input

class="ng-untouched ng-pristine ng-invalid" 
(keydown.enter)="$event.preventDefault()"
placeholder="Search Nearest Location"
autocorrect="off"
autocapitalize="off"
spellcheck="off"
(click)="funct()"
[(ngModel)] = "qustn.fieldValue" name = "{{qustn.field}}" #[qustn.field]= "ngModel"
type="text"
#searchTextField
id = "searchTextField"
required
[ngClass]="{ 'is-invalid': add.submitted && add.form.controls[qustn.field].invalid}"
*ngIf="qustn.type=='googleSearch' && qustn.type!='fileupload'"
/>
                <input (change)="detectFiles($event)" type="file" placeholder="upload" class="ng-untouched ng-pristine ng-invalid" 
                [(ngModel)] = "qustn.fieldValue" name = "{{qustn.field}}" #[qustn.field]= "ngModel" required 
                [ngClass]="{ 'is-invalid': add.submitted && add.form.controls[qustn.field].invalid}" *ngIf="qustn.type=='fileupload' && qustn.type!='googleSearch'"
                >
                
            </div>
            <button class="cu-form__submit" > Submit </button>                         
            </form>
         
            </div>
           