import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-web-application',
  templateUrl: './web-application.component.html',
  styleUrls: ['./web-application.component.scss']
})
export class WebApplicationComponent implements OnInit {

  constructor(private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }

  ngOnInit(): void {
    this.meta.setMetaFields('WebApp');
  }
  firstMAtch = ['11'];
}
