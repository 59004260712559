import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';
import { MetafieldsService } from 'src/app/services/metafields.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-quoteform',
  templateUrl: './quoteform.component.html',
  styleUrls: ['./quoteform.component.scss']
})
export class QuoteformComponent implements OnInit {
  objForm:any;
  errorFlag:boolean=false;
  cat : any
  constructor(private meta:MetafieldsService,private builder: FormBuilder ,private apiservice:ApisService
    ,
    private router:Router,) {
    this.objForm = this.builder.group({
      full_name: ['', Validators.required],
      phone_num: [''],
      email_add:['', [Validators.required, Validators.email]],
      country:[''],
      message: ['', Validators.required],
      contactsskypeid: ['', Validators.required],
      contactsbudget:[''],
      contactscategory:['']
    });
   }

  ngOnInit(): void {
  }
  saveUser(){
    console.log('2111111111111111111111111')
        this.objForm.patchValue({
          category: this.cat,
    
        });
        console.log("working",this.objForm.value)
        if(this.objForm.status=='INVALID'){
          this.errorFlag=true;
    
          // console.log("invalid" ,)
        }
        else{
          let obj = {
            'email_add':this.objForm.value.email_add,
            'full_name':this.objForm.value.full_name,
            'phone_num': this.objForm.value.phone_num,
            'country':this.objForm.value.country,
            'form_type':'submitFormContactForm',
            'message':this.objForm.value.message,
            'contactsskypeid':this.objForm.value.contactsskypeid,
            'contactsbudget':this.objForm.value.contactsbudget,
            'contactscategory':this.objForm.value.contactscategory,
          }
    
        // console.log("working",obj)
    
         this.apiservice.add(obj , environment.apis.users).then((res)=>{
        //  console.log("value",res);
        this.objForm.reset()
        this.errorFlag=false;
        })
        }
       }
}
