<div >
    <section id="tinyslider">
      <div class="dark" style="background-image: linear-gradient(rgb(5 2 26 / 80%) 0%, rgb(0 0 0 / 90%) 100%), url('https://info.yuginfo.in/BlogImagesyug/202161813718-883blogsvidhema.jpg');background-position: center top;">
        <div class="container clearfix">
  
                <div class="slider-caption slider-caption-center">
  
                    <h1 >Explore Latest from the IT World with Yug Enterprises</h1>
  
                    <p >No global movement springs from individuals. It takes an entire team united behind something big. Together, we work hard, we laugh a lot, we brainstorm nonstop,and are passionate about the company and our values.</p>
  
                </div>
  
            </div>
      </div>
      
  
    </section>

  
      <section id="content" class="servesdetails blog">
        <div class="content-wrap" id="yuginfo_content">
          <div class="container clearfix">
  
                      <div class="row clearfix">
  
  
  
                          
  
                      </div>
  
                  </div>
  
          <div class="section nobottommargin" id="serviceslist">
  
            
            <div id="servicedetailss" class="container-fluid" style="background-color:var(--whitecolor);">
              <div class="container clear-bottommargin clearfix" id="servicedetails">
              
              
                <div class="col-md-12 col-xs-12 col-sm-12">
                  <div class="col-md-8 col-sm-7 col-xs-12 largecontentdiv listser">
                    <div *ngIf="Blogs && Blogs.length">
                      <div class="blog-content"  *ngFor="let blog of Blogs" >
                          <h3 class="center "><span><a class="centerClass" :routerLink="{{blog.url}}">{{blog.title}}</a></span></h3>
                          <h5 class="center"><a routerLink="{{blog.url}}"><img src="{{blog.featured_image}}" alt="{{blog.title}}" title="{{blog.title}}"></a></h5>
                          <p class="tecolor"><strong> {{blog.shortDescription}} <a class="aLinkClass" routerLink="{{blog.url}}">View More</a></strong></p>
                      </div>
                    </div>
  
                  </div>
                  <div class="col-md-4 col-sm-5 col-xs-12">
                    <h3>Categories</h3>
                    <ul class="categores-list">
                      <!-- <li class="cat-item cat-item-1"><a routerLink="/blog/what-is-SEO">Digital Marketing</a>
                      </li> -->
                      <li class="cat-item cat-item-197"><a routerLink="/blog/magento-new-updates">E-Commerce</a>
                      </li>
                      <li class="cat-item cat-item-329"><a routerLink="/blog/what-you-need-to-know-about-ionic4">JS Frameworks</a>
                      </li>  
                    </ul>
                     <h5 class="center"><a routerLink="/getaquote.html"><img src="https://info.yuginfo.in/BlogImagesyug/2021322121016-936development.jpg" alt="Development with yuginfo" title="Development with yuginfo"></a></h5>
                     <h5 class="center"><a routerLink="/getaquote.html"><img src="https://info.yuginfo.in/BlogImagesyug/20213221271-60benefits.jpg           " alt="Benefits of yuginfo" title="Benefits of join yuginfo"></a></h5>
                  </div>
                </div>
              </div>
            </div>
          <div class="PaginationClass">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination" :style="ulStyles">
          <li class="page-item first" :class="{'disabled': pager.currentPage === 1}" :style="liStyles">
              <a class="page-link" routerLink="/blog/1" :style="aStyles"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
          </li>
          <li class="page-item previous" :class="{'disabled': pager.currentPage === 1}" :style="liStyles">
              <a class="page-link" routerLink="/blog/{{pager.currentPage - 1}}" :style="aStyles"><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
          </li>
          <li *ngFor="let page of pager.pages" class="page-item page-number" [ngClass]="{'active': pager.currentPage === page}" :style="liStyles">
              <a class="page-link" routerLink="/blog/{{page}}" :style="aStyles">{{page}}</a>
          </li>
          <li class="page-item next" :class="{'disabled': pager.currentPage === pager.totalPages}" :style="liStyles">
              <a class="page-link" routerLink="/blog/{{pager.currentPage + 1}}" :style="aStyles"><i class="fa fa-arrow-right" aria-hidden="true"></i></a>
          </li>
          <li class="page-item last" :class="{'disabled': pager.currentPage === pager.totalPages}" :style="liStyles">
              <a class="page-link" routerLink="/blog/{{pager.totalPages}}" :style="aStyles"><i class="fa fa-step-forward" aria-hidden="true"></i>
              </a>
          </li>
      </ul>
          </div>
            
                   
            
            
                  </div>
         
        </div>
        <app-quoteform></app-quoteform>
      </section>
      
    <a href="#" id="back-to-top" title="Back to top" ><i class="fa fa-arrow-up"></i></a>
    </div>