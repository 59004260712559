import { Component, OnInit } from '@angular/core';
import { MetafieldsService } from 'src/app/services/metafields.service';
declare var $: any;
@Component({
  selector: 'app-react-native',
  templateUrl: './react-native.component.html',
  styleUrls: ['./react-native.component.scss']
})
export class ReactNativeComponentServices implements OnInit {
  constructor(
    private meta:MetafieldsService,
) {
    this.meta.api.addAndRemoveClass('aboutus services','portfolio profilepagesection blogcontentbody mainaboutus blog contactus technology getaquote careers HomeMain')
  }
 
  ngOnInit(): void {
    this.meta.setMetaFields('ReactnativeAppDev');
  }
  firstMAtch = ['17'];
}
